const checkUserAuth = () => {
  const tokenData = JSON.parse(localStorage.getItem('accessToken'));
  if (tokenData) {
    const tokenTimeOutData = tokenData?.timeout || null;
    const tokenD = tokenData?.token || false;
    const timeOutData = new Date().getTime() - tokenData.createdOn;
    if (timeOutData > tokenTimeOutData) {
      tokenData.isExpire = true;
      localStorage.removeItem('accessToken');
      window.location.href = '/';
      return false;
    }
    return tokenD;
  }
  return false;
};

export default checkUserAuth;
