import { createTheme } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

const themeStyle = createTheme();

export const useStyles = makeStyles(() => ({
  secondCard: {
    marginTop: '16px',
    [themeStyle.breakpoints.down('900')]: {
      width: '100%',
    },
    // [themeStyle.breakpoints.down('300')]: {
    //   direaction: 'column',
    // },
  },
  cardPages: {
    [themeStyle.breakpoints.down('400')]: {
      width: '100%',
      // marginLeft: '20px',
    },
  },
  cardPagination: {
    [themeStyle.breakpoints.down('900')]: {
      width: '50%',
      // marginLeft: '20px',
    },
    [themeStyle.breakpoints.down('600')]: {
      width: '100%',
      // marginLeft: '20px',
    },
  },
}));
