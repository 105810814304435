import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { InputAdornment } from '@material-ui/core';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { FormControl } from '@mui/material';
import { getIn } from 'formik';
import { CalenderContainerComponent } from '../../calender-container-component';
import { StyledErrorFormLabel, StyledFormLabel, StyledTextField } from './styled';

const DateRangePicker = ({
  label,
  field,
  form: { setFieldValue, values, errors, touched },
  required,
  dateFormat,
  placeholder,
  fromDate,
  toDate,
  helperText,
  ...rest
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);
  return (
    <FormControl fullWidth>
      {label && <StyledFormLabel required={required}>{label}</StyledFormLabel>}
      {label && isError && <StyledErrorFormLabel required={required}>{label}</StyledErrorFormLabel>}
      <DatePicker
        onChange={(dates) => {
          const [startDate, endDate] = dates;
          setFieldValue(fromDate, startDate);
          setFieldValue(toDate, endDate);
        }}
        startDate={values[fromDate] || null}
        endDate={values[toDate] || null}
        maxDate={new Date()}
        selectsRange
        showYearPicker
        dateFormat={dateFormat}
        calendarContainer={CalenderContainerComponent}
        customInput={
          <StyledTextField
            {...field}
            placeholder={placeholder}
            fullWidth
            error={isError}
            helperText={isError ? errorMessage : helperText}
            InputProps={{
              inputProps: { readOnly: true },
              endAdornment: (
                <InputAdornment position="end">
                  <EventNoteIcon fontSize="12px" htmlColor="#37474F" />
                </InputAdornment>
              ),
            }}
          />
        }
        {...rest}
      />
    </FormControl>
  );
};

export default memo(DateRangePicker);
