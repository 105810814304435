/* eslint-disable import/no-unresolved */
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { InputAdornment } from '@material-ui/core';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { FormControl } from '@mui/material';
import { getIn } from 'formik';
import { CalenderContainerComponent } from '../../calender-container-component';
import { StyledErrorFormLabel, StyledFormLabel, StyledTextField } from './styled';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerInput = ({
  label,
  field,
  form: { setFieldValue, values, errors, touched },
  required,
  dateFormat,
  placeholder,
  fromDate,
  toDate,
  helperText,
  tooltipText,
  ...rest
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);
  return (
    <FormControl fullWidth>
      {label && !isError && <StyledFormLabel required={required}>{label}</StyledFormLabel>}
      {label && isError && <StyledErrorFormLabel required={required}>{label}</StyledErrorFormLabel>}
      <DatePicker
        onChange={(dates) => {
          setFieldValue(field.name, dates);
        }}
        selected={values[field.name] || null}
        maxDate={new Date()}
        dateFormat={dateFormat}
        calendarContainer={CalenderContainerComponent}
        customInput={
          <StyledTextField
            {...field}
            placeholder={placeholder}
            fullWidth
            error={isError}
            helperText={isError ? errorMessage : helperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EventNoteIcon fontSize="12px" htmlColor="#37474F" />
                </InputAdornment>
              ),
            }}
          />
        }
        {...rest}
      />
    </FormControl>
  );
};

export default memo(DatePickerInput);
