import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';

const themeStyle = createTheme();
export const useStyles = makeStyles(() => ({
  tableNoBreakCell: {
    padding: '15px 10px 15px 10px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: '#000',
  },
  labelClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    padding: '4px 8px',
    textAlign: 'center',
    color: '#37474F',
    margin: '0px 8px 4px 0px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    display: 'inline-block',
    backgroundColor: '#E6E8F0',
  },
  linkTextActionButtonClass: {
    color: '#3072C6',
    textDecoration: 'underline',
    padding: '4px 8px',
    backgroundColor: '#E6E8F0',
    boxShadow: 'unset',
    fontWeight: '500',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    '&:hover': {
      backgroundColor: '#E6E8F0',
      boxShadow: 'unset',
      textDecoration: 'underline',
    },
  },
  cursorPointor: {
    cursor: 'pointer',
  },
  tableCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: '#000',
  },
  tableListImageClass: {
    borderRadius: '8px',
    width: '30px',
    maxHeight: '30px',
  },
  stickyTableCell: {
    position: 'sticky',
    top: 'auto',
    right: 0,
    marginTop: '0px',
    background: 'white',
    padding: '8px',
  },
  tableButtonActionCell: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '5px',
  },
  tableListIconButton: {
    paddingRight: '0px',
  },
  blueSearchSquareActionButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '0px',
    padding: '5px',
    // height: '40px',
  },
  cartCancelButton: {
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    width: '70px',
    '&:hover': { backgroundColor: '#910101' },
  },
  cartBlueButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '70px',
  },
  blueNoWrapActionButton: {
    marginRight: '8px',
    whiteSpace: 'nowrap',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  dNone: {
    display: 'none',
  },
  blueActionButton: {
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueActionLastButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
}));
