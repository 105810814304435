import React from 'react';
import { Box, Typography } from '@material-ui/core';
import upgradeImage from '../../asset/page/upgrade-plan-image.svg';
import { useStyles } from './styled';

const UpgradePlanPage = () => {
  const styleClasses = useStyles();
  return (
    <Box className={styleClasses.lockedPageContainer}>
      <Box className={styleClasses.lockedPageImageContainer}>
        <img width="50%" src={upgradeImage} alt="upgrade icon" />
      </Box>
      <Box>
        <Typography align="center" className={styleClasses.lockedTextClass}>
          Your
          <span className={styleClasses.lockedSpanGold}> bronze </span>
          subscription doesn’t include our website integration!
          <br />
          To display your inventory on your website, upgrade to
          <span className={styleClasses.lockedSpanSilver}> silver</span>.
        </Typography>
      </Box>
    </Box>
  );
};

export default UpgradePlanPage;
