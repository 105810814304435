import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import useSettings from '../../hooks/use-settings';
import { useStyles } from '../../styles/common-style';

const PageContainer = ({ title, children, maxWidth = '', isFilter = '', isCenter = '' }) => {
  const styleClasses = useStyles();
  const { settings } = useSettings();
  const maxWidthContainer = settings.compact ? 'xl' : false;
  let className = styleClasses.pageContainer;
  if (isFilter === true) {
    className = styleClasses.filterPageContainer;
  }
  if (isCenter !== '') {
    className = styleClasses.pageCenterContainer;
  }
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <Box className={className}>
        <Container
          maxWidth={maxWidth !== '' ? maxWidth : maxWidthContainer}
          className={isFilter ? styleClasses.filterWrapperSection : styleClasses.containerClass}
        >
          {children}
        </Container>
      </Box>
    </>
  );
};

PageContainer.propTypes = {
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.any,
  isFilter: PropTypes.bool,
  isCenter: PropTypes.any,
};

export default PageContainer;
