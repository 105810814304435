import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const PartIcon = createSvgIcon(
  <svg width="21" height="23" viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.64901 1.87288e-05H12.3775C13.2119 1.87288e-05 13.2119 1.427 12.3775 1.427H11.543V2.28319H13.7682L14.4636 2.85398V5.70794H15.9934L16.5497 6.27874V7.42032H17.245V6.27874C17.245 5.85062 17.5232 5.27983 18.2185 5.70794C18.9139 6.13606 20.0706 7.10659 20.4437 7.42032C20.8168 7.73406 21 7.8484 21 7.99111V13.1283C21 13.4136 21 13.4136 20.7219 13.699C20.4437 13.9845 19.3311 14.9833 18.4967 15.6968C17.6623 16.4103 17.245 15.6968 17.245 15.2687V14.2698H16.5497V15.4114C16.5497 15.6968 16.2715 15.9822 15.9383 15.9822H8.48344C7.92715 15.9822 7.92715 15.9822 7.64901 15.6968C7.37086 15.4114 5.1457 12.5575 5.1457 12.5575H3.47682C3.19863 12.5575 2.78146 12.2721 2.78146 11.9867V10.1316H1.39073V12.2721C1.39073 12.8428 -4.97121e-05 12.8428 0 12.2721V4.56636C0 3.99555 1.39073 3.99555 1.39073 4.56636V6.70683H2.78146V5.13715C2.78146 4.85174 2.92053 4.28096 3.47682 4.28096H4.86755V2.85398C4.86755 2.56857 5.1457 2.28319 5.42384 2.28319H8.2053V1.427H7.64901C6.81457 1.427 6.81457 6.08686e-05 7.64901 1.87288e-05Z" />
  </svg>,
  'PartIcon',
);

export default PartIcon;
