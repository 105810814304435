import { CalendarContainer } from 'react-datepicker';
import PropTypes from 'prop-types';
import { useStyles } from '../styles/common-style';

export const CalenderContainerComponent = ({ className, children }) => {
  const styleClasses = useStyles();
  return (
    <div className={styleClasses.calenderContainerClass}>
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </div>
  );
};

CalenderContainerComponent.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};
