import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

const RouterContext = createContext();

export const RouterProvider = ({ children }) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
  }, [location]);

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>;
};

RouterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RouterContext;
