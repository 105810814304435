import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  secondCard: {
    marginTop: '16px',
  },
  stepperClasses: {
    '& .MuiStepIcon-active': { color: 'red' },
    '& .MuiStepIcon-completed': { color: '#145DA0' },
    '& .Mui-active .MuiStepIcon-root': { fontWeight: 'bold' },
    '& .MuiStepIcon-text': { color: 'black', fontWeight: 'bold' },
    '& .Mui-active': { color: '#145DA0', fontWeight: 'bold' },
    '& .Mui-disabled': { color: '#989898', fontWeight: 'bold' },
    '& .MuiStepConnector-line': { borderTopWidth: '1.5px', borderColor: '#989898' },
    '& .css-1j8x6j7-MuiStepLabel-labelContainer': { textAlign: 'center' },
    '& .Mui-active .css-1j8x6j7-MuiStepLabel-labelContainer': { color: '#145DA0', textAlign: 'center' },
  },
}));
