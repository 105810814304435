import React from 'react';
import { useStyles } from '../styles/common-style';
import { DesktopView, MobileView, TabletView } from './responsive-view-component';

const Logo = () => {
  const styleClasses = useStyles();
  return (
    <>
      <DesktopView>
        <img alt="logo" src="/static/logo.svg" className={styleClasses.deskTopLogoClass} />
      </DesktopView>
      <MobileView>
        <img alt="logo" src="/static/logo.svg" className={styleClasses.mobileLogoClass} />
      </MobileView>
      <TabletView>
        <img alt="logo" src="/static/logo.svg" className={styleClasses.tabletLogoClass} />
      </TabletView>
    </>
  );
};

export default Logo;
