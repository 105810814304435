/* eslint-disable import/no-unresolved */
import { useNavigate } from 'react-router-dom';
import {
  Button,
  // Button,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import LoopIcon from '@mui/icons-material/Loop';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useCommonFunctionHook from '../../../hooks/common-function-hook';
import useAuth from '../../../hooks/use-auth';
import useMounted from '../../../hooks/use-mounted';
import { useStyles } from '../../../styles/common-style';

const LoginAmplify = () => {
  const { catchBlock } = useCommonFunctionHook();
  const styleClasses = useStyles();
  const mounted = useMounted();
  const history = useNavigate();
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        try {
          const loginUser = await login(values.email, values.password);
          if (loginUser.challengeName === 'SMS_MFA') {
            history('/authentication/verify-code', {
              state: {
                username: values.email,
                mode: 'SMS_MFA',
              },
            });
          }
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
            history('/dashboard');
          }
        } catch (err) {
          if (err.code === 'UserNotConfirmedException') {
            history('/authentication/verify-code', {
              state: {
                username: values.email,
              },
            });
          } else {
            catchBlock(err);
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            placeholder="Email Address"
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            placeholder="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && <FormHelperText error>{errors.submit}</FormHelperText>}
          <Button
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={styleClasses.blueButton}
          >
            {isSubmitting && <LoopIcon className={styleClasses.rotateIcon} />}
            Log In
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
