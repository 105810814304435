import React from 'react';
import { Box, Typography } from '@material-ui/core';
import lockedImage from '../../asset/page/locked-page-image.svg';
import { useStyles } from './styled';

const UnauthorizedPage = () => {
  const styleClasses = useStyles();
  return (
    <Box className={styleClasses.lockedPageContainer}>
      <Box className={styleClasses.lockedPageImageContainer}>
        <img width="50%" src={lockedImage} alt="locked icon" />
      </Box>
      <Box>
        <Typography align="center" className={styleClasses.lockedTextClass}>
          You don’t have permission to acess this page!
          <br />
          Talk to your administrator for acess.
        </Typography>
      </Box>
    </Box>
  );
};

export default UnauthorizedPage;
