/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stateLoader: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStateLoader(state, action) {
      state.stateLoader = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setStateLoader = (stateLoader) => async (dispatch) => {
  dispatch(slice.actions.setStateLoader(stateLoader));
};

export default slice;
