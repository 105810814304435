import { experimentalStyled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const LabelRoot = experimentalStyled('span')(({ theme, styleProps }) => {
  const backgroundColor = theme.palette[styleProps.color].main;
  const color = theme.palette[styleProps.color].contrastText;

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: theme.shape.borderRadius,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'center',
    minWidth: 20,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontSize: '13px',
    padding: '4px 8px',
    textAlign: 'center',
    // margin: '0px 4px 4px 0px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  };
});

const Label = (props) => {
  const { color = 'primary', children, ...other } = props;

  const styleProps = { color };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LabelRoot styleProps={styleProps} {...other}>
      {children}
    </LabelRoot>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success']),
};

export default Label;
