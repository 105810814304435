import { Box } from '@material-ui/core';
import { useStyles } from '../styles/common-style';
import Logo from './logo-component';

const SlashScreen = () => {
  const styleClasses = useStyles();
  return (
    <Box className={styleClasses.slashScreenClass}>
      <Logo />
    </Box>
  );
};

export default SlashScreen;
