/* eslint-disable react/jsx-props-no-spreading */
import { Step, StepLabel, Stepper } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '../Card';
import { useStyles } from './styled';

const Steper = ({ index, steps }) => {
  const styleClasses = useStyles();

  return (
    <Card className={styleClasses.secondCard}>
      <Stepper alternativeLabel className={styleClasses.stepperClasses} activeStep={index}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={`stepper-component-${label?.label}-${Math.random()}`} {...stepProps}>
              <StepLabel {...labelProps}>{label?.desc}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Card>
  );
};

Steper.propTypes = {
  index: PropTypes.number,
  steps: PropTypes.array,
};

export default Steper;
