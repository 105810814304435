import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  typographyMB: {
    marginBottom: '8px',
    whiteSpace: 'nowrap',
  },
  carouselImagePaper: {
    borderRadius: '0px',
    height: '145px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: '0px',
    padding: '0px',
    backgroundRepeat: 'no-repeat',
  },
  padding8Class: {
    padding: '8px',
  },
  cardListViewActionContainer: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
  },
}));
