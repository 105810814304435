export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    const table = document.createElement('table');
    table.style.margin = '0px';
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

const customToolTip = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');
    const tr = document.createElement('tr');
    const th = document.createElement('th');
    th.style.borderWidth = 0;
    th.style.fontWeight = '700';
    th.style.color = 'black';
    th.style.borderWidth = 0;
    th.style.fontSize = '15px';
    const text = document.createTextNode('TOTAL SALES');
    th.appendChild(text);
    tr.appendChild(th);
    tableHead.appendChild(tr);

    const tableFooter = document.createElement('tfoot');
    titleLines.forEach((title) => {
      const trFooter = document.createElement('tr');
      trFooter.style.borderWidth = 0;
      const thFooter = document.createElement('th');
      thFooter.style.borderWidth = 0;
      thFooter.style.color = 'black';
      thFooter.style.fontWeight = '400';
      thFooter.style.fontSize = '16px';
      const textFooter = document.createTextNode(title);
      thFooter.appendChild(textFooter);
      trFooter.appendChild(thFooter);
      tableFooter.appendChild(trFooter);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body) => {
      const trBody = document.createElement('tr');
      trBody.style.backgroundColor = 'inherit';
      trBody.style.borderWidth = 0;
      const td = document.createElement('td');
      td.style.borderWidth = 0;
      td.style.fontWeight = '600';
      td.style.color = '#145DA0';
      td.style.fontSize = '32px';
      td.style.textAlign = 'left';
      td.attributes.align = 'left';

      const textBody = document.createTextNode(`$${body}`);
      td.appendChild(textBody);
      trBody.appendChild(td);
      tableBody.appendChild(trBody);
    });
    const tableRoot = tooltipEl.querySelector('table');
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
    tableRoot.appendChild(tableFooter);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.background = '#EDF2F8';
  tooltipEl.style.textAlign = 'left';
  tooltipEl.style.borderRadius = '16px';
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
};

export default customToolTip;
