/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userPermissionTemplate: {},
  singleUserPermission: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPermissionTemplate(state, action) {
      state.userPermissionTemplate = action.payload;
    },
    setSingleUserPermission(state, action) {
      state.singleUserPermission = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setUserPermissionTemplate = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setUserPermissionTemplate(attibutes));
};

export const setSingleUserPermission = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setSingleUserPermission(attibutes));
};

export default slice;
