/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable semi */
/* eslint-disable jsx-quotes */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import appLogo from '../asset/logo/appLogo.svg';
import NavSection from '../components/nav-section-component';
import { DesktopView, MobileView, TabletView } from '../components/responsive-view-component';
import Scrollbar from '../components/scrollbar-table-component';
import CheckPermissions from '../hooks/check-permissions';
import useAuth from '../hooks/use-auth';
import { bottomSections, mobileTabletBottomSections, mobileTabletsections, sections } from '../static/navbar';
import { useSelector } from '../store';
import { NavBarBottomMobileTabletSection, NavBarBottomSection, useStyles } from '../styles/common-style';

const Sidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const styleClasses = useStyles();
  // const navigate = useNavigate()
  const { user } = useAuth();
  const { tenantLogo } = useSelector((state) => state.tenant);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box className={styleClasses.navBarSection}>
      <Scrollbar>
        <MobileView>
          <Box className={styleClasses.paddingOneSpc}>
            {mobileTabletsections.map((section) => (
              <NavSection
                isLocked={
                  section.pathnamekey === 'search'
                    ? false
                    : CheckPermissions(section?.modulename, '', section?.submodulename || '')
                }
                key={`mobile-sidebar-${section.pathnamekey}-${Math.random()}`}
                pathname={location.pathname}
                {...section}
              />
            ))}
          </Box>
          <NavBarBottomMobileTabletSection>
            {mobileTabletBottomSections.map((section) => (
              <NavSection
                isLocked={false}
                key={section.pathnamekey + Math.random()}
                pathname={location.pathname}
                {...section}
              />
            ))}
            <Typography align="center" className={styleClasses.headerUsertypography}>
              {process.env.REACT_APP_PROJECT_VERSION}
            </Typography>
          </NavBarBottomMobileTabletSection>
        </MobileView>
        <TabletView>
          <Box className={styleClasses.paddingOneSpc}>
            {mobileTabletsections.map((section) => (
              <NavSection
                isLocked={
                  section.pathnamekey === 'search'
                    ? false
                    : CheckPermissions(section?.modulename, '', section?.submodulename || '')
                }
                key={`tablet-sidebar-${section.pathnamekey}-${Math.random()}`}
                pathname={location.pathname}
                {...section}
              />
            ))}
          </Box>
          <NavBarBottomMobileTabletSection>
            {mobileTabletBottomSections.map((section) => (
              <NavSection
                isLocked={false}
                key={section.pathnamekey + Math.random()}
                pathname={location.pathname}
                {...section}
              />
            ))}
            <Typography align="center" className={styleClasses.headerUsertypography}>
              {process.env.REACT_APP_PROJECT_VERSION}
            </Typography>
          </NavBarBottomMobileTabletSection>
        </TabletView>
        <DesktopView>
          <Box className={styleClasses.userLogoSection}>
            {user && (
              <Typography align="center" className={styleClasses.headerUsertypography}>
                {tenantLogo !== '' && (
                  <img className={styleClasses.image50Width} src={appLogo} alt="userLogo" width="60px" />
                )}
                {/* {user?.name || ''} */}
              </Typography>
            )}
          </Box>
          <Box className={styleClasses.paddingOneSpc}>
            {sections.map((section) => (
              <NavSection
                isLocked={
                  section.pathnamekey === 'search'
                    ? false
                    : CheckPermissions(section?.modulename, '', section?.submodulename || '')
                }
                key={`desktop-sidebar-${section.pathnamekey}-${Math.random()}`}
                pathname={location.pathname}
                {...section}
              />
            ))}
          </Box>
          <NavBarBottomSection>
            {bottomSections.map((section) => (
              <NavSection
                isLocked={false}
                key={section.pathnamekey + Math.random()}
                pathname={location.pathname}
                {...section}
              />
            ))}
            <Typography align="center" className={styleClasses.headerUsertypography}>
              {process.env.REACT_APP_PROJECT_VERSION}
            </Typography>
          </NavBarBottomSection>
        </DesktopView>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        classes={{
          paper: styleClasses.lgDrawerClasses,
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      classes={{
        paper: styleClasses.smDrawerClasses,
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default Sidebar;
