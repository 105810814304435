/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Collapse, ListItem } from '@material-ui/core';
import LockIcon from '@mui/icons-material/Lock';
import { shutdown as shutdownIntercom } from 'intercom';
import PropTypes from 'prop-types';
import useMixPanelTrack from '../hooks/mix-panel';
import useAuth from '../hooks/use-auth';
import ChevronDownIcon from '../icons/chevron-down';
import ChevronRightIcon from '../icons/chevron-right';
import { useDispatch } from '../store';
import { useStyles } from '../styles/common-style';

const NavItem = (props) => {
  const { setMixPanelTrack } = useMixPanelTrack();
  const { active, children, depth, icon, info, open: openProp, path, title, isLocked, ...other } = props;
  const [open, setOpen] = useState(openProp);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styleClasses = useStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Branch
  if (children) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ListItem disableGutters className={styleClasses.navItemComponent} {...other}>
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          className={styleClasses.listItemButton}
          variant="text"
        >
          <Box>
            <LockIcon fontSize="large" />
          </Box>
          <Box className={styleClasses.flexGrow}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem disableGutters className={styleClasses.navItemComponent}>
      {isLocked === true && (
        <Button
          component={RouterLink}
          variant="text"
          to="/unauthorized"
          className={styleClasses.listDisabledItemButton}
        >
          <Box className={styleClasses.flexGrow}>
            <Box className={styleClasses.lockedMenuIcon}>
              <LockIcon fontSize="small" />
            </Box>
            {icon}
            <br />
            {title}
          </Box>
          {info}
        </Button>
      )}
      {isLocked === 'upgrade' && (
        <Button
          component={RouterLink}
          variant="text"
          to="/upgrade-plan"
          className={styleClasses.listDisabledItemButton}
        >
          <Box className={styleClasses.flexGrow}>
            <Box className={styleClasses.lockedMenuIcon}>
              <LockIcon fontSize="small" />
            </Box>
            {icon}
            <br />
            {title}
          </Box>
          {info}
        </Button>
      )}
      {isLocked === false && (
        <Button
          component={path && RouterLink}
          className={active ? styleClasses.listActiveItemButton : styleClasses.listItemButton}
          variant="text"
          to={path.includes('/logout') ? '/' : path}
          onClick={() => {
            if (path.includes('/logout')) {
              logout();
              shutdownIntercom();
              dispatch({ type: 'SIGNOUT_REQUEST' });
              window.localStorage.removeItem('accessToken');
              navigate('/');
              setMixPanelTrack({
                eventName: 'Logout',
              });
            }
          }}
        >
          <Box className={styleClasses.flexGrow}>
            {icon}
            <br />
            {title}
          </Box>
          {info}
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isLocked: PropTypes.any,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
