import moment from 'moment';

import 'moment-timezone';

const useLocalTimeZone = () => {
  const LocalTimezone = (date, dateFromFormat, dateFormat) => moment(date, dateFromFormat).format(dateFormat);

  const utcTimezone = (date) => moment(date).utc().toISOString();

  const getDaysFromDate = (date) => {
    if (date) {
      const dateLocal = new Date(date.replace(/-/g, '/'));
      const newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
      const localDate = new Date();
      const diffTime = Math.abs(localDate - newDate);
      const diffDays = Math.floor(diffTime / (1000 * 3600 * 24));
      return `${diffDays} Days`;
    }
    return '0 Days';
  };

  const getArrivalFlag = (date, dateFromFormat, dateFormat) => {
    const localtz = moment.tz.guess();
    const updatedTime = moment(date, dateFromFormat).tz(localtz).format(dateFormat);
    const arrivalDate = moment(updatedTime, 'YYYY-MM-DD hh:mm:ss');
    const currentDate = moment().tz(localtz);
    return arrivalDate.valueOf() < currentDate.valueOf();
  };

  const getLocalDateTime = (date, dateFormat) => {
    const localtz = moment.tz.guess();
    const updatedDate = moment(date).tz(localtz).format(dateFormat);
    return updatedDate;
  };

  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  };

  return { LocalTimezone, utcTimezone, getDaysFromDate, getArrivalFlag, getLocalDateTime, convertUTCDateToLocalDate };
};

export default useLocalTimeZone;
