/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Alert, Slide } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoopIcon from '@mui/icons-material/Loop';
import { setRemoveAlertData } from '../slices/master-reducer';
import { useDispatch, useSelector } from '../store';
import { useStyles } from '../styles/common-style';

const AlertContainerComponent = () => {
  const styleClasses = useStyles();
  const dispatch = useDispatch();
  const { alertData } = useSelector((state) => state.master);
  const clearPopup = (popInd) => {
    const removeAlert = alertData.filter((alert, ind) => ind !== popInd);
    dispatch(setRemoveAlertData(removeAlert));
  };
  return (
    <>
      {alertData.map((alert, alertInd) => (
        <React.Fragment key={`alert-popup-key-${alertInd}`}>
          {typeof alert?.type !== 'undefined' && alert?.type === 'success' && (
            <Slide direction="down" in={alert?.type === 'success'}>
              {/* <Snackbar
                        open={alert?.type === 'success'}
                        autoHideDuration={5000}
                        className={styleClasses.snackbarRoot}
                        onClose={() => clearPopup(alertInd)}
                      > */}
              <Alert
                iconMapping={{
                  success: <CheckCircleIcon htmlColor="#fff" fontSize="inherit" />,
                }}
                onClose={() => clearPopup(alertInd)}
                className={styleClasses.alertSuccessRootClass}
              >
                {typeof alert?.message !== 'undefined' && alert?.message}
              </Alert>
              {/* </Snackbar> */}
            </Slide>
          )}
          {typeof alert?.type !== 'undefined' && alert?.type === 'warning' && (
            <Slide direction="down" in={alert?.type === 'warning'}>
              <Alert
                iconMapping={{
                  warning: <LoopIcon htmlColor="#fff" fontSize="inherit" className={styleClasses.rotateIcon} />,
                }}
                severity="warning"
                className={styleClasses.alertWarningRootClass}
              >
                {typeof alert?.message !== 'undefined' && alert?.message}
              </Alert>
            </Slide>
          )}
          {typeof alert?.type !== 'undefined' && alert?.type === 'error' && (
            <Slide direction="down" in={alert?.type === 'error'}>
              <Alert
                iconMapping={{
                  error: <CancelIcon htmlColor="#fff" fontSize="inherit" />,
                }}
                severity="error"
                onClose={() => clearPopup(alertInd)}
                className={styleClasses.alertErrorRootClass}
              >
                {typeof alert?.message !== 'undefined' && alert?.message}
              </Alert>
            </Slide>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default AlertContainerComponent;
