import { FormLabel, TextField } from '@material-ui/core';
import styled from '@emotion/styled';

export const StyledTextAreaField = styled(TextField)((props) => ({
  marginTop: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& .MuiOutlinedInput-input': {
      [props.theme.breakpoints.up('1900')]: {
        fontSize: '14px',
      },
      [props.theme.breakpoints.down('1900')]: {
        fontSize: '12px',
      },
      [props.theme.breakpoints.down('1450')]: {
        fontSize: '10px',
      },
      fontWeight: 600,
      lineHeight: '19px',
      letterSpacing: '0.15px',
      color: '#37474F',
    },
    '& .MuiInputBase-inputMultiline': {
      padding: '8px 10px',
      height: '19px !important',
    },
    '& legend': {
      [props.theme.breakpoints.up('1900')]: {
        fontSize: '12px',
      },
      [props.theme.breakpoints.down('1900')]: {
        fontSize: '11px',
      },
      [props.theme.breakpoints.down('1450')]: {
        fontSize: '10px',
      },
      height: '3px',
    },
    '& fieldset': {
      border: '1.2px solid #37474F !important',
    },
    '&.Mui-focused fieldset': {
      border: '2.4px solid #145DA0 !important',
    },
  },
  '& .MuiInputLabel-root': {
    [props.theme.breakpoints.up('1900')]: {
      fontSize: '16px',
    },
    [props.theme.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [props.theme.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
    color: '#37474F !important',
  },
  '& .MuiIconButton-root': {
    color: '#37474F',
    '& .MuiSvgIcon-root': {
      width: '17px',
    },
  },
}));

export const StyledFormLabel = styled(FormLabel)((props) => ({
  [props.theme.breakpoints.up('1900')]: {
    fontSize: '14px',
  },
  [props.theme.breakpoints.down('1900')]: {
    fontSize: '12px',
  },
  [props.theme.breakpoints.down('1450')]: {
    fontSize: '10px',
  },
  fontFamily: 'Inter !important',
  fontStyle: 'normal !important',
  fontWeight: 600,
  lineHeight: '12px',
  letterSpacing: '0.15px',
  color: '#37474F',
  marginBottom: '5px',
  marginLeft: '5px',
}));

export const StyledErrorFormLabel = styled(FormLabel)((props) => ({
  [props.theme.breakpoints.up('1900')]: {
    fontSize: '14px',
  },
  [props.theme.breakpoints.down('1900')]: {
    fontSize: '12px',
  },
  [props.theme.breakpoints.down('1450')]: {
    fontSize: '10px',
  },
  fontFamily: 'Inter !important',
  fontStyle: 'normal !important',
  fontWeight: 600,
  lineHeight: '12px',
  letterSpacing: '0.15px',
  color: '#C44D56',
  marginBottom: '5px',
  marginLeft: '5px',
}));
