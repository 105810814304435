/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchPartTraderData: {
    hidden: 'False',
  },
  partTraderTableAttributes: {},
  partTraderTableVariable: [],
  partTraderSuggestedPartTableAttributes: {},
  partTraderSuggestTableVariable: {},
  partTraderSuggestVehicleTableVariable: {},
  partTraderSuggestedVehicleTableAttributes: {},
  partTraderDetailsData: {},
};

const slice = createSlice({
  name: 'partTrader',
  initialState,
  reducers: {
    setSearchPartTraderData(state, action) {
      state.searchPartTraderData = action.payload;
    },
    setPartTraderTableAttributes(state, action) {
      state.partTraderTableAttributes = action.payload;
    },
    setPartTraderTableVariable(state, action) {
      state.partTraderTableVariable = action.payload;
    },
    setPartTraderSuggestedPartTableAttributes(state, action) {
      state.partTraderSuggestedPartTableAttributes = action.payload;
    },
    setPartTraderSuggestTableVariable(state, action) {
      state.partTraderSuggestTableVariable = action.payload;
    },
    setPartTraderSuggestVehicleTableVariable(state, action) {
      state.partTraderSuggestVehicleTableVariable = action.payload;
    },
    setPartTraderSuggestedVehicleTableAttributes(state, action) {
      state.partTraderSuggestedVehicleTableAttributes = action.payload;
    },
    setPartTraderDetailsData(state, action) {
      state.partTraderDetailsData = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setSearchPartTraderData = (search) => async (dispatch) => {
  dispatch(slice.actions.setSearchPartTraderData(search));
};

export const setPartTraderTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderTableAttributes(attibutes));
};

export const setPartTraderTableVariable = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderTableVariable(attibutes));
};

export const setPartTraderSuggestedPartTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderSuggestedPartTableAttributes(attibutes));
};

export const setPartTraderSuggestTableVariable = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderSuggestTableVariable(attibutes));
};

export const setPartTraderSuggestVehicleTableVariable = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderSuggestVehicleTableVariable(attibutes));
};

export const setPartTraderSuggestedVehicleTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderSuggestedVehicleTableAttributes(attibutes));
};

export const setPartTraderDetailsData = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTraderDetailsData({ ...attibutes }));
};
export default slice;
