/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import partApi from '../api/partApi';

const initialState = {
  dashboardAttributes: {
    limit: 10,
    page: 0,
    order: 'desc',
  },
  isTableReload: false,
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardAttributes(state, action) {
      state.dashboardAttributes = action.payload;
    },
    setIsTableReload(state, action) {
      state.isTableReload = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setDashboardAttributes = (payload) => async (dispatch) => {
  dispatch(slice.actions.setDashboardAttributes(payload));
};
export const setIsTableReload = (payload) => async (dispatch) => {
  dispatch(slice.actions.setIsTableReload(payload));
};
export default slice;
