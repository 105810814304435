/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItemsInv: [],
  cartAddItems: [],
  editPageCartItemsInv: [],
  editDBCartItemsInv: [],
  addInvoiceCartItemsInv: [],
  removeDBCartItemsInv: [],
  formData: {},
  invoiceAddItemsData: {},
  cartAddItemsInv: [],
  searchData: {},
  tableAttributes: {},
  invoiceTableVariable: [],
  setIsShouldChange: '',
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setCartItemsInv(state, action) {
      state.cartItemsInv = action.payload;
    },
    setEditPageCartItemsInv(state, action) {
      state.editPageCartItemsInv = action.payload;
    },
    setFormData(state, action) {
      state.formData = action.payload;
    },
    setCartAddItems(state, action) {
      state.cartAddItemsInv = action.payload;
    },
    setEditDBCartItems(state, action) {
      state.editDBCartItemsInv = action.payload;
    },
    seAddInvoiceCartItemsInv(state, action) {
      state.addInvoiceCartItemsInv = action.payload;
    },
    setRemoveDBCartItems(state, action) {
      state.removeDBCartItemsInv = action.payload;
    },
    setInvoiceAddItemsData(state, action) {
      state.invoiceAddItemsData = action.payload;
    },
    setSearchData(state, action) {
      state.searchData = action.payload;
    },
    setTableAttributes(state, action) {
      state.tableAttributes = action.payload;
    },
    setInvoiceTableVariable(state, action) {
      state.invoiceTableVariable = action.payload;
    },
    setIsShouldChange(state, action) {
      state.isShouldChange = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setCartItemsReduxInv =
  (cartItemsInv, flag = '') =>
  async (dispatch) => {
    if (cartItemsInv) {
      if (flag !== '' && flag === 'edit') {
        dispatch(slice.actions.setEditPageCartItemsInv([...cartItemsInv]));
      } else {
        dispatch(slice.actions.setCartItemsInv([...cartItemsInv]));
      }
    }
  };
export const setFormDataRedux = (formData) => async (dispatch) => {
  dispatch(slice.actions.setFormData({ ...formData, ...formData }));
};
export const setCartAddItemsReduxInv = (cartItemsInv) => async (dispatch) => {
  dispatch(slice.actions.setCartAddItems([...cartItemsInv]));
};
export const setEditDBCartItems = (cartItemsInv) => async (dispatch) => {
  dispatch(slice.actions.setEditDBCartItems(cartItemsInv));
};
export const seAddInvoiceCartItemsInv = (addInvoiceCartItemsInv) => async (dispatch) => {
  dispatch(slice.actions.seAddInvoiceCartItemsInv(addInvoiceCartItemsInv));
};
export const setRemoveDBCartItems = (cartItemsInv) => async (dispatch) => {
  dispatch(slice.actions.setRemoveDBCartItems(cartItemsInv));
};
export const setInvoiceAddItemsData = (invoiceAddItemsData) => async (dispatch) => {
  dispatch(slice.actions.setInvoiceAddItemsData(invoiceAddItemsData));
};
export const setInvoiceTableVariable = (variable) => async (dispatch) => {
  dispatch(slice.actions.setInvoiceTableVariable(variable));
};
export const setTableAttributes = (attributes) => async (dispatch) => {
  dispatch(slice.actions.setTableAttributes(attributes));
};
export const setSearchData = (searchData) => async (dispatch) => {
  dispatch(slice.actions.setSearchData(searchData));
};
export const setIsShouldChange = (view) => async (dispatch) => {
  dispatch(slice.actions.setIsShouldChange(view));
};

export default slice;
