/* eslint-disable import/no-unresolved */
import React from 'react';
import { TablePagination as TablePaginationComponent } from '@material-ui/core';
import useTableSort from 'hooks/useTableSort';
import PropTypes from 'prop-types';

const TablePagination = ({ totalRecords, handlePageChange, handleLimitChange, page, limit }) => {
  const { tableRowsLimit } = useTableSort();

  return (
    <TablePaginationComponent
      component="div"
      count={totalRecords}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={tableRowsLimit()}
    />
  );
};

TablePagination.propTypes = {
  totalRecords: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleLimitChange: PropTypes.func,
  page: PropTypes.number,
  limit: PropTypes.number,
};

export default TablePagination;
