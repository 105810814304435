/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRelaod: false,
};

const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setIsReload(state, action) {
      state.isRelaod = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setIsReload = (payload) => async (dispatch) => {
  dispatch(slice.actions.setIsReload(payload));
};

export default slice;
