/* eslint-disable react/display-name */
import React from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import QrCodeGenerator from '../../service/qr-code-generator';
import { useStyles } from '../../styles/common-style';

const PrintQRCode = React.forwardRef((props, ref) => {
  const { Payload, tenantLogo } = props;
  const styleClasses = useStyles();
  const LocationQrData = {
    type: 'location',
    location_name: Payload?.LocationName || '',
    location_id: Payload?.LocationID || '',
  };

  return (
    <Box className={styleClasses.printPageContainer} ref={ref}>
      <Grid container spacing={3} direction="row">
        <Grid item justifyContent="left" alignItems="left" className={styleClasses.printBox}>
          <Box className={styleClasses.printBoxContainer}>
            <Table border="0" className={styleClasses.card}>
              <TableBody>
                <TableRow valign="middle">
                  <TableCell width="75%" align="center" valign="middle" className={styleClasses.printCodeTableCell}>
                    {(() => {
                      const locationNameCount = Payload?.LocationName.length;
                      let fontSizeDynamic = '30px';
                      if (locationNameCount < 10 && locationNameCount > 5) {
                        fontSizeDynamic = '35px';
                      } else if (locationNameCount < 6) {
                        fontSizeDynamic = '40px';
                      }
                      return (
                        <Typography
                          align="center"
                          className={styleClasses.printCodeTableCellTypography}
                          sx={{ fontSize: fontSizeDynamic }}
                        >
                          {`${Payload?.LocationName?.substring(0, 20)}`}
                        </Typography>
                      );
                    })()}
                  </TableCell>
                  <TableCell valign="top" width="25%" align="right" className={styleClasses.printQRCodeSection}>
                    <QrCodeGenerator Data={JSON.stringify(LocationQrData)} />
                  </TableCell>
                </TableRow>
                <TableRow valign="bottom" align="center" className={styleClasses.printCodeBottomImageCell}>
                  <TableCell
                    width="100%"
                    colSpan={2}
                    align="center"
                    className={styleClasses.printCodeBottomImageSection}
                  >
                    <img src={tenantLogo} alt="logo" width="140px" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

PrintQRCode.propTypes = {
  Payload: PropTypes.object,
  tenantLogo: PropTypes.string,
};

export default PrintQRCode;
