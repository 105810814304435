/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchCapricornData: {},
  capricornTableAttributes: {},
  capricornTableVariable: [],
  capricornSuggestedPartTableAttributes: {},
  capricornSuggestTableVariable: {},
  capricornSuggestVehicleTableVariable: {},
  capricornSuggestedVehicleTableAttributes: {},
};

const slice = createSlice({
  name: 'capricorn',
  initialState,
  reducers: {
    setSearchData(state, action) {
      state.searchCapricornData = action.payload;
    },
    setCapricornTableAttributes(state, action) {
      state.capricornTableAttributes = action.payload;
    },
    setCapricornTableVariable(state, action) {
      state.capricornTableVariable = action.payload;
    },
    setCapricornSuggestedPartTableAttributes(state, action) {
      state.capricornSuggestedPartTableAttributes = action.payload;
    },
    setCapricornSuggestTableVariable(state, action) {
      state.capricornSuggestTableVariable = action.payload;
    },
    setCapricornSuggestVehicleTableVariable(state, action) {
      state.capricornSuggestVehicleTableVariable = action.payload;
    },
    setCapricornSuggestedVehicleTableAttributes(state, action) {
      state.capricornSuggestedVehicleTableAttributes = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setSearchData = (search) => async (dispatch) => {
  dispatch(slice.actions.setSearchData(search));
};

export const setCapricornTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCapricornTableAttributes(attibutes));
};

export const setCapricornTableVariable = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCapricornTableVariable(attibutes));
};

export const setCapricornSuggestedPartTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCapricornSuggestedPartTableAttributes(attibutes));
};

export const setCapricornSuggestTableVariable = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCapricornSuggestTableVariable(attibutes));
};

export const setCapricornSuggestVehicleTableVariable = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCapricornSuggestVehicleTableVariable(attibutes));
};

export const setCapricornSuggestedVehicleTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCapricornSuggestedVehicleTableAttributes(attibutes));
};
export default slice;
