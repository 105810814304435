import React from 'react';
import { Box, Card, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useStyles } from '../../styles/common-style';
import SkeletonComponent from './skeleton-component';

const CardListSkeletonComponent = () => {
  const styleClasses = useStyles();
  const isMobileScreen = useMediaQuery('(max-width:480px)');
  const array = Array(10)
    .fill()
    .map(() => 50 * Math.random());

  return (
    <Grid container spacing={2} direction="row" alignItems="top" justifyContent="left" mt="5px">
      {array.map((itm) => (
        <Grid
          key={`card-list-skeletop-index-${itm}.toString()`}
          item
          md={3}
          sm={3}
          xs={12}
          lg={3}
          justifyContent="center"
          alignItems="top"
          textAlign="center"
        >
          <Card>
            {!isMobileScreen && (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="center"
                className={styleClasses.typographyMB}
              >
                <Grid item md={12} sm={12} xs={12} lg={12} justifyContent="center" alignItems="center">
                  <SkeletonComponent variant="rectangular" width="100%" height="100%">
                    <Paper className={styleClasses.carouselImagePaper} />
                  </SkeletonComponent>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1} direction="row" className={styleClasses.padding8Class} justifyContent="right">
              {isMobileScreen && (
                <>
                  <Grid item md={12} sm={12} xs={6} lg={12} justifyContent="center" alignItems="center">
                    <SkeletonComponent variant="rectangular" width="100%" height="100%">
                      <Paper className={styleClasses.mobileCarouselImagePaper} />
                    </SkeletonComponent>
                  </Grid>
                  <Grid item md={12} sm={12} xs={6} lg={12} justifyContent="center" alignItems="center">
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                  </Grid>
                </>
              )}
              {!isMobileScreen && (
                <>
                  <Grid item md={3} sm={3} xs={6} lg={3}>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                  </Grid>
                  <Grid item md={3} sm={3} xs={6} lg={3}>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                  </Grid>
                  <Grid item md={3} sm={3} xs={6} lg={3}>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                  </Grid>
                  <Grid item md={3} sm={3} xs={6} lg={3}>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <SkeletonComponent width="100%">
                      <Typography variant="body1">.</Typography>
                    </SkeletonComponent>
                  </Grid>
                  <Grid item textAlign="right" md={6} xs={6}>
                    <Box className={styleClasses.cardListViewActionContainer}>
                      <>
                        <SkeletonComponent width="100%">
                          <Typography variant="body1">.</Typography>
                        </SkeletonComponent>
                        <SkeletonComponent width="100%">
                          <Typography variant="body1">.</Typography>
                        </SkeletonComponent>
                      </>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardListSkeletonComponent;
