/* eslint-disable semi */
/* eslint-disable jsx-quotes */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import Profile from '../asset/icons/page/profile.svg';
import { MobileView, TabletView } from '../components/responsive-view-component';
import Logo from '../components/white-logo-component';
import useAuth from '../hooks/use-auth';
import { useDispatch } from '../store';
import { DashboardNavbarRoot, useStyles } from '../styles/common-style';

const Header = (props) => {
  const { user } = useAuth();
  const { logout } = useAuth();
  const styleClasses = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <>
      <TabletView>
        <DashboardNavbarRoot {...other}>
          <Toolbar className={styleClasses.sideBarToolBarClass}>
            <IconButton color="inherit" onClick={onSidebarMobileOpen}>
              <MenuIcon htmlColor="#145DA0" fontSize="large" />
            </IconButton>
            <div className="main-header-sec">
              <div className="logo-sec logo-sec-mobile">
                <Link to="/search">
                  <Logo />
                </Link>
              </div>
              <div className="login-profile-icons-maindiv">
                {user && (
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                      dispatch({ type: 'SIGNOUT_REQUEST' });
                      window.localStorage.removeItem('accessToken');
                      navigate('/');
                    }}
                    className="logout"
                  >
                    <img src={Profile} alt="profile" />
                    <h6>Logout</h6>
                  </Link>
                )}
              </div>
            </div>
          </Toolbar>
        </DashboardNavbarRoot>
      </TabletView>
      <MobileView>
        <DashboardNavbarRoot {...other}>
          <Toolbar className={styleClasses.sideBarToolBarClass}>
            <IconButton onClick={onSidebarMobileOpen} className={styleClasses.menuButton}>
              <MenuIcon htmlColor="#145DA0" fontSize="large" />
            </IconButton>
            <div className="main-header-sec">
              <div className="logo-sec logo-sec-mobile">
                <Link to="/search">
                  <Logo />
                </Link>
              </div>
              <div className="login-profile-icons-maindiv">
                {user && (
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                      dispatch({ type: 'SIGNOUT_REQUEST' });
                      window.localStorage.removeItem('accessToken');
                      navigate('/');
                    }}
                    className="logout"
                  >
                    <img src={Profile} alt="profile" />
                    <h6>Logout</h6>
                  </Link>
                )}
              </div>
            </div>
          </Toolbar>
        </DashboardNavbarRoot>
      </MobileView>
    </>
  );
};

Header.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Header;
