import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupIcon from '@mui/icons-material/Group';
import PlaceIcon from '@mui/icons-material/Place';
import SearchIcon from '@mui/icons-material/Search';
import PartIcon from '../icons/part-icon';
import PartTraderIcon from '../icons/part-trader-icon';
import { permissionKeys } from './permissions';

export const sections = [
  {
    pathnamekey: 'dashboard',
    modulename: permissionKeys.DASHBOARD,
    submodulename: 'ACCESS',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <DashboardIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'search',
    items: [
      {
        title: 'Search',
        path: '/search',
        icon: <SearchIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'vehicles/add',
    modulename: permissionKeys.VEHICLE,
    submodulename: 'CREATE',
    items: [
      {
        title: 'Add Vehicle',
        path: '/vehicles/add',
        icon: <DirectionsCarIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'parts/new',
    modulename: permissionKeys.PART,
    submodulename: 'CREATE',
    items: [
      {
        title: 'Add Part',
        path: '/parts/new',
        icon: <PartIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'part-trader',
    modulename: permissionKeys.PARTSTRADER,
    submodulename: 'VIEW_REQUEST',
    items: [
      {
        title: 'PartsTrader',
        path: '/part-trader',
        icon: <PartTraderIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'work-order',
    modulename: permissionKeys.WORKORDER,
    submodulename: 'VIEW',
    items: [
      {
        title: 'Work Orders',
        path: '/work-order',
        icon: <AssignmentIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'invoice',
    modulename: permissionKeys.INVOICE,
    submodulename: 'VIEW',
    items: [
      {
        title: 'Invoices',
        path: '/invoice',
        icon: <CalculateRoundedIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'contact',
    modulename: permissionKeys.CONTACT,
    submodulename: 'VIEW',
    items: [
      {
        title: 'Contacts',
        path: '/contact',
        icon: <AssignmentIndIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'location',
    modulename: permissionKeys.LOCATION,
    submodulename: 'VIEW',
    items: [
      {
        title: 'Locations',
        path: '/location',
        icon: <PlaceIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'users',
    modulename: permissionKeys.USER,
    submodulename: 'VIEW_USER_LIST',
    items: [
      {
        title: 'Users',
        path: '/users',
        icon: <GroupIcon fontSize="medium" />,
      },
    ],
  },
];

export const bottomSections = [
  {
    pathnamekey: 'account',
    items: [
      {
        title: 'Profile',
        path: '/account',
        icon: <AccountCircleIcon fontSize="medium" />,
      },
    ],
  },
  {
    items: [
      {
        title: 'Logout',
        path: '/logout',
        icon: <ExitToAppIcon fontSize="medium" />,
      },
    ],
  },
];

export const mobileTabletsections = [
  {
    pathnamekey: 'dashboard',
    modulename: permissionKeys.DASHBOARD,
    submodulename: 'ACCESS',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <DashboardIcon fontSize="medium" />,
      },
    ],
  },
  {
    pathnamekey: 'search',
    items: [
      {
        title: 'Search',
        path: '/search',
        icon: <SearchIcon fontSize="medium" />,
      },
    ],
  },
  {
    modulename: permissionKeys.VEHICLE,
    submodulename: 'CREATE',
    pathnamekey: 'vehicles/add',
    items: [
      {
        title: 'Add Vehicle',
        path: '/vehicles/add',
        icon: <DirectionsCarIcon fontSize="medium" />,
      },
    ],
  },
  {
    modulename: permissionKeys.PART,
    submodulename: 'CREATE',
    pathnamekey: 'parts/new',
    items: [
      {
        title: 'Add Part',
        path: '/parts/new',
        icon: <PartIcon fontSize="medium" />,
      },
    ],
  },
];

export const mobileTabletBottomSections = [
  {
    pathnamekey: 'account',
    items: [
      {
        title: 'Profile',
        path: '/account',
        icon: <AccountCircleIcon fontSize="medium" />,
      },
    ],
  },
  {
    items: [
      {
        title: 'Logout',
        path: '/logout',
        icon: <ExitToAppIcon fontSize="medium" />,
      },
    ],
  },
];
