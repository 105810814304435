import QRCode from 'react-qr-code';
import PropTypes from 'prop-types';

const QrCodeGenerator = (props) => {
  const { Data } = props;
  return <QRCode size={105} id="QRCode" value={Data} />;
};

QrCodeGenerator.propTypes = {
  Data: PropTypes.string,
};

export default QrCodeGenerator;
