/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userIdleState: false,
};

const slice = createSlice({
  name: 'userActivity',
  initialState,
  reducers: {
    setUserIdleState(state, action) {
      state.userIdleState = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setUserIdleState = (stateFlag) => async (dispatch) => {
  dispatch(slice.actions.setUserIdleState(stateFlag));
};

export default slice;
