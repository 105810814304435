import axios from 'axios';
import checkUserAuth from '../api/check-user-auth';

export const baseURL = process.env.REACT_APP_API_BASE_URL;

const Api = axios.create({
  baseURL,
});

Api.interceptors.request.use((req) => {
  const token = checkUserAuth();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

Api.defaults.headers.post['Content-Type'] = 'application/json';

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.assign('/unauthorized');
    }
    return Promise.reject(error);
  },
);

export default Api;
