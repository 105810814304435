import { AppBar } from '@material-ui/core';
import { createTheme, experimentalStyled } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';

const themeStyle = createTheme();

export const useStyles = makeStyles(() => ({
  pageContainer: {
    backgroundColor: '#f4f5f7',
    // minHeight: '100%',
    paddingBottom: '16px',
    [themeStyle.breakpoints.down('900')]: {
      marginTop: '80px',
    },
  },
  pageCenterContainer: {
    backgroundColor: '#f4f5f7',
    minHeight: '90%',
    paddingBottom: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  filterPageContainer: {
    backgroundColor: '#f4f5f7',
    minHeight: '100%',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  cancelButton: {
    marginTop: '8px',
    marginRight: '8px',
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    '&:hover': { backgroundColor: '#910101' },
    height: '40px',
  },
  cancelSquareButton: {
    marginTop: '8px',
    marginRight: '8px',
    borderRadius: '8px',
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    '&:hover': { backgroundColor: '#910101' },
    height: '40px',
  },
  cancelToolbarButton: {
    marginRight: '8px',
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    '&:hover': { backgroundColor: '#910101' },
    height: '40px',
  },
  blueButton: {
    marginTop: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueErrorPageHomeButton: {
    marginTop: '8px',
    marginLeft: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueSkipButton: {
    marginTop: '8px',
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  searchClearButton: {
    marginRight: '8px',
    marginTop: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueActionButton: {
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueActionSquarButton: {
    marginRight: '8px',
    borderRadius: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueNoWrapActionButton: {
    marginRight: '8px',
    whiteSpace: 'nowrap',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  blueActionLastButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  pdfDownloadButton: {
    textDecoration: 'none',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
    padding: '9px 16px',
    borderRadius: '8px',
    marginRight: '8px',
  },
  blueActionLastSquareButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    borderRadius: '8px',
    height: '40px',
  },
  blueSearchButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    marginBottom: '10px',
    height: '40px',
  },
  blueSearchSquareOutlineButton: {
    color: '#145DA0',
    border: '2px solid #145DA0',
    marginBottom: '8px',
    borderRadius: '8px',
    height: '40px',
    '&:hover': {
      color: '#145DA0',
      border: '2px solid #145DA0',
    },
  },
  blueSearchSquareActionButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '0px',
    padding: '5px',
    // height: '40px',
  },
  blueSearchSquareButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    marginBottom: '8px',
    borderRadius: '8px',
    height: '40px',
  },
  blueAddButton: {
    marginTop: '0px',
    marginBottom: '0px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    height: '40px',
  },
  blueToolbarButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '14px 10.5px',
    height: '40px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    // margin: '0px 3px 3px 0px',
  },
  blueToolbarOutlineButton: {
    color: '#145DA0',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    padding: '12px 10px',
    '&:hover': {
      color: '#145DA0',
      border: '2px solid #145DA0',
    },
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    // margin: '0px 3px 3px 0px',
    height: '40px',
  },
  blueToolbarLoadingButton: {
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    height: '40px',
  },
  blueToolbarLoadingSquareButton: {
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    height: '40px',
  },
  blueToolbarNoWrapButton: {
    marginTop: '0px',
    whiteSpace: 'nowrap',
    marginBottom: '0px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    height: '40px',
  },
  blueToolBarSquareButton: {
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    borderRadius: '8px !important',
    height: '40px',
  },
  blueToolbarNoWrapSquareButton: {
    marginTop: '0px',
    whiteSpace: 'nowrap',
    marginBottom: '0px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    borderRadius: '8px',
    height: '40px',
  },
  addButtonCardContent: {
    padding: '8px',
    '&:last-child': {
      paddingBottom: '8px !important',
    },
  },
  filterCardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px !important',
    },
  },
  padding8CardContentClass: {
    borderRadius: '8px',
    padding: '8px',
    '&:last-child': {
      paddingBottom: '8px !important',
    },
  },
  filterFieldSection: {
    // overflow: 'auto',
    height: 'calc(100vh - 180px)',
    paddingRight: '4px',
    width: '108%',
  },
  filterFieldSectionV2: {
    overflow: 'auto',
    height: 'calc(100vh - 183px)',
    paddingRight: '5px',
    width: '103%',
  },
  filterWithoutToggleFieldSection: {
    overflow: 'auto',
    height: 'calc(100vh - 130px)',
    paddingRight: '4px',
    width: '107%',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  learnFieldSection: {
    overflow: 'auto',
    height: '86vh',
    paddingRight: '5px',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: '15px !important',
    },
  },
  checkBox: {
    '&:hover': { background: 'none' },
    m: 0,
    pr: 0,
  },
  tableCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: '#000',
  },
  tableInvoiceFooterCell: {
    padding: '0px 10px 15px 10px',
    wordBreak: 'break-word',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: '#000',
    borderBottom: 'none',
  },
  tableNoBreakCell: {
    padding: '15px 10px 15px 10px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: '#000',
  },
  noWrapClass: {
    whiteSpace: 'nowrap',
  },
  labelClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    padding: '4px 8px',
    textAlign: 'center',
    color: '#37474F',
    margin: '0px 8px 4px 0px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    display: 'inline-block',
    backgroundColor: '#E6E8F0',
  },
  otherLabelClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    padding: '4px 8px',
    textAlign: 'center',
    // color: '#37474F',
    margin: '0px 8px 4px 0px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    display: 'inline-block',
    // backgroundColor: '#E6E8F0',
  },
  otherFirstLabelClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    padding: '4px 8px',
    textAlign: 'center',
    // color: '#37474F',
    margin: '0px 8px 4px 0px',
    marginLeft: '8px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    display: 'inline-block',
    // backgroundColor: '#E6E8F0',
  },
  labelLastClass: {
    fontSize: '13px',
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    textAlign: 'center',
    backgroundColor: '#0C2D48',
    margin: '0px 0px 4px 0px',
    display: 'inline',
  },
  labelTabletClass: {
    // fontSize: '13px',
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 10,
    textAlign: 'center',
    backgroundColor: '#0C2D48',
    margin: '0px 8px 4px 0px',
    display: 'inline',
  },
  labelInvoiceClass: {
    fontSize: '13px',
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    textAlign: 'center',
    backgroundColor: '#0C2D48',
    margin: '0px',
    display: 'inline',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    padding: '0px 10px 10px 10px',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  tableFooterInvoiceHeadCell: {
    fontWeight: 'bold',
    borderBottom: 'none',
    padding: '0px 10px 10px 10px',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  tableHeadPrimaryCell: {
    padding: '10px 10px 10px 10px',
    whiteSpace: 'nowrap',
    // fontSize: '15px',
    color: '#145DA0',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '250%',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    '& .MuiTableSortLabel-root': {
      color: '#145DA0',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  tableHeadTitleCell: {
    fontWeight: 'bold',
    padding: '5px 10px 15px 10px',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    border: 'none',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#ffffff',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    paddingBottom: '16px',
    textAlign: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    maxHeight: '98vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalMobileStyle: {
    position: 'absolute',
    paddingBottom: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    backgroundColor: '#ffffff',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    textAlign: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    maxHeight: '98vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalLgStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    backgroundColor: '#ffffff',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    textAlign: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    maxHeight: '98vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalLargeStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    backgroundColor: '#ffffff',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    textAlign: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    maxHeight: '98vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalMediumStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    backgroundColor: '#ffffff',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    textAlign: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    maxHeight: '98vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalContentContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  modalActionContainer: {
    textAlign: 'right',
    marginTop: '8px',
  },
  modalFormActionContainer: {
    textAlign: 'right',
    marginTop: '16px',
    marginBottom: '16px',
  },
  modalFormBMNActionContainer: {
    textAlign: 'right',
    marginTop: '16px',
    marginBottom: '0px',
  },
  dNone: {
    display: 'none',
  },
  dInline: {
    display: 'inline',
  },
  dFlexClass: {
    display: 'flex',
  },
  checkbox: {
    color: '#145DA0',
  },
  modalTitle: {
    marginBottom: '8px',
    padding: '16px',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    color: '#fff',
    background: '#145DA0',
  },
  typography: {
    marginBottom: '8px',
    marginTop: '8px',
  },
  typographyMinus: {
    marginTop: '8px',
    marginBottom: '-4px',
  },
  typographyMB: {
    marginBottom: '8px',
    whiteSpace: 'nowrap',
  },
  typographyMT: {
    marginTop: '8px',
  },
  marginTop8: {
    marginTop: '8px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginTop7: {
    marginTop: '7px',
  },
  boxTitle: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  boxPageTitle: {
    marginTop: '16px',
    fontSize: '40px',
    lignHeight: '44px',
    fontWeight: 700,
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  boxPageTitleZeroMargin: {
    margin: '0px 0px 16px 0px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '40px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '38px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '35px',
    },
    lignHeight: '44px',
    fontWeight: 700,
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  boxPageTitleMarginTop: {
    margin: '16px 0px 16px 0px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '40px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '38px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '35px',
    },
    lignHeight: '44px',
    fontWeight: 700,
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  boxPageTitleDailogBox: {
    margin: '0px',
    fontSize: '40px',
    lignHeight: '44px',
    fontWeight: 700,
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  boxPopupTitle: {
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  textField: {
    marginBottom: '16px',
    marginRight: '5px',
  },
  mB2: {
    marginBottom: '16px',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  secondCard: {
    marginTop: '16px',
  },
  invoicePreviewA4Size: {
    marginTop: '16px',
    // width: '1100px',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
  },
  toolbarSecondCard: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  secondCardV2: {
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
  },
  pageStartElement: {
    marginTop: '8px',
  },
  chip: {
    '& .MuiChip-root': {
      background: '#0C2D48',
      color: '#fff',
      '&:svg': {
        color: '#fff',
      },
    },
    background: '#0C2D48',
    color: '#fff',
    '&:svg': {
      color: '#fff',
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignItems: {
    alignItems: 'center',
    display: 'flex',
  },
  tabLabelWithIconClass: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    // color: '#145DA0',
    textTransform: 'capitalize',
  },
  redButton: {
    marginRight: '8px',
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '32px',
  },
  loginContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginY: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  mobileIconFilterButton: {
    '&:hover': {
      background: 'none',
    },
    padding: 0,
    margin: '0px 0px 0px -5px',
  },
  toggleFirstActiveButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '50%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleFirstActiveTabletButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '130px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleFirstInActiveButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '50%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleFirstInActiveTabletButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '130px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleSecondActiveButton: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '50%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleSecondActiveTabletButton: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '130px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleThirdLevelMiddleInActiveButton: {
    borderRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '33%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleThirdLevelMiddleActiveButton: {
    borderRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '33%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleThirdLevelFirstActiveButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '33%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleThirdLevelFirstInActiveButton: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '33%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleThirdLevelSecondActiveButton: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '33%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  toggleThirdLevelSecondInActiveButton: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: '1px solid #145DA0',
    borderLeft: '0px',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '33%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleSecondInActiveButton: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '50%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleSecondInActiveTabletButton: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: '1px solid #145DA0',
    backgroundColor: '#FFFFFF',
    color: '#145DA0',
    width: '130px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  toggleFirstActiveButtonMobile: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: 0,
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    boxShadow: 'none',
    padding: '6px',
    width: '50%',
  },
  toggleFirstInActiveButtonMobile: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: 0,
    border: 0,
    backgroundColor: '#C9C9C9',
    color: '#959595',
    boxShadow: 'none',
    padding: '6px',
    width: '50%',
  },
  toggleSecondActiveButtonMobile: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: 0,
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    boxShadow: 'none',
    padding: '6px',
    width: '50%',
  },
  toggleSecondInActiveButtonMobile: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: 0,
    border: 0,
    backgroundColor: '#C9C9C9',
    color: '#959595',
    boxShadow: 'none',
    padding: '6px',
    width: '50%',
  },
  filterMenu: {
    display: 'flex',
    padding: '15px',
    paddingTop: '0px',
    paddingRight: '5px',
    paddingBottom: '0 !important',
  },
  menuRootClass: {
    border: '1px solid #8080802b',
  },
  sortingArrows: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    paddingLeft: '5px',
  },
  IconImageContainer: {
    // marginRight: '-20px',
  },
  cardListViewImageIcon: {
    width: '30px',
  },
  filterIconContainer: {
    padding: '0px',
  },
  FilterIconImageContainer: {
    // marginRight: '-17px',
  },
  cardSortIconImageContainer: {
    // marginRight: '-27px',
  },
  filterIcon: {
    width: '30px',
    cursor: 'pointer',
  },
  filterContainer: {
    marginTop: '0px',
  },
  totalCardTypographySub: {
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  tableFooterCell: {
    padding: '0px 0px 0px 0px',
    borderBottom: 'none',
    fontSize: '14px',
    fontWeight: 500,
    color: '#253858',
  },
  redlabelClass: {
    fontSize: '10px',
    padding: '4px 8px',
    textAlign: 'center',
    margin: '0px 8px 4px 0px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    backgroundColor: '#BB0909',
  },
  redlabelEditInvoiceClass: {
    fontSize: '10px',
    padding: '4px 8px',
    textAlign: 'center',
    margin: '0px 8px 4px 0px',
    marginLeft: '8px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    backgroundColor: '#BB0909',
  },
  greenlabelClass: {
    fontSize: '10px',
    padding: '4px 8px',
    textAlign: 'center',
    margin: '0px 8px 4px 0px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    backgroundColor: '#16A085',
  },
  greenlabelInvoiceEditClass: {
    fontSize: '10px',
    padding: '4px 8px',
    textAlign: 'center',
    margin: '0px 8px 4px 0px',
    marginLeft: '8px',
    borderRadius: '8px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    backgroundColor: '#16A085',
  },
  orangelabelClass: {
    fontSize: '13px',
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    textAlign: 'center',
    backgroundColor: '#ff9800',
    margin: '0px 4px 4px 0px',
  },
  draftLabelClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    textAlign: 'center',
    backgroundColor: '#989898',
    margin: '0px 8px 4px 0px',
    padding: '4px 8px',
  },
  mobileModalClass: {
    top: '0%',
    left: '50%',
    borderRadius: '0px',
    height: '100%',
    zIndex: 2000,
    width: '100%',
    textAlign: 'center',
  },
  totalCardContainer: {
    '&:last-child': {
      paddingBottom: '5px !important',
      // padding: '5px 20px',
    },
    padding: '5px 0px 8px 8px',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  crustStatusCardContainer: {
    '&:last-child': {
      paddingBottom: '5px !important',
    },
    padding: '10px 10px 8px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
  },
  totalCard: {
    // background: '#EDF2F8',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    borderRadius: '8px',
    height: '100%',
    padding: '8px 2px',
    alignItems: 'center',
  },
  totalCard8BorderRadius: {
    background: '#EDF2F8',
    // boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05) !important',
    borderRadius: '8px',
    height: '100%',
    alignItems: 'center',
  },
  totalGreenCard: {
    background: '#16A085',
    color: '#FFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    borderRadius: '8px',
    height: '100%',
    padding: '8px 2px',
    alignItems: 'center',
  },
  totalRedCard: {
    background: '#BB0909',
    color: '#FFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    borderRadius: '8px',
    height: '100%',
    padding: '8px 2px',
    alignItems: 'center',
  },
  totalGreenCard8BorderRadius: {
    background: '#16A085',
    color: '#FFF',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05) !important',
    borderRadius: '8px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60px',
  },
  totalRedCard8BorderRadius: {
    background: '#BB0909',
    color: '#FFF',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05) !important',
    borderRadius: '8px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60px',
  },
  totalCardTypography: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  totalCardTypographyV2: {
    color: '#145DA0',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
  },
  totalCardTypographyWhiteV2: {
    color: '#fff',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
  },
  totalCartTypographyWhiteSubTitle: {
    color: '#fff',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
  },
  totalCartTypographySubTitle: {
    color: '#37474F',
    wordBreak: 'break-all',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
  },
  totalCartTypographySubTitleLink: {
    color: '#3072C6',
    fontWeight: 600,
    lineHeight: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    letterSpacing: '0.5px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
  },
  totalCardBoxContainer: {
    display: 'grid',
    gap: '4px',
  },
  crushStatusLabel: {
    fontWeight: 'bold',
    // fontSize: '15px',
    textAlign: 'center',
    color: '#fff',
    alignItems: 'center',
  },
  mobileCheckboxClass: {
    color: 'transparent',
    position: 'absolute',
    background: 'white',
    right: 5,
    top: 5,
    zIndex: 999,
    borderRadius: '5px',
    padding: '0px',
    margin: '0px',
    border: '2px solid #137cbd',
    '&:hover': {
      background: 'white',
    },
  },
  CheckboxClass: {
    color: 'transparent',
    position: 'absolute',
    background: 'white',
    right: 5,
    top: 5,
    zIndex: 999,
    borderRadius: '5px',
    padding: '0px',
    margin: '0px',
    '& .MuiButtonBase-root': {
      '&:hover': {
        background: 'white',
      },
    },
    '&:hover': {
      background: 'white',
    },
  },
  imageDeleteIconClass: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 999,
    borderRadius: '5px',
    padding: '0px',
    margin: '0px',
  },
  mobileTabletImageCardContent: {
    position: 'relative',
    height: '222px',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  imageListClass: {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill,minmax(110px, 0fr)) !important',
    gridTemplateRows: 'auto auto',
    gridAutoColumns: 'minmax(110px, 0fr)',
    margin: '0px',
    minHeight: '246px',
    gap: '8px !important',
    padding: '8px',
  },
  imageListTwoColumnClass: {
    gridAutoFlow: 'dense',
    gridTemplateRows: 'auto auto',
    margin: '0px',
    gap: '8px !important',
    padding: '8px',
  },
  imageListSingleRowClass: {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill,minmax(110px, 0fr)) !important',
    gridTemplateRows: 'auto',
    gridAutoColumns: 'minmax(110px, 0fr)',
    margin: '0px',
  },
  imageListCoreClass: {
    margin: '0px',
  },
  imageWebsiteListClass: {
    gridTemplateColumns: 'repeat(auto-fill,minmax(110px, 0fr)) !important',
    gridTemplateRows: 'auto',
    margin: '0px',
    minHeight: '246px',
  },
  imageListCartClass: {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill,minmax(132px, 0fr)) !important',
    gridTemplateRows: 'auto',
    gridAutoColumns: 'minmax(132px, 0fr)',
    margin: '0px',
  },
  imageListCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItem: 'center',
    '&:last-child': { paddingBottom: '16px' },
  },
  imageListItem: {
    minHeight: '109px',
    maxHeight: '109px',
    borderRadius: '8px',
  },
  imageCartListItem: {
    minHeight: '100px',
    maxHeight: '100px',
  },
  fileInput: {
    display: 'none',
  },
  blueToolBarButton: {
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
  },
  blueToolBarRowButton: {
    marginRight: '8px',
    marginTop: '4px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
  },
  mobileTotalCardContainer: {
    '&:last-child': {
      paddingBottom: '5px !important',
    },
    padding: '5px 0px 0px 2px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
  },
  mobileTotalCardTypography: {
    textTransform: 'uppercase',
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  imageContainer: {
    position: 'relative',
    marginTop: '16px',
  },
  vehiclePartToogleContainer: {
    marginTop: '3px',
    marginBottom: '0px',
  },
  card: {
    height: '100%',
  },
  cardV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
  },
  cardMoreShadowV2: {
    borderRadius: '8px',
    boxShadow: '1px 5px 8px rgb(0 0 0 / 25%) !important',
  },
  secondCardMoreShadowV2: {
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: '1px 5px 8px rgb(0 0 0 / 25%) !important',
  },
  card100V2: {
    height: '100%',
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
  },
  cardTableListV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 118px)',
  },
  totalSalesContentClass: {
    maxHeight: '655px !important',
    // overflowX: 'auto',
    fontWeight: '600',
    lineHeight: '18px',
    borderRadius: '0px',
    [themeStyle.breakpoints.down('900')]: {
      position: 'fixed',
    },
  },
  filterColumnCardContainer: {
    height: '100%',
    borderRadius: '8px',
    border: '2px solid #145DA0',
    paddingBottom: '8px',
  },
  filterColumnMenuTitleClass: {
    color: '#145DA0',
    textAlign: 'center',
    paddingBottom: '16px',
    paddingTop: '16px',
    borderBottom: '1px solid #E6E8F0',
    whiteSpace: 'nowrap',
    fontSize: '20px !important',
    fontWeight: 700,
  },
  imageCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItem: 'center',
    '&:last-child': { paddingBottom: '8px' },
  },
  typographyClass: {
    marginTop: '8px',
    marginBottom: '8px',
    whiteSpace: 'nowrap',
  },
  totalTdContainer: {
    border: 'none',
    padding: '15px 10px 15px 10px',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  blueToolBarCartButton: {
    backgroundColor: '#fff',
    color: '#145DA0',
    border: '1px solid #145DA0',
    borderRadius: '16px',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  cartButtonLabel: {
    position: 'absolute',
    top: '-30%',
    backgroundColor: '#fff',
    color: '#145DA0',
  },
  cartButtonTableBox: {
    marginTop: '-8px',
    overflow: 'hidden',
  },
  cartTableHeader: {
    backgroundColor: '#145da0',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '17px',
  },
  iconButtonClass: {
    '&:hover': { background: 'none' },
  },
  cartCancelButton: {
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    width: '70px',
    '&:hover': { backgroundColor: '#910101' },
  },
  cartBlueButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    width: '70px',
  },
  cartCardViewBlueButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
  },
  cartCardViewRedButton: {
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    '&:hover': { backgroundColor: '#910101' },
  },
  totalPriceTxt: {
    fontWeight: 'bold',
  },
  fieldSelect: {
    padding: '1px 0px',
    minWidth: 100,
  },
  fieldSelectDashboard: {
    textAlign: 'center',
    padding: '1px 0px',
    minWidth: 120,
    '& .MuiSelect-outlined': {
      padding: '5.5px 14px',
      color: '#145da0',
    },
    '& .MuiInputBase-root': {
      border: '1px solid #145da0',
    },
  },
  typographyClassDashbaord: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  typographyHeaderClass: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    fontSize: '20px',
  },
  typographyChartHeaderClass: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  fieldFormControl: {
    width: '170px',
  },
  fieldFormControlItem: {
    width: '115px',
  },
  fieldFormControlTax: {
    width: '100px',
  },
  divider: {
    alignItems: 'right',
    borderColor: '#0C2D48',
  },
  totalTdTootContainer: {
    padding: '10px',
    border: 'none',
  },
  totalTdTootalContainer: {
    padding: '5px',
    border: 'none',
  },
  totalFooterTdTootContainer: {
    padding: '5px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  totalFooterTdContainer: {
    border: 'none',
    padding: '5px',
    paddingRight: '0px',
  },
  Link: {
    marginLeft: '16px',
    marginTop: '9px',
    alignItems: 'center',
  },
  cardListViewActionContainer: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  loadingScreenComponent: {
    backgroundColor: '#ffffff',
    minHeight: '100%',
  },
  navItemComponent: {
    display: 'block',
    paddingTop: '0px',
    paddingBottom: '0px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  listItemButton: {
    color: '#fff',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    paddingBottom: '3px',
    fontSize: '13px',
    textAlign: 'center',
    textTransform: 'none',
    width: '100%',
    lineHeight: '12px',
  },
  listDisabledItemButton: {
    color: '#fff !important',
    fontWeight: 100,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    paddingBottom: '3px',
    fontSize: '13px',
    textAlign: 'center',
    textTransform: 'none',
    width: '100%',
    lineHeight: '12px',
  },
  listActiveItemButton: {
    color: '#145DA0',
    backgroundColor: '#fff',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    justifyContent: 'center',
    paddingBottom: '5px',
    fontSize: '12px',
    textAlign: 'center',
    textTransform: 'none',
    width: '100%',
    '&:svg': {
      color: '#145DA0',
      '&:svg': {
        color: '#145DA0',
      },
    },
    '&:hover': {
      color: '#145DA0',
      backgroundColor: '#fff',
      '&:svg': {
        color: '#145DA0',
      },
    },
    lineHeight: '12px',
  },
  headerUsertypography: {
    fontSize: '11px',
    color: '#fff',
    fontWeight: 'fontWeightMedium',
    whiteSpace: 'nowrap',
    lineHeight: '12px',
    margin: 'auto',
  },
  userLogoSection: {
    paddingTop: '8px',
    paddingBottom: '8px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  flexGrow: {
    flexGrow: '8px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  listSubHeaderClass: {
    color: '#172b4d',
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  overFlowXClass: {
    overflowX: 'auto',
  },
  slashScreenClass: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    p: 3,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  stepperClasses: {
    '& .MuiStepIcon-active': { color: 'red' },
    '& .MuiStepIcon-completed': { color: '#145DA0' },
    '& .Mui-active .MuiStepIcon-root': { fontWeight: 'bold' },
    '& .MuiStepIcon-text': { color: 'black', fontWeight: 'bold' },
    '& .Mui-active': { color: '#145DA0', fontWeight: 'bold' },
    '& .Mui-disabled': { color: '#989898', fontWeight: 'bold' },
    '& .MuiStepConnector-line': { borderTopWidth: '1.5px', borderColor: '#989898' },
    '& .css-1j8x6j7-MuiStepLabel-labelContainer': { textAlign: 'center' },
    '& .Mui-active .css-1j8x6j7-MuiStepLabel-labelContainer': { color: '#145DA0', textAlign: 'center' },
  },
  deskTopLogoClass: {
    width: 40,
    height: 40,
    display: 'flex',
  },
  mobileLogoClass: {
    marginTop: '5px',
    marginLeft: '50%',
    width: '40px',
  },
  tabletLogoClass: {
    marginTop: '5px',
    width: '40px',
  },
  formBottomContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px',
  },
  otpContainer: {
    columnGap: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    paddingTop: '8px,',
    paddingBottom: '8px,',
  },
  otpInputBase: {
    display: 'inline-block',
    textAlign: 'center',
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  mxClass: {
    marginLeft: '14px',
    marginRight: '14px',
  },
  cartMenuClass: {
    width: '500px',
    maxHeight: '500px',
    overflowY: 'auto',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '5px',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: '-4px',
      marginRight: '8px',
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      backgroundColor: '#ffffff',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  tableRowLastCell: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  variableColumnClasses: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '5px',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: '-4px',
      marginRight: '8px',
    },
  },
  printPageContainer: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '20px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  printBox: {
    width: '300px',
    height: '204px',
  },
  printBoxContainer: {
    border: '2px solid black',
    width: '300px',
    height: '204px',
  },
  printCodeTableCell: {
    borderBottom: 'none',
    borderRight: 'none',
    verticalAlign: 'middle',
    paddingLeft: '2px',
    paddingRight: '10px',
  },
  printCodePartTableCell: {
    marginBottom: '0px',
    paddingBottom: '0px',
    paddingLeft: '2px',
  },
  printCodePartTableSubCell: {
    borderBottom: 'none',
    borderRight: 'none',
    paddingBottom: '0px',
    paddingLeft: '2px',
    paddingRight: '10px',
    paddingTop: '10px',
    verticalAlign: 'sub',
  },
  printCodeVehicleTableSubCell: {
    borderBottom: 'none',
    borderRight: 'none',
    paddingBottom: '0px',
    paddingLeft: '2px',
    paddingRight: '5px',
    paddingTop: '0px',
    verticalAlign: 'sub',
  },
  printCodeVehicleVinTypoClass: {
    fontSize: '12px',
    marginBottom: '-0px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    color: 'black',
  },
  printCodeVehicleTitleValueTypoClass: {
    fontSize: '13px',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    marginTop: '0px',
    lineHeight: 1.3,
    color: 'black',
    wordBreak: 'break-all',
  },
  printCodeVehicleTitleValueThirdTypoClass: {
    fontSize: '17px',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    marginTop: '8px',
    marginBottom: '8px',
    paddingBottom: '0px',
    lineHeight: 1.3,
    color: 'black',
    wordBreak: 'break-all',
  },
  printQrCodePartTitleOneTypo: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    marginTop: '0px',
    marginBottom: '8px',
    paddingBottom: '0px',
    lineHeight: 1.3,
    color: 'black',
  },
  printQrCodePartBottomSection: {
    textAlign: 'center',
    border: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '2px',
    color: 'black',
  },
  printQrCodeBottomTenantImageCell: {
    textAlign: 'center',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    margin: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  printCodeCellTagPartClass: {
    textAlign: 'left',
    mb: -0,
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  printCodeTableCellTypography: {
    fontWeight: 'bold',
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    lineHeight: 1.3,
    color: 'black',
    wordBreak: 'break-all',
  },
  printQRCodeSection: {
    borderBottom: 'none',
    borderLeft: 'none',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '10px',
    paddingTop: '10px',
    verticalAlign: 'top',
  },
  printCodeBottomImageCell: {
    marginTop: '10px',
    paddingTop: '0px',
    borderTop: 'none',
  },
  printCodeBottomIDRow: {
    marginTop: '0px',
    paddingTop: '0px',
    borderTop: 'none',
  },
  printCodeBottomIDCell: {
    textAlign: 'center',
    border: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '2px',
    paddingRight: '30px',
    color: 'black',
  },
  printTagVehicleStockCellClass: {
    textAlign: 'center',
    border: 'none',
    paddingTop: '0px',
    paddingBottom: '10px',
    paddingLeft: '2px',
    color: 'black',
  },
  printTagVehicleStockCellRightSideClass: {
    textAlign: 'left',
    marginBottom: '-0px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    wordBreak: 'break-all',
  },
  printCodeBottomCellTypography: {
    fontSize: '12px',
    marginBottom: -0,
    fontWeight: 'bold',
    lineHeight: 1.3,
    color: 'black',
  },
  printCodeBottomImageSection: {
    textAlign: 'center',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    margin: '0px',
    paddingTop: '0px',
    paddingBottom: '5px',
    paddingLeft: '0px',
    paddingRight: '5px',
    verticalAlign: 'bottom',
  },
  positionRelativeClass: {
    position: 'relative',
  },
  sliderCard: {
    marginLeft: '8px',
    marginRight: '8px',
    marginTop: '16px',
  },
  carouselImagePaper: {
    borderRadius: '0px',
    height: '145px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: '0px',
    padding: '0px',
    backgroundRepeat: 'no-repeat',
  },
  mobileCarouselImagePaper: {
    height: '100px',
    borderRadius: '0px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: '0px',
    padding: '0px',
    backgroundRepeat: 'no-repeat',
  },
  cardViewTypography: {
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  cardViewTypographyIndex: {
    fontSize: '13px',
    fontWeight: 'bold',
  },
  tabletCardContainer: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  cardViewTypographyValue: {
    fontSize: '13px',
  },
  tableListIconButton: {
    paddingRight: '0px',
  },
  tableViewModeIconButton: {
    margin: '0px',
    padding: '0px',
  },
  mobileToggleTitle: {
    marginBottom: '8px',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  mobileImageCard: {
    position: 'relative',
    height: '222px',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  pxClass: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  mobileCardListLabel: {
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    msTouchSelect: 1,
  },
  fontWeightBoldClass: {
    fontWeight: 'bold',
  },
  groupTableBox: {
    padding: '16px',
    border: '1px solid #e0e0e0',
  },
  paddingOneSpc: {
    padding: '1px',
  },
  sideBarBottomSection: {
    padding: '1px',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  mobileUserNameSection: {
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'center',
    padding: '15px',
  },
  whiteButton: {
    backgroundColor: 'white',
    color: '#0B79D0',
    border: '1px solid #0B79D0',
  },
  navBarSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  lgDrawerClasses: {
    backgroundColor: '#145DA0',
    height: 'calc(100% - 0px) !important',
    top: '0px !Important',
    width: 85,
  },
  smDrawerClasses: {
    backgroundColor: '#145DA0',
    width: '76px',
  },
  sideBarToolBarClass: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  tableActionCell: {
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tableButtonActionCell: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '5px',
  },
  filterCardComponent: {
    position: 'relative',
    overflow: 'auto',
    borderRadius: '0px',
    width: '16%',
    flex: '1 1 auto',
    padding: '0px 12px',
    '& .MuiCardHeader-title': {
      fontWeight: '700 !important',
      fontSize: '40px !important',
      color: '#145DA0 !important',
      lineHeight: '40px !important',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      paddingLeft: '0px !important',
    },
  },
  filterBigTitleCardComponent: {
    position: 'relative',
    overflow: 'auto',
    borderRadius: '0px',
    width: '280px',
    flex: '1 1 auto',
    padding: '0px 12px',
    '& .MuiCardHeader-title': {
      fontWeight: '700 !important',
      [themeStyle.breakpoints.up('1900')]: {
        fontSize: '40px',
      },
      [themeStyle.breakpoints.down('1900')]: {
        fontSize: '38px',
      },
      [themeStyle.breakpoints.down('1450')]: {
        fontSize: '35px',
      },
      color: '#145DA0 !important',
      lineHeight: '40px !important',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      paddingLeft: '0px !important',
    },
  },
  filterSection: {
    marginLeft: '-20px',
    marginTop: '-30px',
  },
  cardHeaderTitle: {
    paddingBottom: '0px',
    paddingLeft: '8px !important',
  },
  cardTitleClass: {
    color: '#145DA0',
    fontWeight: '700',
    textAlign: 'left',
    borderBottom: '1px solid #E6E8F0',
    whiteSpace: 'nowrap',
    lineHeight: '137.5%',
    '& .MuiCardHeader-title': {
      fontWeight: '700 !important',
      [themeStyle.breakpoints.up('1900')]: {
        fontSize: '20px',
      },
      [themeStyle.breakpoints.down('1900')]: {
        fontSize: '16px',
      },
      [themeStyle.breakpoints.down('1450')]: {
        fontSize: '14px',
      },
    },
    padding: '10px',
  },
  cardTitleZeroBorderClass: {
    color: '#145DA0',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '137.5%',
    fontWeight: '700 !important',
    padding: '2px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '16px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '14px',
    },
  },
  cardTitleZeroBorderClassv2: {
    color: '#145DA0',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '137.5%',
    fontWeight: '700 !important',
    padding: '5px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '16px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '14px',
    },
  },
  cardListTitleClass: {
    color: '#145DA0',
    fontWeight: '700',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '137.5%',
    padding: '7px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '16px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '14px',
    },
  },
  cardTitleWithoutBorderClass: {
    color: '#145DA0',
    textAlign: 'center',
    paddingBottom: '16px',
    paddingTop: '8px',
    whiteSpace: 'nowrap',
    fontSize: '20px !important',
  },
  filterWrapperSection: {
    paddingLeft: '0px',
    paddingRight: '16px',
    maxWidth: 'unset',
  },
  cardListLabel: {
    width: '100%',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    padding: '4px 8px',
    textAlign: 'center',
    backgroundColor: '#E6E8F0',
    margin: '0px 8px 4px 0px',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#37474F',
  },
  redCardListLabel: {
    width: '100%',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    padding: '4px 8px',
    textAlign: 'center',
    backgroundColor: '#C44D56',
    margin: '0px 8px 4px 0px',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#fff',
  },
  greenCardListLabel: {
    width: '100%',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    padding: '4px 8px',
    textAlign: 'center',
    backgroundColor: '#16A085',
    margin: '0px 8px 4px 0px',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#fff',
  },
  filterElementV2: {
    marginTop: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& .MuiOutlinedInput-input': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '14px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#37474F',
      },
      '& .MuiInputBase-inputMultiline': {
        padding: '8px 10px',
      },
      '& legend': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '11px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        height: '3px',
      },
      '& fieldset': {
        border: '1.2px solid #37474F !important',
      },
      '&.Mui-focused fieldset': {
        border: '2.4px solid #145DA0 !important',
      },
    },
    '& .MuiInputLabel-root': {
      [themeStyle.breakpoints.up('1900')]: {
        fontSize: '16px',
      },
      [themeStyle.breakpoints.down('1900')]: {
        fontSize: '14px',
      },
      [themeStyle.breakpoints.down('1450')]: {
        fontSize: '12px',
      },
      color: '#37474F !important',
    },
    '& .MuiIconButton-root': {
      color: '#37474F',
      '& .MuiSvgIcon-root': {
        width: '17px',
      },
    },
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  filterElementErrorV2: {
    marginTop: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& .MuiOutlinedInput-input': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '14px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#37474F',
      },
      '& .MuiInputBase-inputMultiline': {
        padding: '8px 10px',
      },
      '& legend': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '11px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        height: '3px',
      },
      '& fieldset': {
        border: '1.2px solid #C44D56 !important',
      },
      '&.Mui-focused fieldset': {
        border: '2.4px solid #C44D56 !important',
      },
    },
    '& .MuiInputLabel-root': {
      [themeStyle.breakpoints.up('1900')]: {
        fontSize: '16px',
      },
      [themeStyle.breakpoints.down('1900')]: {
        fontSize: '14px',
      },
      [themeStyle.breakpoints.down('1450')]: {
        fontSize: '12px',
      },
      color: '#37474F !important',
    },
    '& .MuiIconButton-root': {
      color: '#37474F',
      '& .MuiSvgIcon-root': {
        width: '17px',
      },
    },
  },
  filterElementTextAreaV2: {
    marginTop: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& .MuiOutlinedInput-input': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '14px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#37474F',
      },
      '& .MuiInputBase-inputMultiline': {
        padding: '8px 10px',
        height: '19px !important',
      },
      '& legend': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '11px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        height: '3px',
      },
      '& fieldset': {
        border: '1.2px solid #37474F !important',
      },
      '&.Mui-focused fieldset': {
        border: '2.4px solid #145DA0 !important',
      },
    },
    '& .MuiInputLabel-root': {
      [themeStyle.breakpoints.up('1900')]: {
        fontSize: '16px',
      },
      [themeStyle.breakpoints.down('1900')]: {
        fontSize: '14px',
      },
      [themeStyle.breakpoints.down('1450')]: {
        fontSize: '12px',
      },
      color: '#37474F !important',
    },
    '& .MuiIconButton-root': {
      color: '#37474F',
      '& .MuiSvgIcon-root': {
        width: '17px',
      },
    },
  },
  filterElementTextArea: {
    marginTop: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& .MuiOutlinedInput-input': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '14px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#145DA0',
      },
      '& .MuiInputBase-inputMultiline': {
        padding: '8px 10px',
        height: '19px !important',
      },
      '& legend': {
        [themeStyle.breakpoints.up('1900')]: {
          fontSize: '12px',
        },
        [themeStyle.breakpoints.down('1900')]: {
          fontSize: '11px',
        },
        [themeStyle.breakpoints.down('1450')]: {
          fontSize: '10px',
        },
        height: '3px',
      },
    },
    '& .MuiInputLabel-root': {
      [themeStyle.breakpoints.up('1900')]: {
        fontSize: '16px',
      },
      [themeStyle.breakpoints.down('1900')]: {
        fontSize: '14px',
      },
      [themeStyle.breakpoints.down('1450')]: {
        fontSize: '12px',
      },
      color: '#145DA0 !important',
    },
    '& .MuiIconButton-root': {
      color: '#145DA0',
      '& .MuiSvgIcon-root': {
        width: '17px',
      },
    },
  },
  filterElementReadOnlyV2: {
    marginTop: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& .MuiOutlinedInput-input': {
        fontSize: '14px !important',
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#909090',
      },
      '& .MuiInputBase-inputMultiline': {
        padding: '8px 10px',
      },
      '& legend': {
        fontSize: '12px',
        height: '3px',
      },
      '& fieldset': {
        border: '1.2px solid #909090 !important',
      },
      '&.Mui-focused fieldset': {
        border: '1.2px solid #909090 !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      color: '#909090 !important',
    },
    '& .MuiIconButton-root': {
      color: '#909090',
      '& .MuiSvgIcon-root': {
        width: '17px',
      },
    },
  },
  filterElement: {
    marginTop: '5px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& legend': {
        fontSize: '12px',
        height: '3px',
      },
      '& .MuiInputBase-inputMultiline': {
        padding: '8px 10px !important',
      },
      // '& .MuiOutlinedInput-input': {
      //   padding: '8px 10px !important',
      // },
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      color: '#145DA0 !important',
    },
    '& .MuiIconButton-root': {
      color: '#145DA0',
      '& .MuiSvgIcon-root': {
        width: '17px',
      },
    },
  },
  filterDisabledElement: {
    marginTop: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      color: '#989898 !important',
      borderColor: '#989898 !important',
      '& legend': {
        fontSize: '12px',
        height: '3px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#989898 !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      color: '#989898 !important',
    },
  },
  padding8Class: {
    padding: '8px',
  },
  CarouselNavButtonWrapper: {
    height: '100%',
    '&:hover': {
      height: '100%',
      backgroundColor: 'black',
      filter: 'brightness(120%)',
      opacity: 0.4,
    },
  },
  navButtonsProps: {
    height: '100%',
    borderRadius: '0%',
    top: '0px !important',
    padding: '0px',
    margin: '0px',
    width: '40px',
  },
  cardBoxToolBox: {
    maxWidth: '12.5%',
    flexBasis: '12.5%',
  },
  cardBoxPartToolBox: {
    maxWidth: '21%',
    flexBasis: '21%',
    whiteSpace: 'nowrap',
  },
  cardBoxPartSmallToolBox: {
    maxWidth: '15%',
    flexBasis: '15%',
    whiteSpace: 'nowrap',
  },
  marginL8: {
    marginLeft: '8px',
  },
  errorClass: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: '#C44D56',
  },
  sameAsClass: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  marginL24: {
    marginLeft: '24px',
  },
  collapseTitle: {
    cursor: 'pointer',
  },
  centerSectionPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lockedPageImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lockedPageContainer: {
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center',
    // marginLeft: '10%',
  },
  lockedTextClass: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '39px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#000',
  },
  lockedSpanGold: {
    color: '#B08D57',
    fontWeight: 'bold',
  },
  lockedSpanSilver: {
    color: '#C0C0C0',
    fontWeight: 'bold',
  },
  subRowsRootCell: {
    padding: 0,
    borderBottom: '0px',
  },
  typographyCardClass: {
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 600,
  },
  chartTabletypographyClass: {
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
  },
  cursorPointor: {
    cursor: 'pointer',
  },
  searchTabClass: {
    width: '80%',
  },
  filterButtonActions: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '0px 20px !important',
    borderTop: '1px solid #E6E8F0',
    paddingTop: '10px !important',
  },
  tabClass: {
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: '36px',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      minWidth: '50%',
      width: '50%',
      whiteSpace: 'nowrap',
      minHeight: '36px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
      color: '#145DA0',
      borderBottom: 'none !important',
    },
    '& .MuiTabs-root': {
      minHeight: '36px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      background: '#145DA0',
      color: '#fff',
    },
    '& .MuiButtonBase-root': {
      borderLeft: '2px solid #145DA0',
      borderBottom: 'none !important',
      padding: '0px 10px !important',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
  },
  tabZeroBorderClass: {
    minHeight: '43px',
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    width: '100%',
    overflow: 'unset !important',
    '& .MuiTab-root': {
      minWidth: '50%',
      width: '50%',
      whiteSpace: 'nowrap',
      minHeight: '43px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
      overflow: 'unset !important',
    },
    '& .MuiTabs-root': {
      minHeight: '43px',
      overflow: 'unset !important',
    },
    '& .MuiTabs-scroller': {
      overflow: 'unset !important',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
    },
    '& .MuiButtonBase-root': {
      borderBottom: 'none !important',
    },
  },
  tab3Class: {
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: '43px',
    '& .MuiTab-root': {
      minWidth: '33.33%',
      width: '33.33%',
      whiteSpace: 'nowrap',
      minHeight: '43px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
    },
    '& .MuiTabs-root': {
      minHeight: '43px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
    },
    '& .MuiButtonBase-root': {
      borderBottom: '1px solid #787878',
    },
  },
  tab5Class: {
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: '43px',
    '& .MuiTab-root': {
      minWidth: '20%',
      width: '20%',
      whiteSpace: 'nowrap',
      minHeight: '43px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
    },
    '& .MuiTabs-root': {
      minHeight: '43px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
    },
    '& .MuiButtonBase-root': {
      borderBottom: '1px solid #787878',
    },
  },
  tab4Class: {
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: '43px',
    '& .MuiTab-root': {
      minWidth: '25%',
      width: '25%',
      whiteSpace: 'nowrap',
      minHeight: '43px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
    },
    '& .MuiTabs-root': {
      minHeight: '43px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
    },
    '& .MuiButtonBase-root': {
      borderBottom: '1px solid #787878',
    },
  },
  tab3ZeroBorderClass: {
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: '36px',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      minWidth: '33.34%',
      width: '33.34%',
      whiteSpace: 'nowrap',
      minHeight: '36px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
      color: '#145DA0',
      borderBottom: 'none !important',
    },
    '& .MuiTabs-root': {
      minHeight: '36px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      background: '#145DA0',
      color: '#fff',
    },
    '& .MuiButtonBase-root': {
      borderLeft: '2px solid #145DA0',
      borderBottom: 'none !important',
      padding: '0px 10px !important',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
  },
  tab3ZeroBorderFilterClass: {
    // boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: '36px',
    border: '1px solid #79747E',
    borderRadius: '35px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      minWidth: '33.34%',
      width: '33.34%',
      whiteSpace: 'nowrap',
      minHeight: '36px',
      textTransform: 'capitalize',
      fontSize: '14px',
      weight: 500,
      lignHeight: '24px',
      color: '#1C1B1F',
      borderBottom: 'none !important',
    },
    '& .MuiTabs-root': {
      minHeight: '36px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      background: '#CCDCEB',
      color: '#1C1B1F',
    },
    '& .MuiButtonBase-root': {
      borderLeft: '1px solid #79747E',
      borderBottom: 'none !important',
      padding: '0px 10px !important',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
  },
  tabAutoWidthClass: {
    // width: '320px',
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: 'auto',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      whiteSpace: 'nowrap',
      minHeight: '30px',
      textTransform: 'capitalize',
      weight: 500,
      lignHeight: '24px',
      color: '#145DA0',
      borderBottom: 'none !important',
    },
    '& .MuiTabs-root': {
      minHeight: '36px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      background: '#145DA0',
      color: '#fff',
    },
    '& .MuiButtonBase-root': {
      borderLeft: '2px solid #145DA0',
      borderBottom: 'none !important',
      padding: '0px 10px !important',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    '& .MuiTabScrollButton-root': {
      '&:first-child': {
        borderRight: '2px solid #145DA0',
        opacity: '1 !important',
      },
      '&:last-child': {
        borderLeft: '2px solid #145DA0',
        opacity: '1 !important',
      },
    },
  },
  marginBottom16: {
    marginBottom: '16px',
  },
  marginBottom8: {
    marginBottom: '8px',
  },
  marginTopAddPartImageLabel: {
    marginTop: -2,
  },
  htmlDescBoxClass: {
    color: '#172b4d',
    fontSize: '1rem',
    lineHeight: '1.4375em',
    fontWeight: '400',
    width: '100%',
    padding: '5px',
  },
  width100: {
    width: '100% !important',
  },
  width82: {
    width: '82% !important',
  },
  filterAutoComplete: {
    '& .MuiChip-label': {
      textOverflow: 'unset',
      whiteSpace: 'pre-wrap',
    },
    '& .MuiChip-root': {
      height: '100%',
      padding: '5px 0px 4px 0px',
    },
  },
  menuButton: {
    marginLeft: '-16px',
  },
  image100Width: {
    width: '100%',
  },
  image50Width: {
    width: '50%',
  },
  quillEditorRoot: {
    border: '1px solid #145DA0',
    borderRadius: '16px',
    padding: '16.5px 14px',
    fontSize: '1rem',
    lineHeight: '1.4375em',
    fontWeight: '400',
    color: '#172b4d',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    '& .ql-snow.ql-toolbar': {
      display: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderBottom: 'none',
      '& .ql-picker-label:hover': {
        color: '#42526e',
      },
      '& .ql-picker-label.ql-active': {
        color: '#42526e',
      },
      '& .ql-picker-item:hover': {
        color: '#42526e',
      },
      '& .ql-picker-item.ql-selected': {
        color: '#42526e',
      },
      '& button:hover': {
        color: '#42526e',
        '& .ql-stroke': {
          stroke: '#42526e',
        },
      },
      '& button:focus': {
        color: '#42526e',
        '& .ql-stroke': {
          stroke: '#42526e',
        },
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: '#42526e',
        },
      },
      '& .ql-stroke': {
        stroke: '#42526e',
      },
      '& .ql-picker': {
        color: '#42526e',
      },
      '& .ql-picker-options': {
        backgroundColor: '#42526e',
        border: 'none',
      },
    },
    '& .ql-snow.ql-container': {
      borderBottom: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      flexGrow: 1,
      '& .ql-tooltip': {
        display: 'none',
      },
      '& .ql-clipboard': {
        display: 'none',
      },
      '& .ql-editor': {
        '&:focus-visible': {
          outline: 'none',
        },
        color: '#42526e',
        '&.ql-blank::before': {
          fontStyle: 'normal',
        },
      },
    },
  },
  margin8: {
    margin: '8px',
  },
  tableHeadLearnCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 0px 3px 0px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    fontSize: '16px',
  },
  tableLearnCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 0px 3px 0px',
    fontSize: '14px',
  },
  subTableLearnCell: {
    padding: '0px',
  },
  learnSliderSection: {
    width: '60%',
    margin: 'auto',
    height: '100vh',
  },
  learnImage: {
    height: '90vh',
  },
  linearStepperClass: {
    background: 'unset',
    marginTop: '8px',
    width: '100%',
    padding: '0',
    '& .MuiMobileStepper-progress': {
      height: '10px',
      width: '100%',
      backgroundColor: 'rgb(152 152 152)',
    },
  },
  linearTradeMeLabelClass: {
    width: '14.2%',
  },
  linearStepperActiveLabel: {
    fontWeight: '700',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '18px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '15px',
    },
    lignHeight: '137.5%',
    fontFamily: 'Inter',
    color: '#145DA0',
    cursor: 'pointer',
  },
  linearStepperInActiveLabel: {
    fontWeight: '700',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '20px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '18px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '15px',
    },
    // fontSize: '19px',
    lignHeight: '137.5%',
    fontFamily: 'Inter',
    color: '#989898',
  },
  typographyCommonClass: {
    fontWeight: '700',
    fontSize: '16px',
    paddingLeft: '5px',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
    color: '#145DA0',
  },
  typographyErrorCommonClass: {
    fontWeight: '700',
    fontSize: '16px',
    paddingLeft: '5px',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
    color: '#BB0909',
  },
  typographyDisabledCommonClass: {
    fontWeight: '700',
    fontSize: '16px',
    paddingLeft: '5px',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
    color: '#989898',
  },
  smallSectionWithScrollTradeMe: {
    height: 'calc(100vh - 250px)',
    padding: '0px 8px 0px 0px',
    paddingTop: '0px',
    overflowX: 'auto',
  },
  typographyLabelCommonClass: {
    fontWeight: '700',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
    color: '#145DA0',
    display: 'flex',
  },
  typographyCommonBlackClass: {
    fontWeight: '400',
    fontSize: '16px',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
  },
  typographyCheckBoxLabelCommonClass: {
    fontWeight: '700',
    fontSize: '14px',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
    color: '#111827',
  },
  textDanger: {
    color: '#BB0909',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
  },
  helperText: {
    color: '#145DA0',
    fontWeight: '700',
    fontSize: '13px',
    lignHeight: '12px',
    fontFamily: 'Inter',
    letterSpacing: '0.15px',
  },
  blueSquareButton: {
    marginTop: '8px',
    borderRadius: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    minHeight: '42px',
    // whiteSpace: 'nowrap',
  },
  blueSquareMTZButton: {
    marginTop: '0px',
    borderRadius: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    minHeight: '42px',
    // whiteSpace: 'nowrap',
  },
  blueSquareOutlineButton: {
    marginTop: '8px',
    color: '#145DA0',
    minHeight: '42px',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    '&:hover': {
      color: '#145DA0',
      border: '2px solid #145DA0',
    },
  },
  blueSquareOutlineMTZButton: {
    marginTop: '0px',
    color: '#145DA0',
    minHeight: '42px',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    '&:hover': {
      color: '#145DA0',
      border: '2px solid #145DA0',
    },
  },
  blueSquareZeroPaddingButton: {
    marginTop: '8px',
    borderRadius: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    padding: '0px',
    minHeight: '42px',
    // whiteSpace: 'nowrap',
  },
  linearStepperTitleClass: {
    fontWeight: '700',
    fontFamily: 'Inter',
    fontSize: '20px',
    color: '#145DA0',
    lignHeight: '27.5px',
    '& .MuiCardHeader-title': {
      fontSize: '20px !important',
      fontWeight: '700 !important',
    },
  },
  CardTitleClassLeftAlign: {
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontSize: '20px',
    color: '#145DA0',
    lignHeight: '27.5px',
  },
  pT0: {
    paddingTop: '0px !important',
  },
  typographyDetailsTableClass: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  typographyDetailsTitleTableClass: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '18px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '15px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '13px',
    },
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#145DA0',
  },
  typographyDetailsTitleTableWhiteClass: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '18px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '15px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '13px',
    },
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#fff',
  },
  typographyDetailsValueTableClass: {
    fontWeight: 600,
    whiteSpace: 'break-spaces',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '18px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '15px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '13px',
    },
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#37474F',
  },
  typographyDetailsValueTableCommentsClass: {
    fontWeight: 400,
    textAlign: 'justify',
    whiteSpace: 'break-spaces',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '18px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '15px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '13px',
    },
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#37474F',
  },
  typographyDetailsValueTableWhiteClass: {
    fontWeight: 600,
    whiteSpace: 'break-spaces',
    // fontSize: '18px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#fff',
  },
  filterBigTitleClass: {
    fontWeight: '700 !important',
    fontSize: '32px !important',
    color: '#111827 !important',
    lineHeight: '137.5% !important',
  },
  tableHoverClass: {
    '& .MuiTableRow-root.MuiTableRow-hover:hover, .MuiTableRow-root.MuiTableRow-hover:hover td': {
      backgroundColor: '#EDF2F8',
    },
  },
  blueActionSquarLastButton: {
    borderRadius: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
  },
  tableLastRowClass: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  borderRadius8: {
    borderRadius: '8px',
  },
  cardContentZeroMarginClass: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: '0px !important',
    },
  },
  tableValignTopCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
  },
  tableRedBgCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    background: '#C44D56',
  },
  tableGreenBgCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    background: '#16A085',
  },
  tableValignTopWrapCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    verticalAlign: 'top',
  },
  tableTitleCell: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    color: '#111827',
    verticalAlign: 'top',
  },
  tableHeadCellStickyClass: {
    padding: '15px 10px 15px 10px',
    wordBreak: 'break-word',
    fontSize: '16px',
    fontFamily: 'Inter',
    color: '#145DA0',
    verticalAlign: 'top',
    position: 'absolute',
    top: 'auto',
    left: 0,
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '250%',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
  },
  tableHeadPrimaryCellStickyClass: {
    fontWeight: 'bold',
    width: '10em',
    padding: '10px 10px 10px 10px',
    whiteSpace: 'nowrap',
    // fontSize: '17px',
    color: '#145DA0',
    '& .MuiTableSortLabel-root': {
      color: '#145DA0',
    },
    position: 'sticky',
    top: 'auto',
    right: 0,
    background: 'white',
    marginTop: '0px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  stickyTableCell: {
    position: 'sticky',
    top: 'auto',
    right: 0,
    marginTop: '0px',
    background: 'white',
    padding: '8px',
  },
  borderRadiusCardClass: {
    borderRadius: '8px',
    // marginTop: '16px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
  },
  borderDropdownClass: {
    // border: '1.2px solid #37474F !important',
    border: '2.4px solid #145DA0 !important',
    // borderTop: 'none',
    borderRadius: '8px',
    // marginTop: '-7px',
    marginBottom: '10px',
  },
  borderDropdownFormClass: {
    // border: '1.2px solid #37474F !important',
    border: '2.4px solid #145DA0 !important',
    // borderTop: 'none',
    borderRadius: '8px',
    marginTop: '5px',
    marginBottom: '10px',
  },
  tableSortLabelClass: {
    '& .MuiTableSortLabel-icon': {
      color: '#145DA0 !important',
      fontWeight: '700',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  lockedMenuIcon: {
    textAlign: 'end',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '0px',
  },
  mobileFilterSearchMenu: {
    overflowY: 'visible',
    // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
    mt: 0,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
  },
  tableStickContainer: {
    // marginTop: '16px',
    background: 'white',
    position: 'relative',
  },
  rotateIcon: {
    animation: 'spin 2s linear infinite',
  },
  printLayout: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    padding: '20px',
    display: 'block',
    position: 'unset',
  },
  printTableRow: {
    borderCollapse: 'collapse',
  },
  printHeadPageTableCell1: {
    borderCollapse: 'collapse',
    borderBottom: '1px solid black',
    paddingBottom: '0px',
    verticalAlign: 'bottom',
  },
  printHeadPageTableCell2: {
    borderCollapse: 'collapse',
    borderBottom: '1px solid black',
    paddingBottom: '0px',
    verticalAlign: 'sub',
  },
  printHeadPageTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  printTableSubRowCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    margin: '0px',
    paddingBottom: '0px',
  },
  printTableSubRowCellWithBottomBorder: {
    borderBottom: '1px solid black',
    verticalAlign: 'top',
    margin: '0px',
    paddingBottom: '0px',
  },
  printSubHeader: {
    fontWeight: 'bold',
    marginTop: '24px',
    marginBottom: '8px',
  },
  printBorderNone: {
    border: 'none',
  },
  printSubTableHeadCell: {
    padding: '0px',
    paddingBottom: '3px',
    margin: '0px',
    borderTop: 'none',
  },
  printSubTableBodyCell: {
    padding: '0px',
    margin: '0px',
    borderTop: 'none',
  },
  printSubTableCellHeadingText: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  printPx2Class: {
    paddingLeft: '16px',
    paddingRight: '16px',
    pageBreakInside: 'avoid',
  },
  datePickerClass: {
    '& .MuiIconButton-root': {
      color: '#145DA0',
    },
  },
  autoMarginClass: {
    // margin: 'auto 0 auto 0',
    // alignItems: 'center',
  },
  marginRightZero: {
    marginRight: '0px',
  },
  invoiceTitleClasss: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  borderBottomNone: {
    borderBottom: 'none',
  },
  blueColor: {
    color: '#2E8BC0',
  },
  redColor: {
    color: '#C44D56',
  },
  paddingZero: {
    padding: '0px',
  },
  marginTopZero: {
    marginTop: '0px',
  },
  tabsToolarClass: {
    margin: '16px 0px 5px 0px',
  },
  printInvoiceAddressCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    margin: '0px',
    paddingBottom: '0px',
  },
  searchFieldLabel: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    fontFamily: 'Inter !important',
    fontStyle: 'normal !important',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: '#37474F',
    marginBottom: '5px',
    marginLeft: '5px',
  },
  searchFieldErrorLabel: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    fontFamily: 'Inter !important',
    fontStyle: 'normal !important',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: '#C44D56',
    marginBottom: '5px',
    marginLeft: '5px',
  },
  searchFieldReadOnlyLabel: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    fontFamily: 'Inter !important',
    fontStyle: 'normal !important',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: '#909090',
    marginBottom: '5px',
    marginLeft: '5px',
  },
  tabIconLabelFilterWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginBottom: '0px !important',
      marginRight: '5px',
      width: '20px',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '10px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '9px',
    },
  },
  tabIconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 10px',
    '& .MuiSvgIcon-root': {
      marginBottom: '0px !important',
      marginRight: '5px',
      width: '20px',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  tabIconLabelMarginWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginBottom: '-5px !important',
      marginRight: '5px',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  tabLabelContainer: {
    width: 'auto',
    padding: '5px 10px',
  },
  menuWraperClass: {
    '& .MuiPaper-root': {
      borderRadius: '8px',
    },
    '& .MuiList-root': {
      paddingBottom: '0px',
      paddingTop: '0px',
    },
  },
  menuPaperClass: {
    marginTop: '10px',
  },
  displayGrid: {
    display: 'grid',
    gap: '4px',
  },
  ratingClass: {
    color: '#145DA0',
  },
  ratingGoldClass: {
    color: '#D6BA28',
  },
  borderBottomClass: {
    borderBottom: '1px solid #E6E8F0',
  },
  borderBottomWithPClass: {
    borderBottom: '1px solid #E6E8F0',
    padding: '5px',
  },
  tableListImageClass: {
    borderRadius: '8px',
    width: '30px',
    maxHeight: '30px',
  },
  cardSortDownIcon: {
    transform: 'rotate(270deg)',
    fontSize: '30px',
  },
  cardSortUpIcon: {
    transform: 'rotate(270deg)',
    fontSize: '30px',
  },
  calenderContainerClass: {
    borderRadius: '8px',
    color: '#fff',
  },
  calenderHeaderContainer: {
    margin: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  linkTextActionButtonClass: {
    color: '#3072C6',
    textDecoration: 'underline',
    padding: '4px 8px',
    backgroundColor: '#E6E8F0',
    boxShadow: 'unset',
    fontWeight: '500',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
    '&:hover': {
      backgroundColor: '#E6E8F0',
      boxShadow: 'unset',
      textDecoration: 'underline',
    },
  },
  marginTop70: {
    marginTop: '70px',
  },
  marginPaddingZeroClass: {
    margin: '0px',
    padding: '0px',
  },
  displayFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  dailogContainer: {
    [themeStyle.breakpoints.down('600')]: {
      width: '100%',
    },
    width: '450px',
    right: '0 !important',
    left: 'unset !important',
  },
  dailogContentContainer: {
    padding: '20px',
  },
  sortIconClass: {
    fontSize: '35px',
  },
  dailogPaperContainer: {
    backgroundColor: '#F4F5F7 !important',
  },
  dailogFillTitle: {
    marginBottom: '0px',
    padding: '16px',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: '700',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    color: '#fff',
    background: '#145DA0',
  },
  dailogCardContent: {
    padding: '8px !important',
    '&:last-child': {
      paddingBottom: '8px !important',
    },
  },
  dailogBlueButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '500',
  },
  lookUpSearchButton: {
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '5px',
    height: '100%',
    maxHeight: '38px',
  },
  dailogBlueOutlineButton: {
    color: '#145DA0',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      color: '#145DA0',
      border: '2px solid #145DA0',
    },
  },
  dailogCancelButton: {
    backgroundColor: '#BB0909',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': { backgroundColor: '#910101' },
  },
  cardHeaderFillClass: {
    backgroundColor: '#145DA0',
    cursor: 'pointer',
    color: '#fff',
    '& .MuiCardHeader-action': {
      marginTop: '0px !important',
      '& .MuiSvgIcon-root': {
        color: '#fff',
      },
    },
  },
  cardHeaderNotFillClass: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    color: '#145DA0',
    borderBottom: '1px solid #E6E8F0',
    '& .MuiCardHeader-action': {
      marginTop: '0px !important',
      '& .MuiSvgIcon-root': {
        color: '#145DA0',
      },
    },
  },
  toggleCardHeadTitle: {
    fontSize: '20px',
    lineHeight: '137.5%',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    textAlign: 'left',
  },
  toggleCardHeadTitleOptionalClass: {
    fontSize: '15px',
    lineHeight: '137.5%',
    fontWeight: '500',
    fontFamily: 'Inter',
    textAlign: 'left',
    fontStyle: 'italic',
  },
  typographySubtitleClass: {
    fontSize: '18px',
    lineHeight: '137.5%',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#145DA0',
  },
  dragDropContainerClass: {
    border: '2px solid #145DA0',
    borderRadius: '8px',
    display: 'flex',
    gap: '8px',
    padding: '16px',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
  },
  dropZoneImageIconClass: {
    width: '4em',
    height: '4em',
  },
  dropZoneImageListClass: {
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(auto-fill,minmax(110px, 0fr)) !important',
    gridTemplateRows: 'auto auto',
    gridAutoColumns: 'minmax(110px, 0fr)',
    margin: '0px',
    marginTop: '8px',
    gap: '8px !important',
    padding: '0px',
    // '&::-webkit-scrollbar': {
    //   display: '10px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   background: '#E6E8F0',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#C0C7CA',
    //   '&:hover': {
    //     background: '#C0C7CA',
    //   },
    // },
  },
  dropZoneTextClass: {
    fontSize: '12px',
    lineHeight: '19px',
    color: '#145DA0',
    fontWeight: '400',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    letterSpacing: '0.15px',
  },
  smallSectionWithScroll: {
    height: 'calc(100vh - 153px)',
    padding: '10px 10px 3px 0px',
    paddingTop: '0px',
    overflowX: 'auto',
    width: '103%',
  },
  smallSectionBoxPageTitle: {
    fontSize: '40px',
    fontWeight: 700,
    padding: '10px 10px 10px 0px',
    paddingTop: '0px',
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  typographyInfoClass: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
    color: '#37474F !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  breadcrumbLinkClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '16px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
    lineHeight: '20px',
    fontWeight: 700,
    color: '#3072C6 !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    textDecorationLine: 'underline',
  },
  breadcrumbClass: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '16px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
    lineHeight: '20px',
    fontWeight: 700,
    color: '#37474F !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  breadcrumbClassV2: {
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '16px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '12px',
    },
    lineHeight: '20px',
    fontWeight: 700,
    color: '#145DA0',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    borderBottom: '1px solid #145DA0',
  },
  breadcrumbsRootClass: {
    margin: '13px 0px 13px 0px',
    '& .MuiBreadcrumbs-separator': {
      margin: '0px !important',
    },
  },
  alertSuccessRootClass: {
    position: 'absolute',
    top: '0',
    zIndex: '9999',
    width: '100%',
    background: '#16A085',
    color: '#fff',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '18px',
    borderRadius: '0px',
  },
  alertWarningRootClass: {
    position: 'absolute',
    top: '0',
    zIndex: '9999',
    width: '100%',
    background: '#F39A79',
    color: '#fff',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '18px',
    borderRadius: '0px',
    [themeStyle.breakpoints.down('900')]: {
      position: 'fixed',
    },
  },
  alertErrorRootClass: {
    position: 'absolute',
    top: '0',
    zIndex: '9999',
    width: '100%',
    background: '#C44D56',
    color: '#fff',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '18px',
    borderRadius: '0px',
    [themeStyle.breakpoints.down('900')]: {
      position: 'fixed',
    },
  },
  imagePopupModelClass: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(0 0 0 / 80%) !important',
    },
  },
  imagePopupBlueToolBarSquareButton: {
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    borderRadius: '8px !important',
    position: 'absolute',
    right: '6px',
    zIndex: '999',
    padding: '10px 10px !important',
    minWidth: 'unset !important',
    top: '33px',
    '& svg': {
      fontSize: '30px',
    },
  },
  imagePopupModalClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    boxShadow: 0,
    justifyContent: 'right',
    textAlign: 'right',
    paddingTop: '20px',
    width: '60vw',
    height: '90vh',
    '&:focus-visible': {
      outline: 'unset !important',
    },
  },
  datePickerInputIconClass: {
    fontSize: '12px',
  },
  liClass: {
    fontSize: '16px',
    fontWeight: '400',
  },
  whiteSpaceBreakClass: {
    whiteSpace: 'break-spaces',
  },
  snackbarRoot: {
    position: 'unset',
  },
  cardAddPartsTableListV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 288px)',
  },
  cardAddPartsOfVehicleTableListV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 365px)',
  },
  cardTableListSearchVehiclePartsV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 295px)',
  },
  cardTableListWorkOrderInvoiceV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 220px)',
  },
  cardTableListPartsTraderV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 160px)',
  },
  secondCardTableListV2: {
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 170px)',
  },
  secondCardTableUsersPermissionListV2: {
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 270px)',
  },
  secondCardTableUsersPermissionListAddV2: {
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 355px)',
  },
  skeletonTableListV2: {
    borderRadius: '8px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25) !important',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 170px)',
    backgroundColor: '#fff',
  },
}));

export const NavBarBottomSection = experimentalStyled('div')(({ theme }) => ({
  padding: '1px',
  paddingBottom: '4px',
  [theme.breakpoints.up(1600)]: {
    position: 'absolute',
  },
  bottom: 0,
  left: '13%',
  margin: '0 auto',
}));

export const NavBarBottomMobileTabletSection = experimentalStyled('div')(() => ({
  padding: '1px',
  position: 'absolute',
  bottom: 0,
  left: '12%',
  margin: '0 auto',
}));

export const LayoutSearchRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  // height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

export const LayoutSearchWrapper = experimentalStyled('div')(() => ({
  paddingTop: '0px',
  overflow: 'auto',
  paddingLeft: '16px',
  paddingBottom: '16px',
  width: 'calc(100vw - 280px)',
  height: '100vh',
}));

export const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderBottom: '1px solid #DBDBDB',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer - 100,
}));
