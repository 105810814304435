import { setStateLoader } from '../slices/loader-reducer';
import { setAlertData } from '../slices/master-reducer';
import { useDispatch } from '../store';

const useCommonFunctionHook = () => {
  const dispatch = useDispatch();

  const digitsOnly = (value) =>
    typeof value !== 'undefined' && value.length !== 0 ? /^\d+(\.\d{1,5})?$/.test(value) : true;

  // const specialCharNotAllow = (value) => !/[~`!@#$%^&*()+=\-[\]\\';,/{}|\\":<>?]/g.test(value) || (typeof value !== 'undefined' && value.length === 0);
  const specialCharNotAllow = (value) =>
    /^(\d|\w)+$/.test(value) || (typeof value !== 'undefined' && value.length === 0);

  const openInNewTab = (url) => {
    if (url) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  const catchBlock = (error) => {
    dispatch(setStateLoader(false));
    if (typeof error?.response?.data?.Error !== 'undefined' && error?.response?.data?.Error !== '') {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.response?.data?.Error || 'Something went wrong',
        }),
      );
    } else if (typeof error?.response?.data?.Description?.ErrorDescription !== 'undefined') {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.response?.data?.Description?.ErrorDescription || 'Something went wrong',
        }),
      );
    } else if (
      typeof error?.response?.data?.Description?.ValidationErrors !== 'undefined' &&
      Array.isArray(error?.response?.data?.Description?.ValidationErrors)
    ) {
      error?.response?.data?.Description?.ValidationErrors?.map((err) => {
        dispatch(
          setAlertData({
            type: 'error',
            message: err.Message || 'Something went wrong',
          }),
        );
        return err;
      });
    } else if (typeof error?.response?.data?.DUPLICATE !== 'undefined' && error?.response?.data?.DUPLICATE !== '') {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.response?.data?.DUPLICATE || 'Something went wrong',
        }),
      );
    } else if (typeof error?.response?.data?.ERROR !== 'undefined' && error?.response?.data?.ERROR !== '') {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.response?.data?.ERROR || 'Something went wrong',
        }),
      );
    } else if (typeof error?.response?.data?.Message !== 'undefined' && error?.response?.data?.Message !== '') {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.response?.data?.Message || 'Something went wrong',
        }),
      );
    } else if (typeof error?.response?.data !== 'undefined' && error?.response?.data !== '') {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.response?.data || 'Something went wrong',
        }),
      );
    } else {
      dispatch(
        setAlertData({
          type: 'error',
          message: error?.message || 'Something went wrong',
        }),
      );
    }
  };

  const nl2br = (str, isXhtml) => {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
    return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
  };

  const catchBlockGetErrorMessage = (error) => {
    let errorMessage = '';
    if (typeof error?.response?.data?.Error !== 'undefined' && error?.response?.data?.Error !== '') {
      errorMessage = error?.response?.data?.Error || 'Something went wrong';
    } else if (typeof error?.response?.data?.DUPLICATE !== 'undefined' && error?.response?.data?.DUPLICATE !== '') {
      errorMessage = error?.response?.data?.DUPLICATE || 'Something went wrong';
    } else if (typeof error?.response?.data?.ERROR !== 'undefined' && error?.response?.data?.ERROR !== '') {
      errorMessage = error?.response?.data?.ERROR || 'Something went wrong';
    } else if (typeof error?.response?.data?.Message !== 'undefined' && error?.response?.data?.Message !== '') {
      errorMessage = error?.response?.data?.Message || 'Something went wrong';
    } else if (typeof error?.response?.data !== 'undefined' && error?.response?.data !== '') {
      errorMessage = error?.response?.data || 'Something went wrong';
    } else {
      errorMessage = error?.message || 'Something went wrong';
    }
    return errorMessage;
  };

  const generateUniqueString = (prefix = '', length = 8) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return `${prefix}-${result}`;
  };

  return {
    digitsOnly,
    specialCharNotAllow,
    catchBlock,
    openInNewTab,
    catchBlockGetErrorMessage,
    nl2br,
    generateUniqueString,
  };
};

export default useCommonFunctionHook;
