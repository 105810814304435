import React, { memo } from 'react';
import { Checkbox as MaterialCheckbox, FormControl, FormControlLabel } from '@mui/material';

const CheckboxInput = ({ field, label, fullWidth, checked, ...rest }) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        {...field}
        control={<MaterialCheckbox checked={checked} disableRipple />}
        label={label}
        {...rest}
      />
    </FormControl>
  );
};

export default memo(CheckboxInput);
