import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';

const themeStyle = createTheme();

export const useStyles = makeStyles(() => ({
  filterContainer: {
    marginTop: '0px',
  },
  smallSectionBoxPageTitle: {
    fontSize: '40px',
    fontWeight: 700,
    padding: '10px 10px 10px 0px',
    paddingTop: '0px',
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  boxPageTitle: {
    marginTop: '16px',
    fontSize: '40px',
    lignHeight: '44px',
    fontWeight: 700,
    color: '#145DA0 !important',
    lineHeight: '40px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  tabAutoWidthClass: {
    // width: '320px',
    boxShadow: '0px 2px 2px rgb(0 0 0 / 25%)',
    // filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
    minHeight: 'auto',
    border: '2px solid #145DA0',
    borderRadius: '8px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      whiteSpace: 'nowrap',
      minHeight: '30px',
      textTransform: 'capitalize',
      weight: 500,
      lignHeight: '24px',
      color: '#145DA0',
      borderBottom: 'none !important',
    },
    '& .MuiTabs-root': {
      minHeight: '36px',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      background: '#145DA0',
      color: '#fff',
    },
    '& .MuiButtonBase-root': {
      borderLeft: '2px solid #145DA0',
      borderBottom: 'none !important',
      padding: '0px 10px !important',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    '& .MuiTabScrollButton-root': {
      '&:first-child': {
        borderRight: '2px solid #145DA0',
        opacity: '1 !important',
      },
      '&:last-child': {
        borderLeft: '2px solid #145DA0',
        opacity: '1 !important',
      },
    },
  },
  tabIconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 10px',
    '& .MuiSvgIcon-root': {
      marginBottom: '0px !important',
      marginRight: '5px',
      width: '20px',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  tabLabelContainer: {
    width: 'auto',
    padding: '5px 10px',
  },
  chip: {
    '& .MuiChip-root': {
      background: '#0C2D48',
      color: '#fff',
      '&:svg': {
        color: '#fff',
      },
    },
    background: '#0C2D48',
    color: '#fff',
    '&:svg': {
      color: '#fff',
    },
  },
  filterIconContainer: {
    padding: '0px',
  },
  cardSortDownIcon: {
    transform: 'rotate(270deg)',
    fontSize: '30px',
  },
  cardSortUpIcon: {
    transform: 'rotate(270deg)',
    fontSize: '30px',
  },
  sortIconClass: {
    fontSize: '35px',
  },
  menuWraperClass: {
    '& .MuiPaper-root': {
      borderRadius: '8px',
    },
    '& .MuiList-root': {
      paddingBottom: '0px',
      paddingTop: '0px',
    },
  },
  filterColumnCardContainer: {
    height: '100%',
    borderRadius: '8px',
    border: '2px solid #145DA0',
    paddingBottom: '8px',
  },
  filterColumnMenuTitleClass: {
    color: '#145DA0',
    textAlign: 'center',
    paddingBottom: '16px',
    paddingTop: '16px',
    borderBottom: '1px solid #E6E8F0',
    whiteSpace: 'nowrap',
    fontSize: '20px !important',
    fontWeight: 700,
  },
  cardContentZeroMarginClass: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: '0px !important',
    },
  },
  filterMenu: {
    display: 'flex',
    padding: '15px',
    paddingTop: '0px',
    paddingRight: '5px',
    paddingBottom: '0 !important',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  blueActionButton: {
    marginRight: '8px',
    backgroundColor: '#145DA0',
    color: '#FFFFFF',
    height: '40px',
  },
  tableViewModeIconButton: {
    margin: '0px',
    padding: '0px',
  },
  cardListViewImageIcon: {
    width: '30px',
  },
}));
