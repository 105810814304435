import Label from '../components/label-component';

export const getStatusLabel = (status, statusObj = '') => {
  if (typeof statusObj === 'string') {
    // eslint-disable-next-line no-param-reassign
    statusObj = {
      DRAFT: {
        text: 'Draft',
        color: 'secondary',
      },
      'TO-DO': {
        text: 'To Do',
        color: 'error',
      },
      'IN-PROGRESS': {
        text: 'In Progress',
        color: 'warning',
      },
      COMPLETE: {
        text: 'Complete',
        color: 'success',
      },
      Draft: {
        text: 'Draft',
        color: 'secondary',
      },
      'Awaiting Payment': {
        text: 'Awaiting Payment',
        color: 'warning',
      },
      Paid: {
        text: 'Paid',
        color: 'success',
      },
      Overdue: {
        text: 'Overdue',
        color: 'error',
      },
    };
  }
  if (typeof statusObj[status] !== 'undefined') {
    const { text, color } = statusObj[status];
    return <Label color={color}>{text}</Label>;
  }
  return '';
};
