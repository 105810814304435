/* eslint-disable no-lonely-if */
import { subComponentKeys } from '../static/permissions';
import { useSelector } from '../store';

const useCheckPermissions = (module = '', subModule = '', permisson = '') => {
  const { permissions } = useSelector((state) => state.tenant);
  if (module === 'search') {
    return false;
  }
  if (Object.keys(permissions).length > 0) {
    if (permissions?.wildcard === true) {
      return false;
    }
    if (permissions?.modules && Object.keys(permissions?.modules).length > 0) {
      if (module !== '') {
        const moduleTmp = permissions?.modules[module];
        if (moduleTmp[subComponentKeys[module].IS_VIEWABLE] === false) {
          return '';
        }
        if (moduleTmp[subComponentKeys[module].ACCESS] === false) {
          return 'upgrade';
        }
        if (moduleTmp[subComponentKeys[module].ACCESS] === true) {
          if (
            typeof moduleTmp[subComponentKeys[module].WILDCARD] !== 'undefined' &&
            moduleTmp[subComponentKeys[module].WILDCARD] === true
          ) {
            return false;
          }
          if (subModule !== '' && permisson !== '') {
            if (
              typeof moduleTmp[subModule] !== 'undefined' &&
              moduleTmp[subModule][subComponentKeys[module][subModule].ACCESS] === false
            ) {
              return 'upgrade';
            }
            if (typeof moduleTmp[subModule] !== 'undefined' && moduleTmp[subModule][permisson] === true) {
              return false;
            }
            return true;
          }
          if (subModule === '' && permisson !== '') {
            if (permisson === 'ACCESS' && moduleTmp[subComponentKeys[module][permisson]] === true) {
              return false;
            }
            if (
              typeof moduleTmp[subComponentKeys[module][permisson]] !== 'undefined' &&
              moduleTmp[subComponentKeys[module][permisson]] === true
            ) {
              return false;
            }
            return true;
          }
          return true;
        }
        return true;
      }
    }
    return true;
  }
  return true;
};

export default useCheckPermissions;
