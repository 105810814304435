/* eslint-disable prettier/prettier */
export const permissionKeys = {
  DASHBOARD: 'dashboard',
  SEARCH: 'search',
  VEHICLE: 'vehicle',
  PART: 'part',
  ECOSYSTEM: 'ecosystem',
  WORKORDER: 'work_order',
  INVOICE: 'invoice',
  USER: 'user',
  CONTACT: 'contact',
  LOCATION: 'location',
  WEBSITE: 'website',
  TRADEME: 'tradeMe',
  PARTSTRADER: 'partsTrader',
  CAPRICORN: 'capricorn',
};

export const subComponentKeys = {
  [permissionKeys.DASHBOARD]: {
    COUNTRY: 'country',
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    VIEW_TOTAL_SALES_BY_TIME_PERIOD: 'view_total_sales_by_time_period',
    VIEW_SALES_BY_SALES_PERSON_PER_TIME_PERIOD: 'view_sales_by_sales_person_per_time_period',
    VIEW_TODAYS_SALES_LIST: 'view_todays_sales_list',
    VIEW_OVERVIEW_METRICS: 'view_overview_metrics',
    VIEW_DAILY_METRICS: 'view_daily_metrics',
  },
  [permissionKeys.SEARCH]: {
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    PART: 'part',
    VEHICLE: 'vehicle',
    PART_ECO: 'part_eco',
    VEHICLE_ECO: 'vehicle_eco',
  },
  [permissionKeys.VEHICLE]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    VIEW_VEHICLE_STATS: 'view_vehicle_stats',
    VIEW_VEHICLE_COSTS: 'view_vehicle_costs',
    VIEW: 'view',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    STOCK_TAKE: 'stock_take',
    CRUSH: 'crush',
    PRINT_TAG: 'print_tag',
    PART_QUICK_SELL: 'part_quick_sell',
    ADD_QUICK_PART: 'add_quick_part',
    ADD_PART: 'add_part',
    VIEW_HISTORY: 'view_history',
  },
  [permissionKeys.PART]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    VIEW: 'view',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    STOCK_TAKE: 'stock_take',
    PRINT_TAG: 'print_tag',
    MARK_AS_MISSING: 'mark_as_missing',
    QUICK_SELL: 'quick_sell',
    VIEW_QUICK_SELL: 'view_quick_sell',
    UPDATE_QUICK_SELL: 'update_quick_sell',
    DELETE_QUICK_SELL: 'delete_quick_sell',
    VIEW_HISTORY: 'view_history',
    VIEW_ALTERNATIVE_PARTS: 'view_alternative_parts',
    VIEW_SALES_HISTORY_FOR_SIMILAR_PART: 'view_sales_history_for_similar_part',
  },
  [permissionKeys.ECOSYSTEM]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    [permissionKeys.PART]: {
      ACCESS: 'access',
      DISABLED: 'disabled',
      WILDCARD: 'wildcard',
      VIEW: 'view',
      REQUEST_PART: 'request_part',
    },
    [permissionKeys.VEHICLE]: {
      ACCESS: 'access',
      DISABLED: 'disabled',
      WILDCARD: 'wildcard',
      VIEW: 'view',
      REQUEST_PART: 'request_part',
    },
  },
  [permissionKeys.WORKORDER]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    SEARCH: 'search',
    VIEW_METRICS: 'view_metrics',
    VIEW: 'view',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    GENERATE_INVOICE: 'generate_invoice',
    PRINT: 'print',
    UPDATE_STATUS: 'update_status',
    VIEW_HISTORY: 'view_history',
  },
  [permissionKeys.INVOICE]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    SEARCH: 'search',
    VIEW_METRICS: 'view_metrics',
    VIEW: 'view',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    PRINT: 'print',
    APPROVE_INVOICE: 'approve_invoice',
    DISPATCH: 'dispatch',
    VIEW_PREVIEW: 'view_preview',
    BULK_PRICING: 'bulk_pricing',
    VIEW_TRANSACTIONS: 'view_transactions',
    CREATE_CREDIT: 'create_credit',
    DELETE_CREDIT: 'delete_credit',
    CREATE_PAYMENT: 'create_payment',
    DELETE_PAYMENT: 'delete_payment',
    VIEW_NOTES: 'view_notes',
    CREATE_NOTES: 'create_notes',
    VIEW_HISTORY: 'view_history',
  },
  [permissionKeys.USER]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    VIEW_USER_LIST: 'view_user_list',
    VIEW_USER: 'view_user',
    VIEW_USER_PERMISSIONS: 'view_user_permissions',
    CREATE_USER: 'create_user',
    UPDATE_USER: 'update_user',
    DELETE_USER: 'delete_user',
    CHANGE_USER_PASSWORD: 'change_user_password',
    UPDATE_USER_PERMISSIONS: 'update_user_permissions',
  },
  [permissionKeys.CONTACT]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    SEARCH: 'search',
    VIEW: 'view',
    UPDATE: 'update',
    CREATE: 'create',
    DELETE: 'delete',
  },
  [permissionKeys.LOCATION]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    VIEW: 'view',
    SEARCH: 'search',
    UPDATE: 'update',
    CREATE: 'create',
    DELETE: 'delete',
    PRINT_TAG: 'print_tag',
  },
  [permissionKeys.WEBSITE]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    [permissionKeys.PART]: {
      ACCESS: 'access',
      DISABLED: 'disabled',
      WILDCARD: 'wildcard',
      VIEW: 'view',
      CREATE: 'create',
      DELETE: 'delete',
      UPDATE: 'update',
    },
    [permissionKeys.VEHICLE]: {
      ACCESS: 'access',
      DISABLED: 'disabled',
      WILDCARD: 'wildcard',
      VIEW: 'view',
      CREATE: 'create',
      DELETE: 'delete',
      UPDATE: 'update',
    },
  },
  [permissionKeys.TRADEME]: {
    IS_VIEWABLE: 'is-viewable',
    ACCESS: 'access',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    [permissionKeys.PART]: {
      ACCESS: 'access',
      DISABLED: 'disabled',
      WILDCARD: 'wildcard',
      VIEW: 'view',
      CREATE: 'create',
      DELETE: 'delete',
      UPDATE: 'update',
    },
    [permissionKeys.VEHICLE]: {
      ACCESS: 'access',
      DISABLED: 'disabled',
      WILDCARD: 'wildcard',
      VIEW: 'view',
      CREATE: 'create',
      DELETE: 'delete',
      UPDATE: 'update',
    },
  },
  [permissionKeys.PARTSTRADER]: {
    ACCESS: 'access',
    IS_VIEWABLE: 'is-viewable',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    SEARCH: 'search',
    VIEW_REQUEST: 'view_request',
    MARK_AS_QUOTED: 'mark_as_quoted',
    HIDE_REQUEST: 'hide_request',
  },
  [permissionKeys.CAPRICORN]: {
    ACCESS: 'access',
    IS_VIEWABLE: 'is-viewable',
    COUNTRY: 'country',
    DISABLED: 'disabled',
    WILDCARD: 'wildcard',
    SEARCH: 'search',
    VIEW_REQUEST: 'view_request',
    REPLY: 'reply',
    HIDE_REQUEST: 'hide_request',
  },
};

export const defaultPermissions = {
  website: {
    access: true,
    'is-viewable': true,
    part: {
      create: false,
      update: false,
      view: false,
      access: true,
      delete: false,
      wildcard: false,
    },
    vehicle: {
      create: false,
      update: false,
      view: false,
      access: true,
      delete: false,
      wildcard: false,
    },
    wildcard: false,
  },
  ecosystem: {
    access: true,
    'is-viewable': true,
    part: {
      view: false,
      access: true,
      request_part: false,
      wildcard: false,
    },
    vehicle: {
      view: false,
      access: true,
      request_part: false,
      wildcard: false,
    },
    wildcard: false,
  },
  part: {
    print_tag: false,
    stock_take: false,
    access: true,
    view_quick_sell: false,
    mark_as_missing: false,
    update: false,
    delete: false,
    view_sales_history_for_similar_part: false,
    wildcard: false,
    delete_quick_sell: false,
    view: false,
    view_history: false,
    view_alternative_parts: false,
    create: false,
    quick_sell: false,
    update_quick_sell: false,
    'is-viewable': true,
  },
  vehicle: {
    print_tag: false,
    stock_take: false,
    access: true,
    add_part: false,
    update: false,
    view_vehicle_stats: false,
    part_quick_sell: false,
    view_vehicle_costs: false,
    delete: false,
    wildcard: false,
    view: false,
    view_history: false,
    crush: false,
    add_quick_part: false,
    create: false,
    'is-viewable': true,
  },
  capricorn: {
    search: false,
    view_request: false,
    access: true,
    hide_request: false,
    reply: false,
    'is-viewable': true,
    wildcard: false,
  },
  partsTrader: {
    search: false,
    view_request: false,
    access: true,
    hide_request: false,
    mark_as_quoted: false,
    'is-viewable': true,
    wildcard: false,
  },
  contact: {
    search: false,
    view: false,
    access: true,
    create: false,
    update: false,
    delete: false,
    'is-viewable': true,
    wildcard: false,
  },
  location: {
    print_tag: false,
    search: false,
    view: false,
    access: true,
    create: false,
    update: false,
    delete: false,
    'is-viewable': true,
    wildcard: false,
  },
  invoice: {
    delete_payment: false,
    view_notes: false,
    access: true,
    dispatch: false,
    delete_credit: false,
    view_metrics: false,
    update: false,
    create_payment: false,
    delete: false,
    view_transactions: false,
    wildcard: false,
    create_notes: false,
    print: false,
    search: false,
    view: false,
    view_history: false,
    approve_invoice: false,
    create_credit: false,
    create: false,
    bulk_pricing: false,
    'is-viewable': true,
    view_preview: false,
  },
  tradeMe: {
    access: true,
    'is-viewable': true,
    part: {
      create: false,
      update: false,
      view: false,
      access: true,
      delete: false,
      wildcard: false,
    },
    vehicle: {
      create: false,
      update: false,
      view: false,
      access: true,
      delete: false,
      wildcard: false,
    },
    wildcard: false,
  },
  user: {
    view_user_list: false,
    view_user_permissions: false,
    access: true,
    update_user: false,
    update_user_permissions: false,
    create_user: false,
    view_user: false,
    change_user_password: false,
    delete_user: false,
    'is-viewable': true,
    wildcard: false,
  },
  dashboard: {
    view_total_sales_by_time_period: false,
    access: true,
    view_sales_by_sales_person_per_time_period: false,
    view_overview_metrics: false,
    view_daily_metrics: false,
    'is-viewable': true,
    view_todays_sales_list: false,
    wildcard: false,
  },
  work_order: {
    access: true,
    view_metrics: false,
    update: false,
    generate_invoice: false,
    delete: false,
    wildcard: false,
    print: false,
    search: false,
    update_status: false,
    view: false,
    view_history: false,
    create: false,
    'is-viewable': true,
  },
};

export const permissionListView = [
  {
    title: 'Dashboard',
    description: 'Control user access to the dashboard.',
    permission_key: permissionKeys.DASHBOARD,
    permissions: [
      {
        title: 'View Total Sales By Time Period Graph',
        description: 'Allow user to view Total Sales By Time Period graph.',
        permission_key: subComponentKeys[permissionKeys.DASHBOARD].VIEW_TOTAL_SALES_BY_TIME_PERIOD,
        permission_key_string: 'VIEW_TOTAL_SALES_BY_TIME_PERIOD',
      },
      {
        title: 'View Sales By Sales Person Graph',
        description: 'Allow user to view Sales By Sales Person graph.',
        permission_key: subComponentKeys[permissionKeys.DASHBOARD].VIEW_SALES_BY_SALES_PERSON_PER_TIME_PERIOD,
        permission_key_string: 'VIEW_SALES_BY_SALES_PERSON_PER_TIME_PERIOD',
      },
      {
        title: 'View Overview Metrics',
        description: 'Allow user view Overview Metrics.',
        permission_key: subComponentKeys[permissionKeys.DASHBOARD].VIEW_OVERVIEW_METRICS,
        permission_key_string: 'VIEW_OVERVIEW_METRICS',
      },
      {
        title: 'View Daily Metrics',
        description: 'Allow user to view Daily metrics.',
        permission_key: subComponentKeys[permissionKeys.DASHBOARD].VIEW_DAILY_METRICS,
        permission_key_string: 'VIEW_DAILY_METRICS',
      },
      {
        title: 'View Today\'s Sales List',
        description: 'Allow user to view Today\'s Sales list.',
        permission_key: subComponentKeys[permissionKeys.DASHBOARD].VIEW_TODAYS_SALES_LIST,
        permission_key_string: 'VIEW_TODAYS_SALES_LIST',
      },
    ],
  },
  {
    title: 'Vehicles',
    description: 'Control user access to vehicles.',
    permission_key: permissionKeys.VEHICLE,
    permissions: [
      {
        title: 'View',
        description: 'Allow user to view vehicle details.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].VIEW,
        permission_key_string: 'VIEW',
      },
      {
        title: 'Edit',
        description: 'Allow user to edit vehicles details.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].UPDATE,
        permission_key_string: 'UPDATE',
      },
      {
        title: 'Delete',
        description: 'Allow user to delete vehicles.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].DELETE,
        permission_key_string: 'DELETE',
      },
      {
        title: 'Create',
        description: 'Allow user to create vehicles.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].CREATE,
        permission_key_string: 'CREATE',
      },
      {
        title: 'Print tag',
        description: 'Allow user to print vehicle tags.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].PRINT_TAG,
        permission_key_string: 'PRINT_TAG',
      },
      {
        title: 'Stock take',
        description: 'Allow user to stock take a vehicle.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].STOCK_TAKE,
        permission_key_string: 'STOCK_TAKE',
      },
      {
        title: 'Add part',
        description: 'Allow user to add a part to a vehicle.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].ADD_PART,
        permission_key_string: 'ADD_PART',
      },
      {
        title: 'Part quick sell',
        description: 'Allow user to quick sell a part from a vehicle.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].PART_QUICK_SELL,
        permission_key_string: 'PART_QUICK_SELL',
      },
      {
        title: 'View history',
        description: 'Allow user to view vehicle history.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].VIEW_HISTORY,
        permission_key_string: 'VIEW_HISTORY',
      },
      {
        title: 'Crush',
        description: 'Allow user to crush vehicle.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].CRUSH,
        permission_key_string: 'CRUSH',
      },
      {
        title: 'Add quick part',
        description: 'Allow user to add a quick part to a vehicle.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].ADD_QUICK_PART,
        permission_key_string: 'ADD_QUICK_PART',
      },
      {
        title: 'View vehicle stats',
        description: 'Allow user to view vehicle stats.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].VIEW_VEHICLE_STATS,
        permission_key_string: 'VIEW_VEHICLE_STATS',
      },
      {
        title: 'View vehicle costs',
        description: 'Allow user to view vehicle costs.',
        permission_key: subComponentKeys[permissionKeys.VEHICLE].VIEW_VEHICLE_COSTS,
        permission_key_string: 'VIEW_VEHICLE_COSTS',
      },
    ],
  },
  {
    title: 'Parts',
    description: 'Control user access to parts.',
    permission_key: permissionKeys.PART,
    permissions: [
      {
        title: 'View',
        description: 'Allow user to view part details.',
        permission_key: subComponentKeys[permissionKeys.PART].VIEW,
        permission_key_string: 'VIEW',
      },
      {
        title: 'Edit',
        description: 'Allow user to edit part details.',
        permission_key: subComponentKeys[permissionKeys.PART].UPDATE,
        permission_key_string: 'UPDATE',
      },
      {
        title: 'Delete',
        description: 'Allow user to delete parts.',
        permission_key: subComponentKeys[permissionKeys.PART].DELETE,
        permission_key_string: 'DELETE',
      },
      {
        title: 'Create',
        description: 'Allow user to create parts.',
        permission_key: subComponentKeys[permissionKeys.PART].CREATE,
        permission_key_string: 'CREATE',
      },
      {
        title: 'Print tag',
        description: 'Allow user to print part tags.',
        permission_key: subComponentKeys[permissionKeys.PART].PRINT_TAG,
        permission_key_string: 'PRINT_TAG',
      },
      {
        title: 'Stock take',
        description: 'Allow user to stock take a part.',
        permission_key: subComponentKeys[permissionKeys.PART].STOCK_TAKE,
        permission_key_string: 'STOCK_TAKE',
      },
      {
        title: 'View quick sell',
        description: 'Allow user to view a part\'s quick sale.',
        permission_key: subComponentKeys[permissionKeys.PART].VIEW_QUICK_SELL,
        permission_key_string: 'VIEW_QUICK_SELL',
      },
      {
        title: 'Mark as missing',
        description: 'Allow user to mark a part as missing.',
        permission_key: subComponentKeys[permissionKeys.PART].MARK_AS_MISSING,
        permission_key_string: 'MARK_AS_MISSING',
      },
      {
        title: 'View sales history for similar part',
        description: 'Allow user to view a part\'s sales history.',
        permission_key: subComponentKeys[permissionKeys.PART].VIEW_SALES_HISTORY_FOR_SIMILAR_PART,
        permission_key_string: 'VIEW_SALES_HISTORY_FOR_SIMILAR_PART',
      },
      {
        title: 'Delete quick sell',
        description: 'Allow user to delete a part\'s quick sale.',
        permission_key: subComponentKeys[permissionKeys.PART].DELETE_QUICK_SELL,
        permission_key_string: 'DELETE_QUICK_SELL',
      },
      {
        title: 'View history',
        description: 'Allow user to view part history.',
        permission_key: subComponentKeys[permissionKeys.PART].VIEW_HISTORY,
        permission_key_string: 'VIEW_HISTORY',
      },
      {
        title: 'View alternative parts',
        description: 'Allow user to view alternative parts.',
        permission_key: subComponentKeys[permissionKeys.PART].VIEW_ALTERNATIVE_PARTS,
        permission_key_string: 'VIEW_ALTERNATIVE_PARTS',
      },
      {
        title: 'Quick sell',
        description: 'Allow user to quick sell a part.',
        permission_key: subComponentKeys[permissionKeys.PART].QUICK_SELL,
        permission_key_string: 'QUICK_SELL',
      },
      {
        title: 'Update quick sell',
        description: 'Allow user to edit a part\'s quick sale.',
        permission_key: subComponentKeys[permissionKeys.PART].UPDATE_QUICK_SELL,
        permission_key_string: 'UPDATE_QUICK_SELL',
      },
    ],
  },
  {
    title: 'Work order',
    description: 'Control user access to the work order.',
    permission_key: permissionKeys.WORKORDER,
    permissions: [
      {
        title: 'View metrics',
        description: 'Allow user to view work order metrics.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].VIEW_METRICS,
        permission_key_string: 'VIEW_METRICS',
      },
      {
        title: 'Edit',
        description: 'Allow user to edit work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].UPDATE,
        permission_key_string: 'UPDATE',
      },
      {
        title: 'Generate invoice',
        description: 'Allow user to generate invoices from work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].GENERATE_INVOICE,
        permission_key_string: 'GENERATE_INVOICE',
      },
      {
        title: 'Delete',
        description: 'Allow user to delete work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].DELETE,
        permission_key_string: 'DELETE',
      },
      {
        title: 'Print',
        description: 'Allow user to print work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].PRINT,
        permission_key_string: 'PRINT',
      },
      {
        title: 'Search',
        description: 'Allow user to search for work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].SEARCH,
        permission_key_string: 'SEARCH',
      },
      {
        title: 'Update status',
        description: 'Allow user to update a work order\'s status.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].UPDATE_STATUS,
        permission_key_string: 'UPDATE_STATUS',
      },
      {
        title: 'View',
        description: 'Allow user to view work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].VIEW,
        permission_key_string: 'VIEW',
      },
      {
        title: 'View history',
        description: 'Allow user to view work order history.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].VIEW_HISTORY,
        permission_key_string: 'VIEW_HISTORY',
      },
      {
        title: 'Create',
        description: 'Allow user to create work orders.',
        permission_key: subComponentKeys[permissionKeys.WORKORDER].CREATE,
        permission_key_string: 'CREATE',
      },
    ],
  },
  {
    title: 'Invoice',
    description: 'Control user access to the invoice.',
    permission_key: permissionKeys.INVOICE,
    permissions: [
      {
        title: 'View',
        description: 'Allow user to view invoice.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].VIEW,
        permission_key_string: 'VIEW',
      },
      {
        title: 'View metrics',
        description: 'Allow user to view invoice metrics.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].VIEW_METRICS,
        permission_key_string: 'VIEW_METRICS',
      },
      {
        title: 'Edit',
        description: 'Allow user to edit invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].UPDATE,
        permission_key_string: 'UPDATE',
      },
      {
        title: 'Create',
        description: 'Allow user to create invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].CREATE,
        permission_key_string: 'CREATE',
      },
      {
        title: 'Delete',
        description: 'Allow user to delete invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].DELETE,
        permission_key_string: 'DELETE',
      },
      {
        title: 'Print',
        description: 'Allow user to print invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].PRINT,
        permission_key_string: 'PRINT',
      },
      {
        title: 'Search',
        description: 'Allow user to search for invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].SEARCH,
        permission_key_string: 'SEARCH',
      },
      {
        title: 'Delete payment',
        description: 'Allow user to delete payments from invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].DELETE_PAYMENT,
        permission_key_string: 'DELETE_PAYMENT',
      },
      {
        title: 'View notes',
        description: 'Allow user to view invoice notes.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].VIEW_NOTES,
        permission_key_string: 'VIEW_NOTES',
      },
      {
        title: 'Dispatch',
        description: 'Allow user to dispatch invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].DISPATCH,
        permission_key_string: 'DISPATCH',
      },
      {
        title: 'Delete credit',
        description: 'Allow user to delete credit from invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].DELETE_CREDIT,
        permission_key_string: 'DELETE_CREDIT',
      },
      {
        title: 'Create payment',
        description: 'Allow user to add payments to invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].CREATE_PAYMENT,
        permission_key_string: 'CREATE_PAYMENT',
      },
      {
        title: 'View transation',
        description: 'Allow user to view transactions.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].VIEW_TRANSACTIONS,
        permission_key_string: 'VIEW_TRANSACTIONS',
      },
      {
        title: 'Create notes',
        description: 'Allow user to create notes on invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].CREATE_NOTES,
        permission_key_string: 'CREATE_NOTES',
      },
      {
        title: 'View history',
        description: 'Allow user to view invoice history.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].VIEW_HISTORY,
        permission_key_string: 'VIEW_HISTORY',
      },
      {
        title: 'Approve invoice',
        description: 'Allow user to approve invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].APPROVE_INVOICE,
        permission_key_string: 'APPROVE_INVOICE',
      },
      {
        title: 'Create credit',
        description: 'Allow user to add credit to invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].CREATE_CREDIT,
        permission_key_string: 'CREATE_CREDIT',
      },
      {
        title: 'Bulk pricing',
        description: 'Allow user to bulk price parts in invoices.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].BULK_PRICING,
        permission_key_string: 'BULK_PRICING',
      },
      {
        title: 'View preview',
        description: 'Allow user to view invoice previews.',
        permission_key: subComponentKeys[permissionKeys.INVOICE].VIEW_PREVIEW,
        permission_key_string: 'VIEW_PREVIEW',
      },
    ],
  },
  {
    title: 'Contact',
    description: 'Control user access to the contact.',
    permission_key: permissionKeys.CONTACT,
    permissions: [
      {
        title: 'View',
        description: 'Allow user to view contacts.',
        permission_key: subComponentKeys[permissionKeys.CONTACT].VIEW,
        permission_key_string: 'VIEW',
      },
      {
        title: 'Search',
        description: 'Allow user to search for contacts.',
        permission_key: subComponentKeys[permissionKeys.CONTACT].SEARCH,
        permission_key_string: 'SEARCH',
      },
      {
        title: 'Create',
        description: 'Allow user to create contacts.',
        permission_key: subComponentKeys[permissionKeys.CONTACT].CREATE,
        permission_key_string: 'CREATE',
      },
      {
        title: 'Edit',
        description: 'Allow user to edit contacts.',
        permission_key: subComponentKeys[permissionKeys.CONTACT].UPDATE,
        permission_key_string: 'UPDATE',
      },
      {
        title: 'Delete',
        description: 'Allow user to delete contacts.',
        permission_key: subComponentKeys[permissionKeys.CONTACT].DELETE,
        permission_key_string: 'DELETE',
      },
    ],
  },
  {
    title: 'Location',
    description: 'Control user access to the location.',
    permission_key: permissionKeys.LOCATION,
    permissions: [
      {
        title: 'View',
        description: 'Allow user to view locations.',
        permission_key: subComponentKeys[permissionKeys.LOCATION].VIEW,
        permission_key_string: 'VIEW',
      },
      {
        title: 'Search',
        description: 'Allow user to search for locations.',
        permission_key: subComponentKeys[permissionKeys.LOCATION].SEARCH,
        permission_key_string: 'SEARCH',
      },
      {
        title: 'Create',
        description: 'Allow user to create locations.',
        permission_key: subComponentKeys[permissionKeys.LOCATION].CREATE,
        permission_key_string: 'CREATE',
      },
      {
        title: 'Edit',
        description: 'Allow user to edit locations.',
        permission_key: subComponentKeys[permissionKeys.LOCATION].UPDATE,
        permission_key_string: 'UPDATE',
      },
      {
        title: 'Delete',
        description: 'Allow user to delete locations.',
        permission_key: subComponentKeys[permissionKeys.LOCATION].DELETE,
        permission_key_string: 'DELETE',
      },
      {
        title: 'Print tag',
        description: 'Allow user to print location tags.',
        permission_key: subComponentKeys[permissionKeys.LOCATION].PRINT_TAG,
        permission_key_string: 'PRINT_TAG',
      },
    ],
  },
  {
    title: 'User',
    description: 'Control user access to the user.',
    permission_key: permissionKeys.USER,
    permissions: [
      {
        title: 'View user list',
        description: 'Allow user to view user list.',
        permission_key: subComponentKeys[permissionKeys.USER].VIEW_USER_LIST,
        permission_key_string: 'VIEW_USER_LIST',
      },
      {
        title: 'View user',
        description: 'Allow user to view users.',
        permission_key: subComponentKeys[permissionKeys.USER].VIEW_USER,
        permission_key_string: 'VIEW_USER',
      },
      {
        title: 'View user permissions',
        description: 'Allow user to view user permissions.',
        permission_key: subComponentKeys[permissionKeys.USER].VIEW_USER_PERMISSIONS,
        permission_key_string: 'VIEW_USER_PERMISSIONS',
      },
      {
        title: 'Edit user',
        description: 'Allow user to edit user.',
        permission_key: subComponentKeys[permissionKeys.USER].UPDATE_USER,
        permission_key_string: 'UPDATE_USER',
      },
      {
        title: 'Edit user permissions',
        description: 'Allow user to edit user permissions.',
        permission_key: subComponentKeys[permissionKeys.USER].UPDATE_USER_PERMISSIONS,
        permission_key_string: 'UPDATE_USER_PERMISSIONS',
      },
      {
        title: 'Create user',
        description: 'Allow user to create users.',
        permission_key: subComponentKeys[permissionKeys.USER].CREATE_USER,
        permission_key_string: 'CREATE_USER',
      },
      {
        title: 'Change user password',
        description: 'Allow user to change user passwords.',
        permission_key: subComponentKeys[permissionKeys.USER].CHANGE_USER_PASSWORD,
        permission_key_string: 'CHANGE_USER_PASSWORD',
      },
      {
        title: 'Delete user',
        description: 'Allow user to delete users.',
        permission_key: subComponentKeys[permissionKeys.USER].DELETE_USER,
        permission_key_string: 'DELETE_USER',
      },
    ],
  },
  {
    title: 'Web shop',
    description: 'Control user access to the web shop.',
    permission_key: permissionKeys.WEBSITE,
    permissions: [
      {
        title: 'Part',
        description: 'Control user access to part web shop',
        sub_root_permission_key: permissionKeys.PART,
        permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].ACCESS,
        permission_key_string: 'ACCESS',
        is_sub_permission: true,
        permissions: [
          {
            title: 'View',
            description: 'Allow user to view part web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].VIEW,
            permission_key_string: 'VIEW',
          },
          {
            title: 'Create',
            description: 'Allow user to upload part web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].CREATE,
            permission_key_string: 'CREATE',
          },
          {
            title: 'Edit',
            description: 'Allow user to edit part web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].UPDATE,
            permission_key_string: 'UPDATE',
          },
          {
            title: 'Delete',
            description: 'Allow user to delete part web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].DELETE,
            permission_key_string: 'DELETE',
          },
        ],
      },
      {
        title: 'Vehicle',
        description: 'Control user access to vehicle web shop.',
        sub_root_permission_key: permissionKeys.VEHICLE,
        permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].ACCESS,
        permission_key_string: 'ACCESS',
        is_sub_permission: true,
        permissions: [
          {
            title: 'View',
            description: 'Allow user to view vehicle web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].VIEW,
            permission_key_string: 'VIEW',
          },
          {
            title: 'Create',
            description: 'Allow user to upload vehicle web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].CREATE,
            permission_key_string: 'CREATE',
          },
          {
            title: 'Edit',
            description: 'Allow user to edit vehicle web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].UPDATE,
            permission_key_string: 'UPDATE',
          },
          {
            title: 'Delete',
            description: 'Allow user to delete vehicle web shop listings.',
            permission_key: subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].DELETE,
            permission_key_string: 'DELETE',
          },
        ],
      },
    ],
  },
  {
    title: 'Other Dismantlers',
    description: 'Control user access to the other dismantlers.',
    permission_key: permissionKeys.ECOSYSTEM,
    permissions: [
      {
        title: 'Part',
        description: 'Control user access to the part other dismantlers.',
        sub_root_permission_key: permissionKeys.PART,
        permission_key: subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.PART].ACCESS,
        permission_key_string: 'ACCESS',
        is_sub_permission: true,
        permissions: [
          {
            title: 'View',
            description: 'Allow user to view other dismantlers parts.',
            permission_key: subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.PART].VIEW,
            permission_key_string: 'VIEW',
          },
          {
            title: 'Request Part',
            description: 'Allow user to request other dismantlers part quotes.',
            permission_key: subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.PART].REQUEST_PART,
            permission_key_string: 'REQUEST_PART',
          },
        ],
      },
      {
        title: 'Vehicle',
        description: 'Control user access to the vehicle other dismantlers.',
        sub_root_permission_key: permissionKeys.VEHICLE,
        permission_key: subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.VEHICLE].ACCESS,
        permission_key_string: 'ACCESS',
        is_sub_permission: true,
        permissions: [
          {
            title: 'View',
            description: 'Allow user to view other dismantlers vehicles.',
            permission_key: subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.VEHICLE].VIEW,
            permission_key_string: 'VIEW',
          },
          {
            title: 'Request Part',
            description: 'Allow user to request parts from other dismantlers vehicles.',
            permission_key: subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.VEHICLE].REQUEST_PART,
            permission_key_string: 'REQUEST_PART',
          },
        ],
      },
    ],
  },
  {
    title: 'TradeMe',
    description: 'Control user access to the tradeMe.',
    permission_key: permissionKeys.TRADEME,
    permissions: [
      {
        title: 'Part',
        description: 'Control user access to part TradeMe.',
        sub_root_permission_key: permissionKeys.PART,
        permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.PART].ACCESS,
        permission_key_string: 'ACCESS',
        is_sub_permission: true,
        permissions: [
          {
            title: 'View',
            description: 'Allow user to view part TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.PART].VIEW,
            permission_key_string: 'VIEW',
          },
          {
            title: 'Create',
            description: 'Allow user to upload part TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.PART].CREATE,
            permission_key_string: 'CREATE',
          },
          {
            title: 'Edit',
            description: 'Allow user to edit part TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.PART].UPDATE,
            permission_key_string: 'UPDATE',
          },
          {
            title: 'Delete',
            description: 'Allow user to delete part TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.PART].DELETE,
            permission_key_string: 'DELETE',
          },
        ],
      },
      {
        title: 'Vehicle',
        description: 'Control user access to vehicle TradeMe.',
        sub_root_permission_key: permissionKeys.VEHICLE,
        permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.VEHICLE].ACCESS,
        permission_key_string: 'ACCESS',
        is_sub_permission: true,
        permissions: [
          {
            title: 'View',
            description: 'Allow user to view vehicle TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.VEHICLE].VIEW,
            permission_key_string: 'VIEW',
          },
          {
            title: 'Create',
            description: 'Allow user to upload vehicle TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.VEHICLE].CREATE,
            permission_key_string: 'CREATE',
          },
          {
            title: 'Edit',
            description: 'Allow user to edit vehicle TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.VEHICLE].UPDATE,
            permission_key_string: 'UPDATE',
          },
          {
            title: 'Delete',
            description: 'Allow user to delete vehicle TradeMe listings.',
            permission_key: subComponentKeys[permissionKeys.TRADEME][permissionKeys.VEHICLE].DELETE,
            permission_key_string: 'DELETE',
          },
        ],
      },
    ],
  },
  // {
  //   title: 'Capricorn',
  //   description: 'Control user access to the capricorn.',
  //   permission_key: permissionKeys.CAPRICORN,
  //   permissions: [
  //     {
  //       title: 'View',
  //       description: 'Allow user to view Capricorn requests.',
  //       permission_key: subComponentKeys[permissionKeys.CAPRICORN].VIEW_REQUEST,
  //       permission_key_string: 'VIEW_REQUEST',
  //     },
  //     {
  //       title: 'Hide Request',
  //       description: 'Allow user to hide Capricorn requests.',
  //       permission_key: subComponentKeys[permissionKeys.CAPRICORN].HIDE_REQUEST,
  //       permission_key_string: 'HIDE_REQUEST',
  //     },
  //     {
  //       title: 'Reply',
  //       description: 'Allow user to reply to Capricorn requests.',
  //       permission_key: subComponentKeys[permissionKeys.CAPRICORN].REPLY,
  //       permission_key_string: 'REPLY',
  //     },
  //   ],
  // },
  {
    title: 'Parts Trader',
    description: 'Control user access to the parts trader.',
    permission_key: permissionKeys.PARTSTRADER,
    permissions: [
      {
        title: 'View',
        description: 'Allow user to view PartsTrader requests.',
        permission_key: subComponentKeys[permissionKeys.PARTSTRADER].VIEW_REQUEST,
        permission_key_string: 'VIEW_REQUEST',
      },
      {
        title: 'Hide Request',
        description: 'Allow user to hide PartsTrader requests.',
        permission_key: subComponentKeys[permissionKeys.PARTSTRADER].HIDE_REQUEST,
        permission_key_string: 'HIDE_REQUEST',
      },
      {
        title: 'Mark as quoted',
        description: 'Allow user to reply to mark PartsTrader requests as quoted.',
        permission_key: subComponentKeys[permissionKeys.PARTSTRADER].MARK_AS_QUOTED,
        permission_key_string: 'MARK_AS_QUOTED',
      },
    ],
  },
];
