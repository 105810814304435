import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';

const themeStyle = createTheme();
export const useStyles = makeStyles(() => ({
  tableHeadPrimaryCellStickyClass: {
    fontWeight: 'bold',
    width: '10em',
    padding: '10px 10px 10px 10px',
    whiteSpace: 'nowrap',
    // fontSize: '17px',
    color: '#145DA0',
    '& .MuiTableSortLabel-root': {
      color: '#145DA0',
    },
    position: 'sticky',
    top: 'auto',
    right: 0,
    background: 'white',
    marginTop: '0px',
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  tableHeadPrimaryCell: {
    padding: '10px 10px 10px 10px',
    whiteSpace: 'nowrap',
    // fontSize: '15px',
    color: '#145DA0',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '250%',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    '& .MuiTableSortLabel-root': {
      color: '#145DA0',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
  tableSortLabelClass: {
    '& .MuiTableSortLabel-icon': {
      color: '#145DA0 !important',
      fontWeight: '700',
    },
    [themeStyle.breakpoints.up('1900')]: {
      fontSize: '14px',
    },
    [themeStyle.breakpoints.down('1900')]: {
      fontSize: '12px',
    },
    [themeStyle.breakpoints.down('1450')]: {
      fontSize: '10px',
    },
  },
}));
