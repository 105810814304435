import { ImageList, ImageListItem } from '@material-ui/core';
import { useStyles } from '../../styles/common-style';
import SkeletonComponent from './skeleton-component';

const GallerySkeletonComponent = () => {
  const styleClasses = useStyles();
  return (
    <ImageList className={styleClasses.imageListClass}>
      <ImageListItem>
        <SkeletonComponent variant="rectangular" width="100%" height="100%" />
      </ImageListItem>
      <ImageListItem>
        <SkeletonComponent variant="rectangular" width="100%" height="100%" />
      </ImageListItem>
      <ImageListItem>
        <SkeletonComponent variant="rectangular" width="100%" height="100%" />
      </ImageListItem>
      <ImageListItem>
        <SkeletonComponent variant="rectangular" width="100%" height="100%" />
      </ImageListItem>
      <ImageListItem>
        <SkeletonComponent variant="rectangular" width="100%" height="100%" />
      </ImageListItem>
      <ImageListItem>
        <SkeletonComponent variant="rectangular" width="100%" height="100%" />
      </ImageListItem>
    </ImageList>
  );
};

export default GallerySkeletonComponent;
