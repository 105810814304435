/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [],
  cartAddItems: [],
  editPageCartItems: [],
  editDBCartItems: {},
  removeDBCartItems: [],
  formData: {},
  searchData: {},
  tableAttributes: {},
  workOrderTableVariable: [],
  isShouldChange: '',
};

const slice = createSlice({
  name: 'workorder',
  initialState,
  reducers: {
    setCartItems(state, action) {
      state.cartItems = action.payload;
    },
    setEditPageCartItems(state, action) {
      state.editPageCartItems = action.payload;
    },
    setFormData(state, action) {
      state.formData = action.payload;
    },
    setCartAddItems(state, action) {
      state.cartAddItems = action.payload;
    },
    setEditDBCartItems(state, action) {
      state.editDBCartItems = action.payload;
    },
    setRemoveDBCartItems(state, action) {
      state.removeDBCartItems = action.payload;
    },
    setSearchData(state, action) {
      state.searchData = action.payload;
    },
    setTableAttributes(state, action) {
      state.tableAttributes = action.payload;
    },
    setWorkOrderTableVariable(state, action) {
      state.workOrderTableVariable = action.payload;
    },
    setIsShouldChange(state, action) {
      state.isShouldChange = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setCartItemsRedux =
  (cartItems, flag = '') =>
  async (dispatch) => {
    if (flag !== '' && flag === 'edit') {
      dispatch(slice.actions.setEditPageCartItems([...cartItems]));
    } else {
      dispatch(slice.actions.setCartItems([...cartItems]));
    }
  };
export const setFormDataRedux = (formData) => async (dispatch) => {
  dispatch(slice.actions.setFormData({ ...formData, ...formData }));
};
export const setTableAttributes = (attributes) => async (dispatch) => {
  dispatch(slice.actions.setTableAttributes({ ...attributes, ...attributes }));
};
export const setSearchData = (searchData) => async (dispatch) => {
  dispatch(slice.actions.setSearchData({ ...searchData, ...searchData }));
};
export const setCartAddItemsRedux = (cartItems) => async (dispatch) => {
  dispatch(slice.actions.setCartAddItems([...cartItems]));
};
export const setEditDBCartItems = (cartItems) => async (dispatch) => {
  dispatch(slice.actions.setEditDBCartItems(cartItems));
};
export const setRemoveDBCartItems = (cartItems) => async (dispatch) => {
  dispatch(slice.actions.setRemoveDBCartItems(cartItems));
};
export const setWorkOrderTableVariable = (variable) => async (dispatch) => {
  dispatch(slice.actions.setWorkOrderTableVariable(variable));
};
export const setIsShouldChange = (view) => async (dispatch) => {
  dispatch(slice.actions.setIsShouldChange(view));
};

export default slice;
