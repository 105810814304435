import { useMixpanel } from 'react-mixpanel-browser';

const useMixPanelTrack = () => {
  const mixpanel = useMixpanel();

  const setMixPanelTrack = (payload) => {
    if (mixpanel.config.token) {
      mixpanel.track(payload.eventName, '');
      if (payload.eventName === 'Logout') {
        mixpanel.reset();
      }
    }
  };

  const setMixPanelTimeTrack = (payload) => {
    if (mixpanel.config.token) {
      mixpanel.time_event(payload.eventName);
    }
  };

  const setMixPanelRegisterUser = (payload) => {
    if (mixpanel.config.token) {
      const mixPanelPayload = {
        $first_name: payload?.userData?.attributes?.name || '',
        $last_name: payload?.userData?.attributes?.middle_name || '',
        $email: payload?.userData?.attributes?.email || '',
        $gender: payload?.userData?.attributes?.gender || '',
        $phone: payload?.userData?.attributes?.phone_number || '',
        'User Role': payload.userData?.attributes['custom:user_type'],
        'Birth Date': payload?.userData?.birthdate || '',
        'Tenant-City': payload?.tenantData?.City || '',
        'Tenant-Contract': payload?.tenantData?.Contract || '',
        'Tenant-Country Code': payload?.tenantData?.CountryCode || '',
        'Tenant-DeleteStatus': payload?.tenantData?.DeleteStatus || '',
        'Tenant-State': payload?.tenantData?.State || '',
        'Tenant-SubscriptionTierID': payload?.tenantData?.SubscriptionTierID || '',
        'Tenant-SuperAdminEmail': payload?.tenantData?.SuperAdminEmail || '',
        'Tenant-SuperAdminFirstName': payload?.tenantData?.SuperAdminFirstName || '',
        'Tenant-SuperAdminLastName': payload?.tenantData?.SuperAdminLastName || '',
        'Tenant-SuperAdminPhoneNumber': payload?.tenantData?.SuperAdminPhoneNumber || '',
        'Tenant-TenantBusinessAddress': payload?.tenantData?.TenantBusinessAddress || '',
        'Tenant-TenantBusinessBrand': payload?.tenantData?.TenantBusinessBrand || '',
        'Tenant-TenantBusinessEmail': payload?.tenantData?.TenantBusinessEmail || '',
        'Tenant-TenantBusinessID': payload?.tenantData?.TenantBusinessID || '',
        'Tenant-TenantBusinessName': payload?.tenantData?.TenantBusinessName || '',
        'Tenant-TenantBusinessPhoneNumber': payload?.tenantData?.TenantBusinessPhoneNumber || '',
        'Tenant-TenantID': payload?.tenantData?.TenantID || '',
        'Tenant-TenantSalesEmail': payload?.tenantData?.TenantSalesEmail || '',
        'Tenant-TenantSalesNumber': payload?.tenantData?.TenantSalesNumber || '',
        'Tenant-TrialStatus': payload?.tenantData?.TrialStatus || '',
        'Tenant-XeroClientID': payload?.tenantData?.XeroClientID || '',
        'Tenant-XeroClientSecret': payload?.tenantData?.XeroClientSecret || '',
        'Tenant-XeroTenantID': payload?.tenantData?.XeroTenantID || '',
        'Tenant-ZipCode': payload?.tenantData?.ZipCode || '',
      };
      mixpanel.identify(payload.userData.attributes.sub);
      mixpanel.track('Login');
      mixpanel.people.set_once(mixPanelPayload);
    }
  };

  return { setMixPanelTrack, setMixPanelRegisterUser, setMixPanelTimeTrack };
};

export default useMixPanelTrack;
