/* eslint-disable import/no-unresolved */
import React, { memo, useState } from 'react';
import { FormControl, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getIn } from 'formik';
import TooltipComponent from 'components/tooltip-component';
import useCommonFunctionHook from '../../../hooks/common-function-hook';
import {
  StyledAutocompleteField,
  StyledErrorFormLabel,
  StyledFormLabel,
  StyledTextField,
  useAutocompleteStyles,
} from './styled';

const AutocompleteInput = ({
  extractLabel = (option) => option.label,
  label,
  optionalLabel,
  options,
  field,
  form: { setFieldValue, errors, touched },
  required,
  placeholder,
  onChangeFun,
  loading,
  helperText,
  tooltipText,
  ...rest
}) => {
  const isMobileScreen = useMediaQuery('(max-width:480px)');
  const styleClasses = useAutocompleteStyles();
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);
  const { generateUniqueString } = useCommonFunctionHook();
  const [tooltipState, setTooltipState] = useState(false);
  const toolTipHandleFun = (flag) => {
    if (!isMobileScreen) {
      setTooltipState(flag);
    }
  };
  return (
    <FormControl fullWidth>
      {label && !isError && (
        <StyledFormLabel required={required}>
          {label} {optionalLabel}
        </StyledFormLabel>
      )}
      {label && isError && (
        <StyledErrorFormLabel required={required}>
          {label} {optionalLabel}
        </StyledErrorFormLabel>
      )}
      <TooltipComponent open={tooltipState} placement="left" title={tooltipText}>
        <StyledAutocompleteField
          {...field}
          fullWidth
          classes={{
            paper: styleClasses.borderDropdownClass,
          }}
          options={options}
          getOptionLabel={(option) => extractLabel(option) || option}
          noOptionsText="No options"
          onChange={(e, newValue) => {
            setFieldValue(field.name, newValue);
            if (typeof onChangeFun === 'function') {
              onChangeFun(newValue);
            }
          }}
          isOptionEqualToValue={(option, value) => value !== undefined && value !== '' && option.label === value.label}
          loading={loading}
          renderOption={(props, option) => (
            <li {...props} key={`${field.name}-${option?.id || ''}-${generateUniqueString()}`}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder={placeholder}
              fullWidth
              error={isError}
              helperText={isError ? errorMessage : helperText}
              onBlur={() => {
                toolTipHandleFun(false);
              }}
              onFocus={() => {
                toolTipHandleFun(true);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...rest}
        />
      </TooltipComponent>
    </FormControl>
  );
};

export default memo(AutocompleteInput);
