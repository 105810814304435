import React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../../styles/common-style';
import CardComponent from '../Card';
import SkeletonComponent from './skeleton-component';

const AddEditFormOpenCardComponent = () => {
  const styleClasses = useStyles();
  return (
    <CardComponent
      title="Card Title"
      titleClass={styleClasses.cardTitleClass}
      className={styleClasses.cardV2}
      cardContentClassProp={styleClasses.cardContentZeroMarginClass}
      viewDataLoader
    >
      <Table className={styleClasses.tableHoverClass}>
        <TableBody>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography className={styleClasses.typographySubtitleClass}>Description</Typography>
                  <Typography style={{ fontWeight: 600 }}>Description</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
          <TableRow className={styleClasses.tableRowLastCell}>
            <TableCell width="30%" className={styleClasses.tableValignTopCell}>
              <>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              </>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardComponent>
  );
};

export default AddEditFormOpenCardComponent;
