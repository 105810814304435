import React from 'react';
import PropTypes from 'prop-types';

import '../css/loader.css';

const Loader = (props) => {
  const { stateLoader } = props;
  if (stateLoader) {
    return (
      <div className="loader-wrapper">
        <div className="loader" />
      </div>
    );
  }
  return '';
};

Loader.propTypes = {
  stateLoader: PropTypes.bool,
};

export default Loader;
