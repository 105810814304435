/* eslint-disable import/no-unresolved */
import React from 'react';
import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import { ArrowCircleDownTwoTone } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useStyles } from 'components/Table/TableSortHeader/styled';

const TableSortHeader = (props) => {
  const styleClasses = useStyles();
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (event, property) => {
    onRequestSort(event, property);
  };

  return (
    <TableRow>
      {headCells
        .filter((headCell) => headCell.isVisible)
        .map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell?.width || ''}
            className={
              headCell.id === 'Actions'
                ? styleClasses.tableHeadPrimaryCellStickyClass
                : styleClasses.tableHeadPrimaryCell
            }
          >
            {headCell?.sortable === true && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={(e) => createSortHandler(e, headCell.id)}
                IconComponent={ArrowCircleDownTwoTone}
                color="#145DA0"
                classes={{
                  root: styleClasses.tableSortLabelClass,
                }}
              >
                {headCell.label}
              </TableSortLabel>
            )}
            {headCell?.sortable === false && headCell.label}
          </TableCell>
        ))}
    </TableRow>
  );
};

TableSortHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array,
};

export default TableSortHeader;
