import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/common-style';
import { SkeletonComponent } from '../Skeleton';

const CardComponent = ({
  title,
  children,
  className,
  alignTitle,
  style,
  isFilter,
  titleClass,
  cardContentClassProp,
  viewDataLoader = false,
}) => {
  const styleClasses = useStyles();
  let cardContentClass = styleClasses.addButtonCardContent;
  if (isFilter && typeof isFilter !== 'undefined') {
    cardContentClass = styleClasses.filterCardContent;
  }
  if (cardContentClassProp && typeof cardContentClassProp !== 'undefined') {
    cardContentClass = cardContentClassProp;
  }
  return (
    <Card style={style} className={className}>
      {title && (
        <CardHeader
          title={
            <>
              {viewDataLoader === true && (
                <SkeletonComponent width="100%">
                  <Typography>.</Typography>
                </SkeletonComponent>
              )}
              {viewDataLoader === false && title}
            </>
          }
          align={alignTitle || ''}
          className={titleClass || styleClasses.cardHeaderTitle}
        />
      )}
      <CardContent className={cardContentClass}>{children}</CardContent>
    </Card>
  );
};

CardComponent.propTypes = {
  title: PropTypes.string,
  className: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.any,
  alignTitle: PropTypes.string,
  isFilter: PropTypes.bool,
  titleClass: PropTypes.any,
  cardContentClassProp: PropTypes.any,
  viewDataLoader: PropTypes.bool,
};

export default CardComponent;
