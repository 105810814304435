import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import SplashScreen from './components/splash-screen-component';
import useAuth from './hooks/use-auth';
import useScrollReset from './hooks/use-scroll-reset';
import useSettings from './hooks/use-settings';
import Layout from './layouts/layout';
import ErrorPage from './pages/error/error-page';
import routes from './routes';
import { createCustomTheme } from './theme';

import './css/custom.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'some',
});

const tokenData = JSON.parse(localStorage.getItem('accessToken'));

const replay = new Sentry.Replay();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), replay],
  tracesSampleRate: 1.0,
  beforeSend(event) {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        name: tokenData?.user_name || '',
        email: tokenData?.user_email || '',
      });
    }
    return event;
  },
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: `${process.env.REACT_APP_SENTRY_PROJECT_NAME}@${process.env.REACT_APP_PROJECT_VERSION}`,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        <Sentry.ErrorBoundary
          // eslint-disable-next-line react/no-unstable-nested-components
          fallback={({ error }) => (
            <Layout errorState={false}>
              <ErrorPage error={error} />
            </Layout>
          )}
        >
          {auth.isInitialized ? content : <SplashScreen />}
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
