/* eslint-disable import/no-unresolved */
import React from 'react';
import { Box, Grid, Pagination, TablePagination } from '@material-ui/core';
import useTableSort from 'hooks/useTableSort';
import PropTypes from 'prop-types';
import { useStyles } from 'components/Table/CardViewPagination/styled';

const CardViewPagination = ({ totalBaseRecords, totalRecords, handlePageChange, handleLimitChange, page, limit }) => {
  const styleClasses = useStyles();
  const { tableRowsLimit } = useTableSort();
  return (
    <Box className={styleClasses.secondCard}>
      <Grid container spacing={3} display="flex" direction="row" alignItems="center" justifyContent="left">
        <Grid
          item
          md={9}
          lg={7}
          xl={8}
          display="flex"
          justifyContent="right"
          alignItems="center"
          textAlign="right"
          className={styleClasses.cardPages}
        >
          <TablePagination
            component="div"
            count={totalBaseRecords}
            onPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            onRowsPerPageChange={handleLimitChange}
            showFirstButton={false}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
            showLastButton={false}
            rowsPerPageOptions={tableRowsLimit()}
          />
        </Grid>
        <Grid
          item
          md={3}
          lg={5}
          xl={4}
          display="flex"
          justifyContent="right"
          alignItems="right"
          textAlign="right"
          className={styleClasses.cardPagination}
        >
          <Pagination
            defaultPage={page + 1}
            onChange={handlePageChange}
            count={totalRecords}
            color="primary"
            variant="outlined"
            size="medium"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

CardViewPagination.propTypes = {
  totalRecords: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleLimitChange: PropTypes.func,
  page: PropTypes.number,
  limit: PropTypes.number,
  totalBaseRecords: PropTypes.number,
};

export default CardViewPagination;
