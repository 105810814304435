/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Navigate, useLocation } from 'react-router-dom';
import { boot as bootIntercom, load as loadIntercom } from 'intercom';
import PropTypes from 'prop-types';
import CheckPermissions from '../hooks/check-permissions';
import useAuth from '../hooks/use-auth';
import Login from '../pages/authentication/login';
import { setUserIdleState } from '../slices/user-activity-reducer';
import { useDispatch } from '../store';

const AuthGuard = (props) => {
  const { children, modulename, permission, subModule } = props;
  const { isAuthenticated, logout, user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const accesstokenData = JSON.parse(window.localStorage.getItem('accessToken'));
  const accesstokenDataState =
    accesstokenData !== null && Object.keys(accesstokenData).length > 0 ? accesstokenData?.isExpire || true : true;

  const handleOnIdle = () => {
    if (!accesstokenDataState) {
      dispatch(setUserIdleState(true));
    }
  };
  const handleOnActive = () => {
    if (!accesstokenDataState) {
      dispatch(setUserIdleState(false));
    }
  };
  useIdleTimer({
    timeout: 5000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  if (accesstokenData === null || Object.keys(accesstokenData).length === 0) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    if (isAuthenticated) {
      logout();
      dispatch({ type: 'SIGNOUT_REQUEST' });
    }
    return <Login />;
  }

  if (typeof modulename !== 'undefined' && modulename !== '') {
    const moduleNameL = modulename;
    const subModuleL = typeof subModule !== 'undefined' ? subModule : '';
    const permissionL = typeof permission !== 'undefined' ? permission : '';
    const checkPermisson = CheckPermissions(moduleNameL, subModuleL, permissionL);
    if (checkPermisson === 'upgrade') {
      return <Navigate to="/upgrade-plan" />;
    }
    if (checkPermisson === true) {
      return <Navigate to="/unauthorized" />;
    }
    if (checkPermisson === '') {
      return <Navigate to="/unauthorized" />;
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  loadIntercom();
  const intercomUser = Promise.resolve({
    email: user?.email || '',
    name: user?.name || '',
    created_at: new Date().getTime() / 1000,
  });
  bootIntercom(intercomUser);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  modulename: PropTypes.string,
  permission: PropTypes.string,
  subModule: PropTypes.string,
};

export default AuthGuard;
