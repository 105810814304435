/* eslint-disable react/jsx-props-no-spreading */
import { Skeleton } from '@material-ui/core';
import PropTypes from 'prop-types';

const SkeletonComponent = (props) => {
  const { children } = props;
  return (
    <Skeleton animation="wave" {...props}>
      {children}
    </Skeleton>
  );
};

SkeletonComponent.propTypes = {
  props: PropTypes.any,
  children: PropTypes.any,
};

export default SkeletonComponent;
