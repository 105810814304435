/* eslint-disable import/no-unresolved */
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from 'styles/common-style';
import SkeletonComponent from 'components/Skeleton/skeleton-component';

const TableListSkeletonComponent = () => {
  const styleClasses = useStyles();
  return (
    <Box className={styleClasses.skeletonTableListV2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
            <TableCell>
              <SkeletonComponent width="100%">
                <Typography variant="body2">.</Typography>
              </SkeletonComponent>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default TableListSkeletonComponent;
