/* eslint-disable import/no-unresolved */
/* eslint-disable no-plusplus */
/* eslint-disable no-else-return, react/no-array-index-key */
import React, { useState } from 'react';
// import SwiperComponent from '../swiper-component';
import Lightbox from 'react-image-lightbox';
import { useNavigate } from 'react-router-dom';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Box, Button, Checkbox, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PrintQrCode from 'features/Location/print-qr-code';
import { getStatusLabel } from 'hooks/status-map';
import useCommonFunctionHook from 'hooks/useCommonFunctionHook';
import useLocalTimeZone from 'hooks/useLocalTimeZone';
import useMixPanelTrack from 'hooks/useMixPanelTrack';
// import Label from '../label-component';
import ArrowRightIcon from 'icons/arrow-right';
import moment from 'moment';
import PropTypes from 'prop-types';
import { setBreadCrumbArr } from 'slices/getMasterState';
import { setViewPartMode } from 'slices/part-reducer';
import { setViewVehicleMode } from 'slices/vehicle-reducer';
import { permissionKeys, subComponentKeys } from 'static/permissions';
import { useStyles } from 'components/Table/TableBody/styled';
import { useDispatch, useSelector } from 'store';

const TableBody = ({
  listData,
  variableColumnArr,
  variableColumnArrLocalCount,
  listImageArr,
  premission,
  handleParentVehiclePartStat,
  isSearch,
  handleRemoveCartItem,
  handleAddCartItem,
  cartItemsLocal,
  editPermission,
  deletePermission,
  printPermission,
  editFunction,
  deleteFunction,
  componentRef,
  redirectBack,
  redirectBackState,
  handleHideRequestFunc,
  hiddenDataKey,
  redirectBackTitle,
}) => {
  const { getArrivalFlag, getDaysFromDate, getLocalDateTime } = useLocalTimeZone();
  const { tenantLogo } = useSelector((state) => state.tenant);
  const { breadCrumbArr } = useSelector((state) => state.master);
  const { openInNewTab } = useCommonFunctionHook();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoIndexId, setPhotoIndexId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { setMixPanelTrack } = useMixPanelTrack();

  const styleClasses = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const getFlagColumn = (tableId) => {
    const cellArr = variableColumnArr.find((x) => x.id === tableId);
    if (cellArr && cellArr.isVisible) {
      return true;
    }
    return false;
  };

  const handleImagePreviewPopup = (idKey) => {
    setIsOpen(true);
    setPhotoIndex(0);
    setPhotoIndexId(idKey);
  };

  return (
    <>
      {listData &&
        listData?.length > 0 &&
        listData?.map((list, index) => (
          <React.Fragment key={`common-table-body-index-${index}`}>
            <TableRow hover>
              {variableColumnArr.map((column, subIndex) => (
                <React.Fragment key={`variable-column-map-filter-${subIndex}`}>
                  {getFlagColumn(column.id) && (
                    <>
                      {column.type === 'label' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {list[column.id] && (
                            <Typography color="primary" className={styleClasses.labelClass} align="center">
                              {typeof column.prefix !== 'undefined' && (column?.prefix || '')}
                              {list[column.id]}
                            </Typography>
                          )}
                        </TableCell>
                      )}
                      {column.type === 'date' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const alias =
                              typeof column.as !== 'undefined' && column.as !== '' ? list[column.as] : list[column.id];
                            return alias && alias !== '0000-00-00 00:00:00'
                              ? moment(alias, 'YYYY-MM-DD hh:ii:ss').format('DD/MM/YYYY')
                              : '-';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'dateWithLable' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const alias =
                              typeof column.as !== 'undefined' && column.as !== '' ? list[column.as] : list[column.id];
                            return (
                              <Typography className={styleClasses.labelClass} align="center">
                                {alias && alias !== '0000-00-00 00:00:00'
                                  ? moment(alias, 'YYYY-MM-DD hh:ii:ss').format('ddd, h:ssA')
                                  : '-'}
                              </Typography>
                            );
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'amount' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {typeof column.prefix !== 'undefined' && (column?.prefix || '')}
                          {list[column.id] ? parseFloat(list[column.id] || '0', 10).toFixed(2) : '0.00'}
                        </TableCell>
                      )}
                      {column.type === 'sequence' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {index + 1}
                        </TableCell>
                      )}
                      {column.type === 'linkText' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const viewId = variableColumnArr.find(
                              (item) => typeof item.isViewId !== 'undefined' && item.isViewId === true,
                            );
                            let queryString = '?';
                            if (typeof viewId.queryString !== 'undefined' && Array.isArray(viewId.queryString)) {
                              viewId.queryString.map((qryArr) => {
                                const currentEle = qryArr;
                                if (typeof currentEle.isDynamic !== 'undefined' && currentEle.isDynamic === true) {
                                  Object.keys(currentEle).map((obj) => {
                                    if (obj !== 'isDynamic') {
                                      queryString += `&${obj}=${list[currentEle[obj]]}`;
                                    }
                                    return obj;
                                  });
                                }
                                if (typeof currentEle.isDynamic !== 'undefined' && currentEle.isDynamic === false) {
                                  Object.keys(currentEle).map((obj) => {
                                    if (obj !== 'isDynamic') {
                                      queryString += `&${obj}=${currentEle[obj]}`;
                                    }
                                    return obj;
                                  });
                                }
                                return qryArr;
                              });
                            }
                            if (list[column.id]) {
                              return (
                                <Button
                                  variant="contained"
                                  className={styleClasses.linkTextActionButtonClass}
                                  onClick={() => {
                                    const columnFrom = variableColumnArr.find(
                                      (item) => typeof item.type !== 'undefined' && item.type === 'action',
                                    );
                                    if (
                                      typeof columnFrom !== 'undefined' &&
                                      columnFrom.from === 'vehicle' &&
                                      breadCrumbArr.length > 8
                                    ) {
                                      dispatch(
                                        setBreadCrumbArr([
                                          {
                                            title: 'Vehicle Search',
                                            path: '/search',
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                        ]),
                                      );
                                    } else if (
                                      typeof columnFrom !== 'undefined' &&
                                      columnFrom.from === 'part' &&
                                      breadCrumbArr.length > 8
                                    ) {
                                      dispatch(
                                        setBreadCrumbArr([
                                          {
                                            title: 'Part Search',
                                            path: '/search',
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                        ]),
                                      );
                                    } else if (
                                      typeof columnFrom !== 'undefined' &&
                                      columnFrom.from === 'eco_part' &&
                                      breadCrumbArr.length > 8
                                    ) {
                                      dispatch(
                                        setBreadCrumbArr([
                                          {
                                            title: 'Part Search',
                                            path: '/search',
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                          {
                                            title: 'Other Dismantler’s',
                                            path: '/search',
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                        ]),
                                      );
                                    } else if (
                                      typeof columnFrom !== 'undefined' &&
                                      columnFrom.from === 'eco_vehicle' &&
                                      breadCrumbArr.length > 8
                                    ) {
                                      dispatch(
                                        setBreadCrumbArr([
                                          {
                                            title: 'Vehicle Search',
                                            path: '/search',
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                          {
                                            title: 'Other Dismantler’s',
                                            path: '/search',
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                        ]),
                                      );
                                    } else {
                                      dispatch(
                                        setBreadCrumbArr([
                                          ...breadCrumbArr,
                                          {
                                            title: redirectBackTitle,
                                            path: redirectBack,
                                            isType: 'search',
                                            stateData: redirectBackState,
                                          },
                                        ]),
                                      );
                                    }
                                    history(`${column.viewPath}${list[column.id]}${queryString}`, {
                                      state: {
                                        redirectBack,
                                        stateData: redirectBackState,
                                        redirectFromW: redirectBack,
                                      },
                                    });
                                  }}
                                >
                                  {list[column.id]}
                                </Button>
                              );
                            }
                            return '';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'statusLabel' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {getStatusLabel(list[column.id], column.statusLabel)}
                        </TableCell>
                      )}
                      {column.type === 'local_date' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {getLocalDateTime(list[column.id], 'DD-MM-YYYY')}
                        </TableCell>
                      )}
                      {column.type === 'local_time' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {getLocalDateTime(list[column.id], 'HH:mm')}
                        </TableCell>
                      )}
                      {column.type === 'statusLabelWithLink' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const from = typeof column.from !== 'undefined' ? column.from : '';
                            if (typeof column.labels[list[column.id]] !== 'undefined' && from !== '') {
                              const { text, linkId, link, isLink } = column.labels[list[column.id]];
                              if (isLink === true) {
                                if (from === 'part') {
                                  return (
                                    <Box
                                      onClick={() =>
                                        history(`${link}${list[linkId]}`, {
                                          state: {
                                            redirectBack,
                                            stateData: redirectBackState,
                                            redirectFromW: redirectBack,
                                          },
                                        })
                                      }
                                      className={styleClasses.cursorPointor}
                                    >
                                      <Typography className={styleClasses.labelClass} align="center">
                                        {`${text}${list[linkId]}`}
                                      </Typography>
                                    </Box>
                                  );
                                }
                              } else if (isLink === false) {
                                if (from === 'part') {
                                  return (
                                    <Box className={styleClasses.cursorPointor}>
                                      <Typography className={styleClasses.labelClass} align="center">
                                        {text}
                                      </Typography>
                                    </Box>
                                  );
                                }
                              }
                            }
                            return '-';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'percentageStatusLabel' && (
                        <TableCell align="left" className={styleClasses.tableCell}>
                          {(() => {
                            const labelArray = typeof column.statusLabel !== 'undefined' ? column.statusLabel : [];
                            if (labelArray.length > 0) {
                              const { color } = labelArray.find(
                                (lbl) =>
                                  lbl.startRange < parseInt(list[column.id], 10) &&
                                  lbl.endRange >= parseInt(list[column.id], 10),
                              );
                              return (
                                <Typography className={styleClasses.labelClass} align="center" color={color}>
                                  {`${list[column.id]}%`}
                                </Typography>
                              );
                            }
                            return '-';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'deductAmount' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const dueAmountArr = [];
                            let dueAmount = 0;
                            if (typeof column.deductKeys !== 'undefined' && column.deductKeys.length > 0) {
                              for (let dIndex = 0; dIndex < column.deductKeys.length; dIndex++) {
                                dueAmountArr.push(parseFloat(list[column.deductKeys[dIndex]] || 0, 10));
                              }
                            }
                            if (dueAmountArr.length > 0) {
                              dueAmount = dueAmountArr.reduce((a, b) => a - b);
                            }
                            if (dueAmount > 0) {
                              return `${typeof column.prefix !== 'undefined' ? column?.prefix : ''}${dueAmount.toFixed(
                                2,
                              )}`;
                            }
                            return `${typeof column.prefix !== 'undefined' ? `${column?.prefix}0.00` : '0.00'}`;
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'dayCalculate' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const optionalDate = typeof column.optionalDate !== 'undefined' ? column.optionalDate : '';
                            let date = list[column.id] !== '' ? list[column.id] : '';
                            if (date === '' && optionalDate !== '') {
                              date = list[optionalDate];
                            }
                            return (
                              <Typography className={styleClasses.labelClass} align="center">
                                {getDaysFromDate(date, 'YYYY-MM-DD hh:mm:ss', 'YYYY-MM-DD hh:mm:ss')}
                              </Typography>
                            );
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'arrayFirstIndex' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const returnStringArray = [];
                            const columnKeysArr = typeof column.keys !== 'undefined' ? column.keys : [];
                            const betaData =
                              typeof list[column.id] !== 'undefined' && list[column.id].length > 0
                                ? list[column.id][0]
                                : {};
                            if (columnKeysArr.length > 0 && Object.keys(betaData).length > 0) {
                              for (let columnKeyIndex = 0; columnKeyIndex < columnKeysArr.length; columnKeyIndex++) {
                                returnStringArray.push(betaData[columnKeysArr[columnKeyIndex]]);
                              }
                            }
                            return returnStringArray.join(' ');
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'filterText' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const columnFilterArr = list[column.id];
                            return columnFilterArr
                              ? columnFilterArr.find((obj) => obj.Name === column.filterKey)?.Value
                              : '';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'filterLabel' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {(() => {
                            const columnFilterArr = list[column.id];
                            const filteredData = columnFilterArr
                              ? columnFilterArr.find((obj) => obj.Name === column.filterKey)?.Value
                              : '';
                            if (filteredData !== '') {
                              return (
                                <Typography className={styleClasses.labelClass} align="center">
                                  {filteredData}
                                </Typography>
                              );
                            }
                            return '';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'checkbox' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          <Checkbox checked={Boolean(list[column.id] || 0)} disabled />
                        </TableCell>
                      )}
                      {column.type === 'text' && (
                        <TableCell align="left" className={styleClasses.tableNoBreakCell}>
                          {list[column.id] || '-'}
                        </TableCell>
                      )}
                      {column.type === 'dateIsPastOrNot' && (
                        <TableCell align="left" className={styleClasses.tableCell}>
                          {(() => {
                            const arrivalFlag = getArrivalFlag(
                              list[column.id],
                              'YYYY-MM-DD hh:ii:ss',
                              'YYYY-MM-DD hh:ii:ss',
                            );
                            if (arrivalFlag) {
                              return (
                                <Typography color="success" align="center">
                                  Yes
                                </Typography>
                              );
                            }
                            return (
                              <Typography color="error" align="center">
                                No
                              </Typography>
                            );
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'tagsFromString' && (
                        <TableCell align="left" className={styleClasses.tableCell}>
                          {(() => {
                            const stringArray = list[column.id] ? list[column.id].split(column.splitFlag) : [];
                            return stringArray.map((tag) => (
                              <Typography
                                key={`list-tags-${tag}-${Math.random()}`}
                                className={styleClasses.labelClass}
                                align="center"
                              >
                                {tag}
                              </Typography>
                            ));
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'image' && (
                        <TableCell align="center" className={styleClasses.tableCell}>
                          {(() => {
                            const images = (list[column.id] ? list[column.id] : '').split(',');
                            const viewId = variableColumnArr.find(
                              (item) => typeof item.isViewId !== 'undefined' && item.isViewId === true,
                            );
                            if (images.length > 0) {
                              const idKey = list[viewId?.id];
                              if (typeof listImageArr !== 'undefined') {
                                // eslint-disable-next-line no-param-reassign
                                listImageArr[idKey] = images;
                              }
                            }
                            if (images[0] !== 'None' && images[0] !== '') {
                              return (
                                <IconButton onClick={() => handleImagePreviewPopup(list[viewId?.id])}>
                                  <img
                                    onError={(e) => {
                                      e.target.src = `${window.location.origin.toString()}/static/no_image_available.png`;
                                      e.target.alt = 'No Data';
                                    }}
                                    src={images[0]}
                                    alt={images[0]}
                                    className={styleClasses.tableListImageClass}
                                  />
                                </IconButton>
                              );
                            }
                            return '-';
                          })()}
                        </TableCell>
                      )}
                      {column.type === 'action' && (
                        <TableCell align="right" className={styleClasses.stickyTableCell}>
                          <div className={styleClasses.tableButtonActionCell}>
                            {(() => {
                              if (typeof premission !== 'undefined' && premission === false) {
                                let queryString = '?';
                                const viewId = variableColumnArr.find(
                                  (item) => typeof item.isViewId !== 'undefined' && item.isViewId === true,
                                );
                                if (typeof viewId.queryString !== 'undefined' && Array.isArray(viewId.queryString)) {
                                  viewId.queryString.map((qryArr) => {
                                    const currentEle = qryArr;
                                    if (typeof currentEle.isDynamic !== 'undefined' && currentEle.isDynamic === true) {
                                      Object.keys(currentEle).map((obj) => {
                                        if (obj !== 'isDynamic') {
                                          queryString += `&${obj}=${list[currentEle[obj]]}`;
                                        }
                                        return obj;
                                      });
                                    }
                                    if (typeof currentEle.isDynamic !== 'undefined' && currentEle.isDynamic === false) {
                                      Object.keys(currentEle).map((obj) => {
                                        if (obj !== 'isDynamic') {
                                          queryString += `&${obj}=${currentEle[obj]}`;
                                        }
                                        return obj;
                                      });
                                    }
                                    return qryArr;
                                  });
                                }
                                if (typeof column.from !== 'undefined' && column.from === 'vehicle') {
                                  if (
                                    typeof isSearch !== 'undefined' &&
                                    isSearch === false &&
                                    typeof handleParentVehiclePartStat === 'function'
                                  ) {
                                    return (
                                      <IconButton
                                        className={styleClasses.tableListIconButton}
                                        onClick={() => handleParentVehiclePartStat(list)}
                                      >
                                        <ArrowRightIcon fontSize="small" />
                                      </IconButton>
                                    );
                                  } else if (typeof isSearch !== 'undefined' && isSearch === true) {
                                    return (
                                      <>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          type="button"
                                          className={styleClasses.blueSearchSquareActionButton}
                                          onClick={() => {
                                            if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  ...breadCrumbArr,
                                                  {
                                                    title: redirectBackTitle,
                                                    path: redirectBack,
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            } else {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  {
                                                    title: 'Vehicle Search',
                                                    path: '/search',
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            }
                                            openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                          }}
                                        >
                                          <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          type="button"
                                          className={styleClasses.blueSearchSquareActionButton}
                                          onClick={() => {
                                            if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  ...breadCrumbArr,
                                                  {
                                                    title: redirectBackTitle,
                                                    path: redirectBack,
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            } else {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  {
                                                    title: 'Vehicle Search',
                                                    path: '/search',
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            }
                                            dispatch(setViewVehicleMode('vehicle'));
                                            history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`, {
                                              state: {
                                                redirectBack: typeof redirectBack !== 'undefined' ? redirectBack : '',
                                                stateData:
                                                  typeof redirectBackState !== 'undefined' ? redirectBackState : {},
                                              },
                                            });
                                          }}
                                        >
                                          <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                        </Button>
                                      </>
                                    );
                                  }
                                } else if (typeof column.from !== 'undefined' && column.from === 'eco_vehicle') {
                                  return (
                                    <>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                            dispatch(
                                              setBreadCrumbArr([
                                                ...breadCrumbArr,
                                                {
                                                  title: redirectBackTitle,
                                                  path: redirectBack,
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          } else {
                                            dispatch(
                                              setBreadCrumbArr([
                                                {
                                                  title: 'Vehicle Search',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                                {
                                                  title: 'Other Dismantler’s',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          }
                                          openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                            dispatch(
                                              setBreadCrumbArr([
                                                ...breadCrumbArr,
                                                {
                                                  title: redirectBackTitle,
                                                  path: redirectBack,
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          } else {
                                            dispatch(
                                              setBreadCrumbArr([
                                                {
                                                  title: 'Vehicle Search',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                                {
                                                  title: 'Other Dismantler’s',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          }
                                          history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`, {
                                            state: {
                                              redirectBack: typeof redirectBack !== 'undefined' ? redirectBack : '',
                                              stateData:
                                                typeof redirectBackState !== 'undefined' ? redirectBackState : {},
                                            },
                                          });
                                        }}
                                      >
                                        <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                    </>
                                  );
                                } else if (typeof column.from !== 'undefined' && column.from === 'part') {
                                  if (typeof isSearch !== 'undefined' && isSearch === false) {
                                    const findItem =
                                      typeof cartItemsLocal !== 'undefined' && cartItemsLocal.length > 0
                                        ? cartItemsLocal.map((cartItem) => cartItem.TagID).indexOf(list[viewId?.id])
                                        : -1;
                                    if (findItem !== -1 && typeof handleRemoveCartItem === 'function') {
                                      return (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className={styleClasses.cartCancelButton}
                                          onClick={() => handleRemoveCartItem(list[viewId?.id])}
                                        >
                                          Remove
                                        </Button>
                                      );
                                    }
                                    if (typeof handleAddCartItem === 'function') {
                                      return (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className={styleClasses.cartBlueButton}
                                          onClick={() =>
                                            handleAddCartItem({ ...list, isReady: 0, AccountType: 'Sales', Tax: 15 })
                                          }
                                        >
                                          Add
                                        </Button>
                                      );
                                    }
                                  } else if (typeof isSearch !== 'undefined' && isSearch === true) {
                                    if (
                                      typeof redirectBack !== 'undefined' &&
                                      typeof redirectBackState !== 'undefined'
                                    ) {
                                      return (
                                        <>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            type="button"
                                            className={styleClasses.blueSearchSquareActionButton}
                                            onClick={() => {
                                              if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                                dispatch(
                                                  setBreadCrumbArr([
                                                    ...breadCrumbArr,
                                                    {
                                                      title: redirectBackTitle,
                                                      path: redirectBack,
                                                      isType: 'search',
                                                      stateData: redirectBackState,
                                                    },
                                                  ]),
                                                );
                                              } else {
                                                dispatch(
                                                  setBreadCrumbArr([
                                                    {
                                                      title: 'Part Search',
                                                      path: '/search',
                                                      isType: 'search',
                                                      stateData: redirectBackState,
                                                    },
                                                  ]),
                                                );
                                              }
                                              openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                            }}
                                          >
                                            <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            type="button"
                                            className={styleClasses.blueSearchSquareActionButton}
                                            onClick={() => {
                                              if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                                dispatch(
                                                  setBreadCrumbArr([
                                                    ...breadCrumbArr,
                                                    {
                                                      title: redirectBackTitle,
                                                      path: redirectBack,
                                                      isType: 'search',
                                                      stateData: redirectBackState,
                                                    },
                                                  ]),
                                                );
                                              } else {
                                                dispatch(
                                                  setBreadCrumbArr([
                                                    {
                                                      title: 'Part Search',
                                                      path: '/search',
                                                      isType: 'search',
                                                      stateData: redirectBackState,
                                                    },
                                                  ]),
                                                );
                                              }
                                              dispatch(setViewPartMode('part-view'));
                                              history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`, {
                                                state: { redirectBack, stateData: redirectBackState },
                                              });
                                            }}
                                          >
                                            <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                          </Button>
                                        </>
                                      );
                                    }
                                    return (
                                      <>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          type="button"
                                          className={styleClasses.blueSearchSquareActionButton}
                                          onClick={() => {
                                            if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  ...breadCrumbArr,
                                                  {
                                                    title: redirectBackTitle,
                                                    path: redirectBack,
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            } else {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  {
                                                    title: 'Part Search',
                                                    path: '/search',
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            }
                                            openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                          }}
                                        >
                                          <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          type="button"
                                          className={styleClasses.blueSearchSquareActionButton}
                                          onClick={() => {
                                            if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  ...breadCrumbArr,
                                                  {
                                                    title: redirectBackTitle,
                                                    path: redirectBack,
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            } else {
                                              dispatch(
                                                setBreadCrumbArr([
                                                  {
                                                    title: 'Part Search',
                                                    path: '/search',
                                                    isType: 'search',
                                                    stateData: redirectBackState,
                                                  },
                                                ]),
                                              );
                                            }
                                            history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                          }}
                                        >
                                          <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                        </Button>
                                      </>
                                    );
                                  }
                                } else if (typeof column.from !== 'undefined' && column.from === 'eco_part') {
                                  return (
                                    <>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          if (column.isBreadCrumb === true && breadCrumbArr.length < 9) {
                                            dispatch(
                                              setBreadCrumbArr([
                                                ...breadCrumbArr,
                                                {
                                                  title: redirectBackTitle,
                                                  path: redirectBack,
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          } else {
                                            dispatch(
                                              setBreadCrumbArr([
                                                {
                                                  title: 'Part Search',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                                {
                                                  title: 'Other Dismantler’s',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          }
                                          openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          if (column.isBreadCrumb === true) {
                                            dispatch(
                                              setBreadCrumbArr([
                                                ...breadCrumbArr,
                                                {
                                                  title: redirectBackTitle,
                                                  path: redirectBack,
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          } else {
                                            dispatch(
                                              setBreadCrumbArr([
                                                {
                                                  title: 'Part Search',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                                {
                                                  title: 'Other Dismantler’s',
                                                  path: '/search',
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          }
                                          history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                    </>
                                  );
                                } else if (typeof column.from !== 'undefined' && column.from === 'parts_trader') {
                                  return (
                                    <>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => handleHideRequestFunc(list[viewId?.id], !list[hiddenDataKey])}
                                      >
                                        {list[hiddenDataKey] === false && (
                                          <VisibilityOffIcon htmlColor="#fff" fontSize="small" />
                                        )}
                                        {list[hiddenDataKey] === true && (
                                          <VisibilityIcon htmlColor="#fff" fontSize="small" />
                                        )}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          dispatch(
                                            setBreadCrumbArr([
                                              {
                                                title: 'PartsTrader',
                                                path: '/part-trader',
                                                isType: 'search',
                                                stateData: redirectBackState,
                                              },
                                            ]),
                                          );
                                          openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          dispatch(
                                            setBreadCrumbArr([
                                              {
                                                title: 'PartsTrader',
                                                path: '/part-trader',
                                                isType: 'search',
                                                stateData: redirectBackState,
                                              },
                                            ]),
                                          );
                                          history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                    </>
                                  );
                                } else if (typeof column.from !== 'undefined' && column.from === 'user') {
                                  return (
                                    <IconButton
                                      className={styleClasses.tableListIconButton}
                                      onClick={() =>
                                        history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`, {
                                          state: { user: list },
                                        })
                                      }
                                    >
                                      <ArrowRightIcon fontSize="small" />
                                    </IconButton>
                                  );
                                } else if (typeof column.from !== 'undefined' && column.from === 'location') {
                                  const editId = variableColumnArr.find(
                                    (item) => typeof item.isEditFlag !== 'undefined' && item.isEditFlag === true,
                                  );
                                  const deleteId = variableColumnArr.find(
                                    (item) => typeof item.isDeleteFlag !== 'undefined' && item.isDeleteFlag === true,
                                  );
                                  return (
                                    <>
                                      {typeof printPermission !== 'undefined' && (
                                        <>
                                          <ReactToPrint
                                            documentTitle={`QR Code Location #${list[viewId?.id]}`}
                                            content={() => componentRef.current[index]}
                                          >
                                            <PrintContextConsumer>
                                              {({ handlePrint }) => (
                                                <Button
                                                  color="primary"
                                                  variant="contained"
                                                  className={styleClasses.blueNoWrapActionButton}
                                                  onClick={() => {
                                                    setMixPanelTrack({
                                                      eventName: `${permissionKeys.LOCATION}-${
                                                        subComponentKeys[permissionKeys.LOCATION].PRINT_TAG
                                                      }`,
                                                    });
                                                    handlePrint();
                                                  }}
                                                  disabled={printPermission === true}
                                                  endIcon={printPermission === true ? <LockIcon /> : ''}
                                                >
                                                  Print Location Tag
                                                </Button>
                                              )}
                                            </PrintContextConsumer>
                                          </ReactToPrint>
                                          <div className={styleClasses.dNone}>
                                            <PrintQrCode
                                              tenantLogo={tenantLogo}
                                              Payload={list}
                                              // eslint-disable-next-line no-return-assign, no-param-reassign
                                              ref={(el) => (componentRef.current[index] = el)}
                                            />
                                          </div>
                                        </>
                                      )}
                                      {typeof editPermission !== 'undefined' && (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => editFunction(list[editId.id])}
                                          className={styleClasses.blueActionButton}
                                          disabled={editPermission === true}
                                          endIcon={editPermission === true ? <LockIcon /> : ''}
                                        >
                                          Edit
                                        </Button>
                                      )}
                                      {typeof deletePermission !== 'undefined' && (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => deleteFunction(list[deleteId.id])}
                                          className={styleClasses.blueActionLastButton}
                                          disabled={deletePermission === true}
                                          endIcon={deletePermission === true ? <LockIcon /> : ''}
                                        >
                                          Delete
                                        </Button>
                                      )}
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          openInNewTab(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <OpenInNewIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        className={styleClasses.blueSearchSquareActionButton}
                                        onClick={() => {
                                          if (column.isBreadCrumb === true) {
                                            dispatch(
                                              setBreadCrumbArr([
                                                ...breadCrumbArr,
                                                {
                                                  title: redirectBackTitle,
                                                  path: redirectBack,
                                                  isType: 'search',
                                                  stateData: redirectBackState,
                                                },
                                              ]),
                                            );
                                          }
                                          history(`${viewId.viewPath}${list[viewId?.id]}${queryString}`);
                                        }}
                                      >
                                        <ArrowForwardIcon htmlColor="#fff" fontSize="small" />
                                      </Button>
                                    </>
                                  );
                                }
                              }
                              return '-';
                            })()}
                          </div>
                        </TableCell>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </React.Fragment>
        ))}
      {listData.length === 0 && (
        <TableRow>
          <TableCell align="center" colSpan={variableColumnArrLocalCount}>
            <Typography>No Data Found.</Typography>
          </TableCell>
        </TableRow>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={listImageArr[photoIndexId][photoIndex]}
          nextSrc={listImageArr[photoIndexId][(photoIndex + 1) % listImageArr[photoIndexId].length]}
          prevSrc={
            listImageArr[photoIndexId][
              (photoIndex + listImageArr[photoIndexId].length - 1) % listImageArr[photoIndexId].length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + listImageArr[photoIndexId].length - 1) % listImageArr[photoIndexId].length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % listImageArr[photoIndexId].length)}
        />
      )}
      {/* <SwiperComponent
        isOpen={isOpen}
        imageArr={isOpen ? listImageArr[photoIndexId] : []}
        setIsOpen={setIsOpen}
      /> */}
    </>
  );
};

TableBody.propTypes = {
  listData: PropTypes.array,
  variableColumnArr: PropTypes.array,
  variableColumnArrLocalCount: PropTypes.number,
  listImageArr: PropTypes.array,
  premission: PropTypes.any,
  handleParentVehiclePartStat: PropTypes.func,
  isSearch: PropTypes.any,
  handleRemoveCartItem: PropTypes.func,
  handleAddCartItem: PropTypes.func,
  cartItemsLocal: PropTypes.any,
  editPermission: PropTypes.any,
  deletePermission: PropTypes.any,
  printPermission: PropTypes.any,
  editFunction: PropTypes.func,
  deleteFunction: PropTypes.func,
  componentRef: PropTypes.any,
  redirectBack: PropTypes.any,
  redirectBackState: PropTypes.any,
  handleHideRequestFunc: PropTypes.func,
  hiddenDataKey: PropTypes.any,
  redirectBackTitle: PropTypes.any,
};

export default TableBody;
