/* eslint-disable import/no-unresolved */
import React, { memo, useEffect, useState } from 'react';
import { FormControl, useMediaQuery } from '@mui/material';
import { getIn } from 'formik';
import TooltipComponent from 'components/tooltip-component';
import { StyledErrorFormLabel, StyledFormLabel, StyledTextField, useStyles } from './styled';

const Input = ({
  tooltipText,
  field,
  label,
  helperTexts,
  helperText,
  onFocusFun,
  type = 'text',
  form: { errors, touched, setFieldValue },
  required,
  multiline = false,
  ...props
}) => {
  const styleClasses = useStyles();
  const isMobileScreen = useMediaQuery('(max-width:480px)');
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);
  const [localStateVal, setLocalStateVal] = useState(field.value);
  const [tooltipState, setTooltipState] = useState(false);

  useEffect(() => {
    setLocalStateVal(field.value);
  }, [field.value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFieldValue(field.name, localStateVal);
    }, 200);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStateVal]);

  const toolTipHandleFun = (flag) => {
    if (!isMobileScreen) {
      setTooltipState(flag);
    }
  };

  return (
    <FormControl fullWidth>
      {label && !isError && (
        <StyledFormLabel required={required}>
          {label}
          {helperTexts && <span className={styleClasses.helperText}> {helperTexts}</span>}
        </StyledFormLabel>
      )}
      {label && isError && (
        <StyledErrorFormLabel required={required}>
          {label}
          {helperTexts && <span className={styleClasses.helperText}> {helperTexts}</span>}
        </StyledErrorFormLabel>
      )}
      <TooltipComponent open={tooltipState} placement="left" title={tooltipText}>
        <StyledTextField
          {...field}
          type={type}
          variant="outlined"
          value={localStateVal}
          error={isError}
          helperText={isError ? errorMessage : helperText}
          multiline={multiline}
          size="small"
          onChange={(e) => setLocalStateVal(e.target.value)}
          fullWidth
          onBlur={() => {
            // setFieldValue(field.name, e.target.value);
            toolTipHandleFun(false);
          }}
          onFocus={() => {
            if (typeof onFocusFun !== 'undefined') {
              onFocusFun();
            }
            toolTipHandleFun(true);
          }}
          {...props}
        />
      </TooltipComponent>
    </FormControl>
  );
};

export default memo(Input);
