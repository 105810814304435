import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Link, Typography } from '@material-ui/core';
import { Card as CardComponent } from '../../components';
import AlertContainerComponent from '../../components/alert-container-component';
import Logo from '../../components/logo-component';
import { PageContainer } from '../../components/page';
import { DesktopView, MobileView, TabletView } from '../../components/responsive-view-component';
import { LoginAmplify } from '../../features/authentication/login';
import { useStyles } from '../../styles/common-style';

const Login = () => {
  const styleClasses = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <AlertContainerComponent />
      <PageContainer maxWidth="sm" title="Login" isCenter="true">
        <Box className={styleClasses.logoContainer}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
        <CardComponent className={styleClasses.loginContent}>
          <DesktopView>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              textAlign="center"
              className={styleClasses.typography}
            >
              Welcome to Genius Inventory.
            </Typography>
          </DesktopView>
          <TabletView>
            <Typography color="textPrimary" gutterBottom variant="h4" textAlign="center">
              Welcome to Genius Inventory.
            </Typography>
          </TabletView>
          <MobileView>
            <Typography color="textPrimary" gutterBottom variant="h5" textAlign="center">
              Welcome to Genius Inventory.
            </Typography>
          </MobileView>
          <LoginAmplify />
          <Divider className={styleClasses.marginY} />
          <DesktopView>
            <Link
              color="textSecondary"
              component={RouterLink}
              className={styleClasses.typography}
              to="/authentication/password-recovery"
              variant="body2"
            >
              Forgot password
            </Link>
          </DesktopView>
          <TabletView>
            <Button
              color="primary"
              size="large"
              variant="contained"
              className={styleClasses.redButton}
              onClick={() => navigate('/authentication/password-recovery')}
            >
              Forgot Password
            </Button>
          </TabletView>
          <MobileView>
            <Button
              color="primary"
              size="large"
              fullWidth
              variant="contained"
              className={styleClasses.redButton}
              onClick={() => navigate('/authentication/password-recovery')}
            >
              Forgot Password
            </Button>
          </MobileView>
        </CardComponent>
      </PageContainer>
    </>
  );
};

export default Login;
