import { Zoom } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// eslint-disable-next-line react/jsx-props-no-spreading
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#F0F0F0',
      color: '#62727B',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
      padding: '4px',
      fontSize: '14px',
      fontWeight: '400',
    },
  }),
);

const TooltipComponent = ({ children, title, placement, open }) => (
  <LightTooltip placement={placement} open={open} TransitionComponent={Zoom} title={title}>
    {children}
  </LightTooltip>
);

TooltipComponent.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  placement: PropTypes.string,
  open: PropTypes.bool,
};

export default TooltipComponent;
