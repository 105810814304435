import customToolTip from './utils/chart-custom-tooltip';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const locationSearchWarehouseList = [
  { name: 'yes', label: 'Yes' },
  { name: 'no', label: 'No' },
];

export const userRoles = [{ name: 'Admin' }, { name: 'User' }];

export const userPositions = [{ name: 'Picker' }, { name: 'Manager' }, { name: 'Inventory' }, { name: 'Sales' }];

export const businesscustomerOptions = [
  { name: 'Yes', value: '1' },
  { name: 'No', value: '0' },
];

export const filterSectionStyles = {
  overflowY: 'auto',
  borderRadius: '0px 0px 0px 0px',
  width: '18%',
  flex: '1 1 auto',
};

export const defaultPeoplesObj = {
  first_name: '',
  last_name: '',
  email: '',
  include_email: [],
};

export const crushOptions = [
  { id: '1', name: 'Yes' },
  { id: '0', name: 'No' },
];

export const crushOptionsV2 = [
  { id: '1', label: 'Yes' },
  { id: '0', label: 'No' },
];

export const arrivedFlagOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

export const imageOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

export const isReplyOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

export const websiteOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

export const trademeOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

export const lastStockTakeOptions = [
  { label: '1 to 7 Days', value: [1, 7] },
  { label: '8 to 22 Days', value: [8, 22] },
  { label: '23 to 44 Days', value: [23, 44] },
  { label: '45 to 90 Days', value: [45, 90] },
  { label: '91 to 180 Days', value: [91, 180] },
  { label: '181 to 360 Days', value: [181, 360] },
  { label: 'Greater than 360 Days', value: [360, 'n'] },
];

export const ageOptions = [
  { label: '1 to 7 Days', value: [1, 7] },
  { label: '8 to 22 Days', value: [8, 22] },
  { label: '23 to 44 Days', value: [23, 44] },
  { label: '45 to 90 Days', value: [45, 90] },
  { label: '91 to 180 Days', value: [91, 180] },
  { label: '181 to 360 Days', value: [180, 360] },
  { label: 'Greater than 360 Days', value: [360, 'n'] },
];

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const stepsOfVehicle = [
  { label: 'Input REGO/VIN #', desc: 'Input REGO/VIN #' },
  { label: 'Edit vehicle information', desc: 'Edit vehicle info' },
];

export const workOrderStatus = [
  { value: 'DRAFT', text: 'Draft' },
  { value: 'TO-DO', text: 'To Do' },
  { value: 'IN-PROGRESS', text: 'In Progress' },
  { value: 'COMPLETE', text: 'Complete' },
];

export const stepsOfWorkOrder = [
  { label: 'In Progress', desc: 'Input Work Order Details' },
  { label: 'To Do', desc: 'Add Parts to Work Order' },
  { label: 'To Do', desc: 'Review Work Order' },
];

export const invoiceStatus = [
  { id: 1, text: 'Draft' },
  { id: 2, text: 'Awaiting Payment' },
  { id: 3, text: 'Paid' },
  { id: 4, text: 'Overdue' },
];

export const stepsOfInvoice = [
  { label: 'In Progress', desc: 'Input Invoice Details' },
  { label: 'To Do', desc: 'Add Parts to Invoice' },
  { label: 'To Do', desc: 'Review Invoice' },
];

export const accountTabs = [
  { label: 'General', value: 'general' },
  { label: 'Security', value: 'security' },
];

export const chartVerticleoptions = {
  responsive: true,
  legend: {
    display: false,
  },
  plugins: {
    title: {
      display: false,
      text: 'TOTAL SALES',
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: customToolTip,
    },
  },
};

export const chartHorizontalOptions = {
  responsive: true,
  maintainAspectRatio: true,
  indexAxis: 'y',
  scales: {
    y: {
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    title: {
      display: false,
      text: 'TOTAL SALES',
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: customToolTip,
    },
  },
};

export const monthArr = [
  {
    key: 1,
    month: 'Jan',
  },
  {
    key: 2,
    month: 'Feb',
  },
  {
    key: 3,
    month: 'Mar',
  },
  {
    key: 4,
    month: 'Apr',
  },
  {
    key: 5,
    month: 'May',
  },
  {
    key: 6,
    month: 'June',
  },
  {
    key: 7,
    month: 'July',
  },
  {
    key: 8,
    month: 'Aug',
  },
  {
    key: 9,
    month: 'Sept',
  },
  {
    key: 10,
    month: 'Oct',
  },
  {
    key: 11,
    month: 'Nov',
  },
  {
    key: 12,
    month: 'Dec',
  },
];

export const learnImagesArr = [
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-01.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-02.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-03.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-04.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-05.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-06.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-07.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-08.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-08.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-10.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-11.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-12.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-13.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-14.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-15.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-16.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-17.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-18.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-19.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-20.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-21.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-22.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-23.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-24.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-25.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-26.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-27.png`,
  `${process.env.REACT_APP_PUBLIC_URL}/static/learn/Users-Manual-2.0.0-28.png`,
];

export const restrictTanentIds = [1, 2];
