/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tenantData: {},
  tenantLogo: '',
  permissions: {},
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenantData(state, action) {
      state.tenantData = action.payload;
    },
    setTenantLogo(state, action) {
      state.tenantLogo = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setTenantData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setTenantData(payload));
};

export const setTenantLogo = (payload) => async (dispatch) => {
  dispatch(slice.actions.setTenantLogo(payload));
};

export const setPermissions = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPermissions(payload));
};

export default slice;
