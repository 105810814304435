import Api from '../utils/axios';

class TenantApi {
  constructor() {
    this.Axios = Api;
  }

  async getLogo() {
    return this.Axios.get('tenant/logo');
  }

  async getData() {
    return this.Axios.get('tenant');
  }

  async getPermissions() {
    return this.Axios.get('user/permission');
  }
}

export default new TenantApi();
