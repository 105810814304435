/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchCustomerData: {},
  customerTableAttributes: {},
};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setSearchData(state, action) {
      state.searchCustomerData = action.payload;
    },
    setCustomerTableAttributes(state, action) {
      state.customerTableAttributes = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setSearchData = (search) => async (dispatch) => {
  dispatch(slice.actions.setSearchData({ ...search, ...search }));
};

export const setCustomerTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setCustomerTableAttributes({ ...attibutes, ...attibutes }));
};

export default slice;
