/* eslint-disable no-param-reassign */
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { reducer as capricornReducer } from '../slices/capricorn-reducer';
import { reducer as customerReducer } from '../slices/customer-reducer';
import { reducer as dashboardReducer } from '../slices/dashboard-reducer';
import { reducer as historyReducer } from '../slices/history-reducer';
import { reducer as invoiceReducer } from '../slices/invoice-reducer';
import { reducer as loaderReducer } from '../slices/loader-reducer';
import { reducer as locationReducer } from '../slices/location-reducer';
import { reducer as masterReducer } from '../slices/master-reducer';
import { reducer as partReducer } from '../slices/part-reducer';
import { reducer as partTraderReducer } from '../slices/part-trader-reducer';
import { reducer as tenantReducer } from '../slices/tenant-reducer';
import { reducer as tradeMeReducer } from '../slices/trademe-reducer';
import { reducer as userActivityReducer } from '../slices/user-activity-reducer';
import { reducer as userReducer } from '../slices/user-reducer';
import { reducer as vehicleReducer } from '../slices/vehicle-reducer';
import { reducer as webshopReducer } from '../slices/webshop-reducer';
import { reducer as workorderReducer } from '../slices/workorder-reducer';

const appReducer = combineReducers({
  vehicle: vehicleReducer,
  part: partReducer,
  loader: loaderReducer,
  location: locationReducer,
  customer: customerReducer,
  userActivity: userActivityReducer,
  workorder: workorderReducer,
  invoice: invoiceReducer,
  tenant: tenantReducer,
  partTrader: partTraderReducer,
  capricorn: capricornReducer,
  user: userReducer,
  master: masterReducer,
  trademe: tradeMeReducer,
  webshop: webshopReducer,
  history: historyReducer,
  dashboard: dashboardReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
