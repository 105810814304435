/* eslint-disable import/no-unresolved */
import React from 'react';
import Lightbox from 'react-image-lightbox';
import { ImageList, ImageListItem } from '@mui/material';
import PropTypes from 'prop-types';
import Card from 'components/Card/index';
import { useStyles } from 'components/VehicleImagesGallery/styled';

const VehicleImagesGallery = ({
  vehicalDetailImage,
  handleImagePreviewPopup,
  photoIndex,
  setPhotoIndex,
  setImageIsOpen,
  imageIsOpen,
}) => {
  const styleClasses = useStyles();
  return (
    <Card className={styleClasses.secondCard}>
      <ImageList className={styleClasses.imageListCartClass}>
        {vehicalDetailImage.map((item, index) => (
          <ImageListItem key={item} role="button" onClick={() => handleImagePreviewPopup(index)}>
            <img src={item} srcSet={item} alt={item} loading="lazy" className={styleClasses.imageCartListItem} />
          </ImageListItem>
        ))}
        {imageIsOpen && (
          <Lightbox
            mainSrc={vehicalDetailImage[photoIndex]}
            nextSrc={vehicalDetailImage[(photoIndex + 1) % vehicalDetailImage.length]}
            prevSrc={vehicalDetailImage[(photoIndex + vehicalDetailImage.length - 1) % vehicalDetailImage.length]}
            onCloseRequest={() => setImageIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + vehicalDetailImage.length - 1) % vehicalDetailImage.length)
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % vehicalDetailImage.length)}
          />
        )}
      </ImageList>
    </Card>
  );
};

VehicleImagesGallery.propTypes = {
  vehicalDetailImage: PropTypes.array,
  handleImagePreviewPopup: PropTypes.func,
  photoIndex: PropTypes.number,
  setPhotoIndex: PropTypes.func,
  setImageIsOpen: PropTypes.func,
  imageIsOpen: PropTypes.bool,
};

export default VehicleImagesGallery;
