import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  lockedPageContainer: {
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center',
    // marginLeft: '10%',
  },
  lockedPageImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lockedTextClass: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '39px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#000',
  },
  lockedSpanGold: {
    color: '#B08D57',
    fontWeight: 'bold',
  },
  lockedSpanSilver: {
    color: '#C0C0C0',
    fontWeight: 'bold',
  },
}));
