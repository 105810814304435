/* eslint-disable no-underscore-dangle */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import Loadable from 'react-loadable';
import { MixpanelProvider } from 'react-mixpanel-browser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from './contexts/amplify-context';
import { RouterProvider } from './contexts/router-context';
import { SettingsProvider } from './contexts/settings-context';
import App from './App';
import { initStore } from './store';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';

const { store, persistor } = initStore(window.__SERVER_STATE__ || {});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 1000,
    },
  },
});

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const InitApp = () => (
  <StrictMode>
    <MixpanelProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <RouterProvider>
                      <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                          <IntercomProvider appId={INTERCOM_APP_ID}>
                            <App />
                          </IntercomProvider>
                        </AuthProvider>
                      </QueryClientProvider>
                    </RouterProvider>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </MixpanelProvider>
  </StrictMode>
);

const renderMethod = window.__SERVER_STATE__ ? ReactDOM.hydrate : ReactDOM.render;

const render = () => renderMethod(<InitApp />, document.querySelector('#root'));

Loadable.preloadReady().then(render);

ReactDOM.render(<InitApp />, document.getElementById('root'));
