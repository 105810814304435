/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import Amplify, { Auth } from 'aws-amplify';
import { boot as bootIntercom } from 'intercom';
import PropTypes from 'prop-types';
import tenantApi from '../api/tenant-api';
import { amplifyConfig } from '../config';
import useMixPanelTrack from '../hooks/mix-panel';
import { setPermissions, setTenantData, setTenantLogo } from '../slices/tenant-reducer';
import { useDispatch } from '../store';

Amplify.configure(amplifyConfig);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  VERIFY_MFA: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state }),
  UPDATEUSER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyMFA: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchCustom = useDispatch();
  const { setMixPanelRegisterUser } = useMixPanelTrack();
  let user = {};

  useEffect(() => {
    const initialize = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: currentUser.attributes.sub,
              userType: currentUser.attributes['custom:user_type'],
              avatar: '',
              email: currentUser.attributes.email,
              name: currentUser.attributes.given_name,
              firstName: currentUser.attributes.name,
              lastName: currentUser.attributes.middle_name,
              birthdate: currentUser.attributes.birthdate,
              gender: currentUser.attributes.gender,
              locale: currentUser.attributes.locale,
              phoneNumber: currentUser.attributes.phone_number,
              plan: 'Premium',
            },
          },
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const updateUser = async (obj) => {
    try {
      const users = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(users, obj);
      dispatch({
        type: 'UPDATEUSER',
        payload: {
          user: {
            id: users.attributes.sub,
            avatar: '',
            email: obj.email,
            name: obj.given_name,
            firstName: obj.name,
            lastName: obj.middle_name,
            birthdate: obj.birthdate,
            gender: obj.gender,
            locale: users.attributes.locale,
            phoneNumber: obj.phone_number,
            plan: 'Premium',
          },
        },
      });
      return users;
    } catch (err) {
      dispatch({
        type: 'UPDATEUSER',
        payload: {
          isAuthenticated: false,
          user,
        },
      });
      return err;
    }
  };

  const updatePassword = async ({ oldPassword, password }) => {
    try {
      const users = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(users, oldPassword, password);
      return users;
    } catch (err) {
      dispatch({
        type: 'UPDATEUSER',
        payload: {
          isAuthenticated: false,
          user,
        },
      });
      return err;
    }
  };

  const getTanentData = async (userData) => {
    try {
      const mixPanelPayload = {};
      mixPanelPayload.tenantData = await tenantApi.getData().then((res) => {
        dispatchCustom(setTenantData(res?.data[0] || {}));
        return res?.data[0] || {};
      });
      mixPanelPayload.tenantlogo = await tenantApi.getLogo().then((res) => {
        dispatchCustom(setTenantLogo(res?.data || {}));
        return res?.data || {};
      });
      mixPanelPayload.userPermission = await tenantApi.getPermissions().then((res) => {
        dispatchCustom(setPermissions(res?.data || {}));
        return res?.data || {};
      });
      mixPanelPayload.userData = userData;
      setMixPanelRegisterUser(mixPanelPayload);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const proceedLogin = async () => {
    const tokenData = {
      timeout: 1000 * 60 * 600,
      tenant_id: user.attributes['custom:tenant_id'],
      id: user.attributes.sub,
      isExpire: false,
      createdOn: new Date().getTime(),
      token: user.signInUserSession.idToken.jwtToken,
      user_name: user.attributes?.name || '',
      user_email: user.attributes?.email || '',
    };
    localStorage.setItem('accessToken', JSON.stringify(tokenData));
    await getTanentData(user);
    const intercomUser = await Promise.resolve({
      email: user.attributes?.email || '',
      name: user?.attributes?.given_name || '',
      created_at: new Date().getTime() / 1000,
    });
    bootIntercom(intercomUser);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.attributes.sub,
          userType: user.attributes['custom:user_type'],
          avatar: '',
          email: user.attributes.email,
          name: user.attributes.given_name,
          firstName: user.attributes.name,
          lastName: user.attributes.middle_name,
          birthdate: user.attributes.birthdate,
          gender: user.attributes.gender,
          locale: user.attributes.locale,
          phoneNumber: user.attributes.phone_number,
          plan: 'Premium',
        },
      },
    });
  };

  const addUser = async (obj) => {
    try {
      const res = await Auth.signUp({
        username: obj.email,
        password: obj.password,
        attributes: {
          email: obj.email,
          given_name: obj.given_name,
          name: obj.name,
          middle_name: obj.middle_name,
          birthdate: obj.birthdate,
          gender: obj.gender,
          family_name: obj.tenant_id,
          locale: 'en',
          phone_number: obj.phone_number,
          'custom:user_type': obj.user_role,
          'custom:tenant_id': obj.tenant_id,
        },
      });
      return res;
    } catch (err) {
      return { err: err.message };
    }
  };

  const login = async (email, password) => {
    user = await Auth.signIn(email, password);
    if (user.challengeName) {
      return user;
    }
    await proceedLogin(user);
    return user;
  };

  const logout = async () => {
    await Auth.signOut();
    localStorage.removeItem('accessToken');
    dispatch({
      type: 'LOGOUT',
    });
  };

  const register = async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email },
    });
    dispatch({
      type: 'REGISTER',
    });
  };

  const verifyMFA = async (code, mfaType) => {
    const userObj = await Auth.confirmSignIn(user, code, mfaType);
    await proceedLogin(user);
    return userObj;
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE',
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE',
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY',
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET',
    });
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyMFA,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        updateUser,
        updatePassword,
        addUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
