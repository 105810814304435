/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from '../styles/common-style';

const Scrollbar = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const styleClasses = useStyles();

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <Box ref={ref} className={styleClasses.overFlowXClass}>
        {children}
      </Box>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PerfectScrollbar ref={ref} {...other}>
      {children}
    </PerfectScrollbar>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node,
};

export default Scrollbar;
