/* eslint-disable import/no-unresolved */
/* eslint-disable react/display-name */
import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingScreen from './components/loading-screen-component';
import AuthGuard from './middlewares/auth-guard';
import GuestGuard from './middlewares/guest-guard';
import { permissionKeys, subComponentKeys } from './static/permissions';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload();
        }
        reject(error);
        return error;
      });
  });
};

// Layouts

const MainLayoutWithoutSidebar = Loadable(lazy(() => lazyRetry(() => import('./layouts/main-layout-without-sidebar'))));
const Layout = Loadable(lazy(() => lazyRetry(() => import('./layouts/layout'))));

// Authentication pages

const Login = Loadable(lazy(() => lazyRetry(() => import('./pages/authentication/login'))));
const PasswordRecovery = Loadable(lazy(() => lazyRetry(() => import('./pages/authentication/password-recovery'))));
const PasswordReset = Loadable(lazy(() => lazyRetry(() => import('./pages/authentication/password-reset'))));
const VerifyCode = Loadable(lazy(() => lazyRetry(() => import('./pages/authentication/verify-code'))));

// Dashboard pages

const Profile = Loadable(lazy(() => lazyRetry(() => import('./pages/profile/profile'))));
const Search = Loadable(lazy(() => lazyRetry(() => import('./pages/Search/search'))));
const AddVehiclePage = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/AddVehiclePage'))));
const EcoViewVehicle = Loadable(lazy(() => lazyRetry(() => import('./pages/eco-service/vehicle/EcoVehicleViewPage'))));
const ViewVehicleMasterPage = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/ViewVehicleMasterPage'))));
const VehicleAddPartPage = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/addPartPage'))));
const EditVehicle = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/edit-vehicle'))));
const EditVehiclePage = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/EditVehiclePage'))));
const VehicleAddPart = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/add-part'))));
const EditVehicleWebSite = Loadable(lazy(() => lazyRetry(() => import('./pages/vehicle/edit-vehicle-website'))));
const EditWebShopPage = Loadable(lazy(() => lazyRetry(() => import('./pages/webshop/EditWebShopPage'))));
const ListWorkOrder = Loadable(lazy(() => lazyRetry(() => import('./pages/work-order/list-work-order'))));
const User = Loadable(lazy(() => lazyRetry(() => import('./pages/user/user'))));
const ViewUser = Loadable(lazy(() => lazyRetry(() => import('./pages/user/view-user'))));
const EditUserPage = Loadable(lazy(() => lazyRetry(() => import('./pages/user/edit-user'))));
const AddUserPage = Loadable(lazy(() => lazyRetry(() => import('./pages/user/add-user'))));
const Location = Loadable(lazy(() => lazyRetry(() => import('./pages/location/list-location'))));
const CustomerList = Loadable(lazy(() => lazyRetry(() => import('./pages/customer/list-customer'))));
const AddCustomer = Loadable(lazy(() => lazyRetry(() => import('./pages/customer/add-customer'))));
const EditCustomer = Loadable(lazy(() => lazyRetry(() => import('./pages/customer/edit-customer'))));
const ViewCustomer = Loadable(lazy(() => lazyRetry(() => import('./pages/customer/view-customer'))));
const AddPartPage = Loadable(lazy(() => lazyRetry(() => import('./pages/part/AddPartPage'))));
// const ViewPartPage = Loadable(lazy(() => lazyRetry(() => import('./pages/part/ViewPartPage'))));
const ViewPartMasterPage = Loadable(lazy(() => lazyRetry(() => import('./pages/part/ViewPartMasterPage'))));
const EditPartPage = Loadable(lazy(() => lazyRetry(() => import('./pages/part/EditPartPage'))));
const EditPartWebSite = Loadable(lazy(() => lazyRetry(() => import('./pages/part/edit-part-website'))));
// const ViewPartEcoSystem = Loadable(lazy(() => lazyRetry(() => import('./pages/part/view-part-eco-system'))));
const EcoPartViewPage = Loadable(lazy(() => lazyRetry(() => import('./pages/eco-service/part/EcoPartViewPage'))));
const AddWorkOrder = Loadable(lazy(() => lazyRetry(() => import('./pages/work-order/add-work-order'))));
const ViewWorkOrder = Loadable(lazy(() => lazyRetry(() => import('./pages/work-order/view-work-order'))));
const EditWorkOrder = Loadable(lazy(() => lazyRetry(() => import('./pages/work-order/edit-work-order'))));
const ListInvoice = Loadable(lazy(() => lazyRetry(() => import('./pages/invoice/list-invoice'))));
const AddInvoice = Loadable(lazy(() => lazyRetry(() => import('./pages/invoice/add-invoice'))));
const EditInvoice = Loadable(lazy(() => lazyRetry(() => import('./pages/invoice/edit-invoice'))));
const ViewInvoice = Loadable(lazy(() => lazyRetry(() => import('./pages/invoice/view-invoice'))));
const Dashboard = Loadable(lazy(() => lazyRetry(() => import('./pages/Dashboard/DashboardPage'))));
const NotFound = Loadable(lazy(() => lazyRetry(() => import('./pages/error/not-found'))));
const Unauthorized = Loadable(lazy(() => lazyRetry(() => import('./pages/error/unauthorized'))));
const UpgradePlan = Loadable(lazy(() => lazyRetry(() => import('./pages/error/upgrade-plan'))));
const ListPartTrader = Loadable(lazy(() => lazyRetry(() => import('./pages/part-trader/list-part-trader'))));
const DetailsPartTrader = Loadable(lazy(() => lazyRetry(() => import('./pages/part-trader/details-part-trader'))));
const ListCapricorn = Loadable(lazy(() => lazyRetry(() => import('./pages/capricorn/list-capricorn'))));
const DetailsCapricorn = Loadable(lazy(() => lazyRetry(() => import('./pages/capricorn/details-capricorn'))));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: 'account',
        element: <Profile />,
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.DASHBOARD} permission="ACCESS">
                <Dashboard />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'search',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.SEARCH} permission="ACCESS">
                <Search />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'vehicles',
        children: [
          {
            path: 'add',
            element: (
              <AuthGuard modulename={permissionKeys.VEHICLE} permission="CREATE">
                <AddVehiclePage />
              </AuthGuard>
            ),
          },
          {
            path: 'part/add',
            element: (
              <AuthGuard modulename={permissionKeys.VEHICLE} permission="ADD_PART">
                <VehicleAddPart />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:stockId',
            element: (
              <AuthGuard modulename={permissionKeys.VEHICLE} permission="UPDATE">
                <EditVehiclePage />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:stockId',
            element: (
              <AuthGuard modulename={permissionKeys.VEHICLE} permission="VIEW">
                <ViewVehicleMasterPage />
              </AuthGuard>
            ),
          },
          {
            path: 'add-part/:stockId',
            element: (
              <AuthGuard modulename={permissionKeys.VEHICLE} permission="VIEW">
                <VehicleAddPartPage />
              </AuthGuard>
            ),
          },
          {
            path: 'eco/:stockId',
            element: (
              <AuthGuard
                modulename={permissionKeys.ECOSYSTEM}
                permission={subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.VEHICLE].VIEW}
                subModule={permissionKeys.VEHICLE}
              >
                <EcoViewVehicle />
              </AuthGuard>
            ),
          },
          {
            path: 'searchEdit',
            element: (
              <AuthGuard modulename={permissionKeys.VEHICLE} permission="UPDATE">
                <EditVehicle />
              </AuthGuard>
            ),
          },
          {
            path: 'upload-website',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].CREATE}
                subModule={permissionKeys.VEHICLE}
              >
                <EditVehicleWebSite />
              </AuthGuard>
            ),
          },
          {
            path: 'edit-website',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].UPDATE}
                subModule={permissionKeys.VEHICLE}
              >
                <EditVehicleWebSite />
              </AuthGuard>
            ),
          },
          {
            path: 'edit-website',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].UPDATE}
                subModule={permissionKeys.VEHICLE}
              >
                <EditVehicleWebSite />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'part-trader',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.PARTSTRADER} permission="VIEW_REQUEST">
                <ListPartTrader />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:partTraderID',
            element: (
              <AuthGuard modulename={permissionKeys.PARTSTRADER} permission="VIEW_REQUEST">
                <DetailsPartTrader />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.USER} permission="VIEW_USER_LIST">
                <User />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:userId',
            element: (
              <AuthGuard modulename={permissionKeys.USER} permission="VIEW_USER">
                <ViewUser />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:userId',
            element: (
              <AuthGuard modulename={permissionKeys.USER} permission="UPDATE_USER">
                <EditUserPage />
              </AuthGuard>
            ),
          },
          {
            path: 'add',
            element: (
              <AuthGuard modulename={permissionKeys.USER} permission="CREATE_USER">
                <AddUserPage />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'location',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.LOCATION} permission="VIEW">
                <Location />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'capricorn',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <ListCapricorn />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:requestID',
            element: (
              <AuthGuard>
                <DetailsCapricorn />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'work-order',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.WORKORDER} permission="VIEW">
                <ListWorkOrder />
              </AuthGuard>
            ),
          },
          {
            path: 'add',
            element: (
              <AuthGuard modulename={permissionKeys.WORKORDER} permission="CREATE">
                <AddWorkOrder />
              </AuthGuard>
            ),
          },
          {
            path: 'edit',
            element: (
              // <AuthGuard
              //   modulename={permissionKeys.WORKORDER}
              //   permission="UPDATE"
              // >
              <EditWorkOrder />
              // </AuthGuard>
            ),
          },
          {
            path: 'view/:workOrderID',
            element: (
              <AuthGuard modulename={permissionKeys.WORKORDER} permission="VIEW">
                <ViewWorkOrder />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'invoice',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.INVOICE} permission="VIEW">
                <ListInvoice />
              </AuthGuard>
            ),
          },
          {
            path: 'add',
            element: (
              <AuthGuard modulename={permissionKeys.INVOICE} permission="CREATE">
                <AddInvoice />
              </AuthGuard>
            ),
          },
          {
            path: 'edit',
            element: (
              <AuthGuard modulename={permissionKeys.INVOICE} permission="UPDATE">
                <EditInvoice />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:InvoiceID',
            element: (
              <AuthGuard modulename={permissionKeys.INVOICE} permission="VIEW">
                <ViewInvoice />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'contact',
        children: [
          {
            path: '',
            element: (
              <AuthGuard modulename={permissionKeys.CONTACT} permission="VIEW">
                <CustomerList />
              </AuthGuard>
            ),
          },
          {
            path: 'add',
            element: (
              <AuthGuard modulename={permissionKeys.CONTACT} permission="CREATE">
                <AddCustomer />
              </AuthGuard>
            ),
          },
          {
            path: 'edit',
            element: (
              <AuthGuard modulename={permissionKeys.CONTACT} permission="UPDATE">
                <EditCustomer />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:customerId',
            element: (
              <AuthGuard modulename={permissionKeys.CONTACT} permission="VIEW">
                <ViewCustomer />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'parts',
        children: [
          {
            path: 'new',
            element: (
              <AuthGuard modulename={permissionKeys.PART} permission="VIEW">
                <AddPartPage />
              </AuthGuard>
            ),
          },
          // {
          //   path: 'view/:tagId',
          //   element: (
          //     <AuthGuard modulename={permissionKeys.PART} permission="VIEW">
          //       <ViewPartPage />
          //     </AuthGuard>
          //   ),
          // },
          // {
          //   path: 'eco/:tagId',
          //   element: (
          //     <AuthGuard
          //       modulename={permissionKeys.ECOSYSTEM}
          //       permission={subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.PART].VIEW}
          //       subModule={permissionKeys.PART}
          //     >
          //       <ViewPartEcoSystem />
          //     </AuthGuard>
          //   ),
          // },
          {
            path: 'eco/:tagId',
            element: (
              <AuthGuard
                modulename={permissionKeys.ECOSYSTEM}
                permission={subComponentKeys[permissionKeys.ECOSYSTEM][permissionKeys.PART].VIEW}
                subModule={permissionKeys.PART}
              >
                <EcoPartViewPage />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:tagId',
            element: (
              <AuthGuard modulename={permissionKeys.PART} permission="UPDATE">
                <EditPartPage />
              </AuthGuard>
            ),
          },
          {
            path: 'view/:tagId',
            element: (
              <AuthGuard modulename={permissionKeys.PART} permission="VIEW">
                <ViewPartMasterPage />
              </AuthGuard>
            ),
          },
          {
            path: 'upload-website',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].CREATE}
                subModule={permissionKeys.PART}
              >
                <EditPartWebSite />
              </AuthGuard>
            ),
          },
          {
            path: 'edit-website',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].UPDATE}
                subModule={permissionKeys.PART}
              >
                <EditPartWebSite />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'web-shop-part',
        children: [
          {
            path: 'edit/:type/:masterId',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.PART].UPDATE}
                subModule={permissionKeys.PART}
              >
                <EditWebShopPage />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'web-shop-vehicle',
        children: [
          {
            path: 'edit/:type/:masterId',
            element: (
              <AuthGuard
                modulename={permissionKeys.WEBSITE}
                permission={subComponentKeys[permissionKeys.WEBSITE][permissionKeys.VEHICLE].UPDATE}
                subModule={permissionKeys.VEHICLE}
              >
                <EditWebShopPage />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <MainLayoutWithoutSidebar />,
    children: [
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: 'unauthorized',
        element: <Unauthorized />,
      },
      {
        path: 'upgrade-plan',
        element: <UpgradePlan />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
