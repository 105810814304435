/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alertData: [],
  breadCrumbArr: [],
};

const slice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setAlertData(state, action) {
      state.alertData = action.payload;
    },
    setBreadCrumbArr(state, action) {
      state.breadCrumbArr = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setRemoveAlertWithIndexData = (index) => async (dispatch, getState) => {
  const { master } = getState();
  const removeAlert = master.alertData.filter((alert) => alert.messageIndex !== index);
  dispatch(slice.actions.setAlertData(removeAlert));
};

export const setAlertData = (payload) => async (dispatch, getState) => {
  const { master } = getState();
  const tmpMessageIndex = master.alertData.length + 1;
  payload = { ...payload, messageIndex: tmpMessageIndex };
  const tmpPayload = [...master.alertData, payload];
  dispatch(slice.actions.setAlertData(tmpPayload));
  setTimeout(() => {
    dispatch(setRemoveAlertWithIndexData(tmpMessageIndex));
  }, 10000);
};

export const setRemoveAlertData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setAlertData(payload));
};

export const setBreadCrumbArr = (payload) => async (dispatch) => {
  dispatch(slice.actions.setBreadCrumbArr(payload));
};

export default slice;
