import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  secondCard: {
    marginTop: '16px',
  },
  imageListCartClass: {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill,minmax(132px, 0fr)) !important',
    gridTemplateRows: 'auto',
    gridAutoColumns: 'minmax(132px, 0fr)',
    margin: '0px',
  },
  imageCartListItem: {
    minHeight: '100px',
    maxHeight: '100px',
  },
}));
