/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import CachedIcon from '@mui/icons-material/Cached';
import AlertContainerComponent from '../components/alert-container-component';
import Loader from '../components/loader-component';
import { useSelector } from '../store';
import Header from './header';
import Sidebar from './navbar';

const LayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const LayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  // margin: 'auto',
  flex: '1 1 auto',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '85px',
  },
}));

const LayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const LayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const Layout = ({ children, errorState }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const { stateLoader } = useSelector((state) => state.loader);
  const onSidebarMobileOpen = () => {
    setIsSidebarMobileOpen(true);
  };

  return (
    <>
      <Loader stateLoader={typeof errorState !== 'undefined' ? errorState : stateLoader} />
      <LayoutRoot>
        <Header onSidebarMobileOpen={onSidebarMobileOpen} />
        <Sidebar onMobileClose={() => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
        <LayoutWrapper>
          <LayoutContainer>
            <LayoutContent>
              <AlertContainerComponent />
              {typeof children !== 'undefined' && children}
              {typeof children === 'undefined' && <Outlet />}
            </LayoutContent>
          </LayoutContainer>
        </LayoutWrapper>
      </LayoutRoot>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  errorState: PropTypes.any,
};

export default Layout;
