import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import NProgress from 'nprogress';
import { useStyles } from '../styles/common-style';

const LoadingScreen = () => {
  const styleClasses = useStyles();
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return <Box className={styleClasses.loadingScreenComponent} />;
};

export default LoadingScreen;
