/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import partApi from '../api/partApi';

const yearList = Array.from(Array(Number(new Date().getFullYear()) + 1 - 1944), (_, i) => ({
  id: i,
  name: (i + 1945).toString(),
})).reverse();

const initialState = {
  parts: [],
  currentPart: {},
  partDetail: {
    yearList,
    makeListArr: [],
    modelsListArr: [],
    subModelsListArr: [],
    partTypeListArr: [],
    subPartTypeListArr: [],
    InterchangePartListArr: [],
    partLocation: [],
    partCategory: [],
    partCondition: [],
  },
  searchPartDetails: {},
  searchPartData: {},
  partTableAttributes: {
    limit: 10,
    page: 0,
    order: 'desc',
  },
  vehiclePartTableAttributes: {},
  partTableVariable: [],
  ecoPartTableVariable: [],
  partViewMode: true,
  editPartWebsiteData: {},
  partTardeMeFormData: {},
  partFilterListObject: {},
  partAlternativeVariableColumn: {},
  viewPartAlternativeVariableColumn: {},
  viewEcoPartAlternativeVariableColumn: {},
  isTableReload: false,
  searchPartList: {
    Data: [],
    TotalRows: 0,
  },
  viewPartMode: 'part-view',
  partSearchMode: 'non-eco',
};

const slice = createSlice({
  name: 'part',
  initialState,
  reducers: {
    setSearchPartDetails(state, action) {
      state.searchPartDetails = action.payload;
    },
    setPartDetail(state, action) {
      state.partDetail = action.payload;
    },
    setSearchPartData(state, action) {
      state.searchPartData = action.payload;
    },
    setPartTableAttributes(state, action) {
      state.partTableAttributes = action.payload;
    },
    setVehiclePartTableAttributes(state, action) {
      state.vehiclePartTableAttributes = action.payload;
    },
    setPartTableVariable(state, action) {
      state.partTableVariable = action.payload;
    },
    setEcoPartTableVariable(state, action) {
      state.ecoPartTableVariable = action.payload;
    },
    setPartViewMode(state, action) {
      state.partViewMode = action.payload;
    },
    setEditPartWebsiteData(state, action) {
      state.editPartWebsiteData = action.payload;
    },
    setPartTardeMeFormData(state, action) {
      state.partTardeMeFormData = action.payload;
    },
    setPartFilterListObject(state, action) {
      state.partFilterListObject = action.payload;
    },
    setPartAlternativeVariableColumn(state, action) {
      state.partAlternativeVariableColumn = action.payload;
    },
    setViewPartAlternativeVariableColumn(state, action) {
      state.viewPartAlternativeVariableColumn = action.payload;
    },
    setViewEcoPartAlternativeVariableColumn(state, action) {
      state.viewEcoPartAlternativeVariableColumn = action.payload;
    },
    setIsTableReload(state, action) {
      state.isTableReload = action.payload;
    },
    setSearchPartList(state, action) {
      state.searchPartList = action.payload;
    },
    setViewPartMode(state, action) {
      state.viewPartMode = action.payload;
    },
    setPartSearchMode(state, action) {
      state.partSearchMode = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setPartDetail = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartDetail(payload));
};
export const setSearchPartList = (payload) => async (dispatch) => {
  dispatch(slice.actions.setSearchPartList(payload));
};
export const searchPartDetails = (searchResult) => async (dispatch) => {
  dispatch(slice.actions.setSearchPartDetails({ ...searchResult }));
};

export const setSearchPartData = (searchResult) => async (dispatch) => {
  dispatch(slice.actions.setSearchPartData({ ...searchResult, ...searchResult }));
};

export const setPartTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setPartTableAttributes({ ...attibutes, ...attibutes }));
};

export const setVehiclePartTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setVehiclePartTableAttributes({ ...attibutes, ...attibutes }));
};

export const setPartViewMode = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartViewMode(payload));
};

export const setPartTableVariable = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartTableVariable(payload));
};

export const setEcoPartTableVariable = (payload) => async (dispatch) => {
  dispatch(slice.actions.setEcoPartTableVariable(payload));
};

export const setEditPartWebsiteData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setEditPartWebsiteData(payload));
};

export const setPartTardeMeFormData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartTardeMeFormData(payload));
};

export const setPartFilterListObject = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartFilterListObject(payload));
};

export const setPartAlternativeVariableColumn = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartAlternativeVariableColumn(payload));
};

export const setViewPartAlternativeVariableColumn = (payload) => async (dispatch) => {
  dispatch(slice.actions.setViewPartAlternativeVariableColumn(payload));
};

export const setViewEcoPartAlternativeVariableColumn = (payload) => async (dispatch) => {
  dispatch(slice.actions.setViewEcoPartAlternativeVariableColumn(payload));
};
export const setIsTableReload = (payload) => async (dispatch) => {
  dispatch(slice.actions.setIsTableReload(payload));
};
export const setViewPartMode = (payload) => async (dispatch) => {
  dispatch(slice.actions.setViewPartMode(payload));
};

export const setPartSearchMode = (payload) => async (dispatch) => {
  dispatch(slice.actions.setPartSearchMode(payload));
};

export default slice;
