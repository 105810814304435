/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRelaod: false,
  tardeMeFormData: { activeState: 1 },
};

const slice = createSlice({
  name: 'trademe',
  initialState,
  reducers: {
    setIsReload(state, action) {
      state.isRelaod = action.payload;
    },
    setTardeMeFormData(state, action) {
      state.tardeMeFormData = action.payload;
    },
  },
});
export const { reducer } = slice;

export const setIsReload = (payload) => async (dispatch) => {
  dispatch(slice.actions.setIsReload(payload));
};
export const setTardeMeFormData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setTardeMeFormData(payload));
};

export default slice;
