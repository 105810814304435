import { useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@mui/material';
import { PageContainer } from '../../components/page';
import { setStateLoader } from '../../slices/loader-reducer';
import { useDispatch } from '../../store';
import { useStyles } from '../../styles/common-style';

const ErrorPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const styleClasses = useStyles();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    dispatch(setStateLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer title="Error: Something went wrong!!" isCenter="true">
      <Grid container spacing={3} direction="row" alignItems="center" justifyContent="center">
        <Grid item md={12} xs={12} justifyContent="center" alignItems="center">
          <Box justifyContent="center">
            <Typography align="center" color="textPrimary" variant={mobileDevice ? 'h4' : 'h1'}>
              Opps something went wrong
            </Typography>
            <Box justifyContent="center" display="flex" className={styleClasses.secondCard}>
              <Button
                className={styleClasses.blueButton}
                variant="contained"
                onClick={() => {
                  window.location.href = '/dashboard';
                }}
              >
                Back to Home
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ErrorPage;
