import { useEffect, useState } from 'react';
import { experimentalStyled } from '@material-ui/core/styles';

export const DesktopView = experimentalStyled('div')(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const DesktopTabletView = experimentalStyled('div')(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const TabletView = experimentalStyled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.between('sm', 'md')]: {
    display: 'block',
  },
}));

export const MobileView = experimentalStyled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const HandleResizeFun = () => {
  const [isMobile, setIsMobile] = useState(0);
  const handleResize = () => {
    if (window.innerWidth < 575) {
      setIsMobile(1);
    } else if (window.innerWidth > 575 && window.innerWidth < 900) {
      setIsMobile(2);
    } else {
      setIsMobile(0);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 575) {
      setIsMobile(1);
    } else if (window.innerWidth > 575 && window.innerWidth < 900) {
      setIsMobile(2);
    } else {
      setIsMobile(0);
    }
    window.addEventListener('resize', handleResize);
  }, []);
  return isMobile;
};
