import React from 'react';
import { Box, Modal, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/common-style';

const ModalComponent = ({ title, children, isOpen, modalBoxClass }) => {
  const styleClasses = useStyles();
  return (
    <Modal open={isOpen} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <Box className={modalBoxClass || styleClasses.modalStyle}>
        <Typography color="primary" align="center" className={styleClasses.modalTitle}>
          {title}
        </Typography>
        {children}
      </Box>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
  modalBoxClass: PropTypes.any,
};

export default ModalComponent;
