/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import vehicleApi from '../api/vehicle-api';

const yearList = Array.from(Array(Number(new Date().getFullYear()) + 1 - 1944), (_, i) => ({
  id: i,
  label: (i + 1945).toString(),
})).reverse();

const initialState = {
  searchVehicleList: {},
  vehicles: [],
  selectedVehicles: [],
  currentVehicle: {},
  ecoCurrentVehicle: {},
  vehicleDetails: {
    makeListArr: [],
    modelsListArr: [],
    subModelsListArr: [],
    color: [],
    vehicleType: [],
    bodyStyle: [],
    vehicleLocation: [],
    fuelType: [],
    year: yearList,
    purpose: [],
    country: [],
  },
  searchVehicleData: {},
  searchVehicleDetails: { vehicles: [] },
  currentSearchVehicle: {},
  searchVehicleDetailsview: {},
  vehicleTableAttributes: {
    limit: 10,
    page: 0,
    order: 'desc',
  },
  ecoVehicleTableVariable: {},
  vehicleTableVariable: [],
  vehicleViewMode: true,
  vehicleAddPartsViewFlag: false,
  vehiclesMetaApisLoaderState: true,
  editVehicleWebsiteData: {},
  vehicleTardeMeFormData: {},
  checkCarjamDataVin: '',
  vehicleFilterListObject: {},
  vehicleSoldPartVariableColumn: {},
  vehicleAlternativeVariableColumn: {},
  vehicleEcoAlternativeVariableColumn: {},
  isTableReload: false,
  viewVehicleMode: 'vehicle',
  isVehicleStatesReload: false,
  vehicleSearchMode: 'non-eco',
};

const slice = createSlice({
  name: 'vahicle',
  initialState,
  reducers: {
    setPurchasedVechicles(state, action) {
      state.vehicles = action.payload;
    },
    manageSelectedVehicle(state, action) {
      state.selectedVehicles = action.payload;
    },
    setCurrentVehicle(state, action) {
      state.currentVehicle = action.payload;
    },
    setEcoCurrentVehicle(state, action) {
      state.ecoCurrentVehicle = action.payload;
    },
    setVehicleDetails(state, action) {
      state.vehicleDetails = action.payload;
    },
    setSearchVehicleDetails(state, action) {
      state.searchVehicleDetails = action.payload;
    },
    setCurrentSearchVehicle(stete, action) {
      stete.currentSearchVehicle = action.payload;
    },
    setSearchVehicleDetailsView(state, action) {
      state.searchVehicleDetailsview = action.payload;
    },
    setSearchVehicleData(state, action) {
      state.searchVehicleData = action.payload;
    },
    setVehicleTableAttributes(state, action) {
      state.vehicleTableAttributes = action.payload;
    },
    setVehicleTableVariable(state, action) {
      state.vehicleTableVariable = action.payload;
    },
    setEcoVehicleTableVariable(state, action) {
      state.ecoVehicleTableVariable = action.payload;
    },
    setVehicleViewMode(state, action) {
      state.vehicleViewMode = action.payload;
    },
    setVehicleAddPartsViewFlag(state, action) {
      state.vehicleAddPartsViewFlag = action.payload;
    },
    setEditVehicleWebsiteData(state, action) {
      state.editVehicleWebsiteData = action.payload;
    },
    setCheckCarjamDataVin(state, action) {
      state.checkCarjamDataVin = action.payload;
    },
    setVehicleTardeMeFormData(state, action) {
      state.vehicleTardeMeFormData = action.payload;
    },
    setVehiclesMetaApisLoaderState(state, action) {
      state.vehiclesMetaApisLoaderState = action.payload;
    },
    setVehicleFilterListObject(state, action) {
      state.vehicleFilterListObject = action.payload;
    },
    setVehicleSoldPartVariableColumn(state, action) {
      state.vehicleSoldPartVariableColumn = action.payload;
    },
    setVehicleAlternativeVariableColumn(state, action) {
      state.vehicleAlternativeVariableColumn = action.payload;
    },
    setVehicleEcoAlternativeVariableColumn(state, action) {
      state.vehicleEcoAlternativeVariableColumn = action.payload;
    },
    setIsTableReload(state, action) {
      state.isTableReload = action.payload;
    },
    setSearchVehicleList(state, action) {
      state.searchVehicleList = action.payload;
    },
    setViewVehicleMode(state, action) {
      state.viewVehicleMode = action.payload;
    },
    setIsVehicleStatesReload(state, action) {
      state.isVehicleStatesReload = action.payload;
    },
    setVehicleSearchMode(state, action) {
      state.vehicleSearchMode = action.payload;
    },
  },
});

export const { reducer } = slice;

export const addPurchasedVechicles =
  ({ vehicles }) =>
  async (dispatch) => {
    const vehicle = await vehicleApi.getPurchasedVehicle();
    dispatch(slice.actions.setPurchasedVechicles([vehicle, ...vehicles]));
  };

export const updatePurchasedVechicles = (vehicles) => async (dispatch) => {
  dispatch(slice.actions.setPurchasedVechicles(vehicles));
};

export const manageSelectedVehicle = (selectedVehicles) => async (dispatch) => {
  dispatch(slice.actions.manageSelectedVehicle(selectedVehicles));
};

export const removePurchasedVechicles = (updatedVehiclesList) => async (dispatch) => {
  dispatch(slice.actions.setPurchasedVechicles(updatedVehiclesList));
  if (updatedVehiclesList?.length === 0) dispatch(slice.actions.manageSelectedVehicle([]));
};

export const getVehicleDetails =
  (currentVehicle, from = null) =>
  async (dispatch) => {
    dispatch(slice.actions.setVehiclesMetaApisLoaderState(true));
    const { vehicleDetails } = await vehicleApi.getVehicleDetails(currentVehicle, from);
    dispatch(slice.actions.setVehicleDetails(vehicleDetails));
    dispatch(slice.actions.setVehiclesMetaApisLoaderState(false));
  };

export const setCurrentVehicle = (vehicle) => async (dispatch) => {
  dispatch(slice.actions.setCurrentVehicle(vehicle));
};

export const setSearchVehicleData = (searchData) => async (dispatch) => {
  dispatch(slice.actions.setSearchVehicleData({ ...searchData }));
};

export const searchVehicleDetails = (searchResult) => async (dispatch) => {
  dispatch(slice.actions.setSearchVehicleDetails({ ...searchResult }));
};

export const setCurrentSearchVehicle = (vehicle) => async (dispatch) => {
  dispatch(slice.actions.setCurrentSearchVehicle({ ...vehicle, ...vehicle?.carjamRes }));
};

export const getSearchVehicleDetails = (currentSearchVehicle) => async (dispatch) => {
  const { vehicleDetails } = await vehicleApi.getSearchVehicleDetails(currentSearchVehicle);
  const searchVehicleDetailsView = vehicleDetails;
  dispatch(slice.actions.setVehicleDetails(searchVehicleDetailsView));
  dispatch(slice.actions.setCurrentVehicle({ ...currentSearchVehicle }));
};

export const setVehicleTableAttributes = (attibutes) => async (dispatch) => {
  dispatch(slice.actions.setVehicleTableAttributes({ ...attibutes, ...attibutes }));
};

export const setVehicleViewMode = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleViewMode(payload));
};

export const setVehicleTableVariable = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleTableVariable(payload));
};

export const setEcoVehicleTableVariable = (payload) => async (dispatch) => {
  dispatch(slice.actions.setEcoVehicleTableVariable(payload));
};

export const setEcoCurrentVehicle = (payload) => async (dispatch) => {
  dispatch(slice.actions.setEcoCurrentVehicle(payload));
};

export const setVehicleAddPartsViewFlag = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleAddPartsViewFlag(payload));
};

export const setEditVehicleWebsiteData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setEditVehicleWebsiteData(payload));
};

export const setCheckCarjamDataVin = (payload) => async (dispatch) => {
  dispatch(slice.actions.setCheckCarjamDataVin(payload));
};

export const setVehicleTardeMeFormData = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleTardeMeFormData(payload));
};

export const setVehicleFilterListObject = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleFilterListObject(payload));
};

export const setVehicleSoldPartVariableColumn = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleSoldPartVariableColumn(payload));
};

export const setVehicleAlternativeVariableColumn = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleAlternativeVariableColumn(payload));
};

export const setVehicleEcoAlternativeVariableColumn = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleEcoAlternativeVariableColumn(payload));
};

export const setIsTableReload = (payload) => async (dispatch) => {
  dispatch(slice.actions.setIsTableReload(payload));
};

export const setSearchVehicleList = (payload) => async (dispatch) => {
  dispatch(slice.actions.setSearchVehicleList(payload));
};

export const setVehicleDetails = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleDetails(payload));
};

export const setViewVehicleMode = (payload) => async (dispatch) => {
  dispatch(slice.actions.setViewVehicleMode(payload));
};

export const setIsVehicleStatesReload = (payload) => async (dispatch) => {
  dispatch(slice.actions.setIsVehicleStatesReload(payload));
};

export const setVehicleSearchMode = (payload) => async (dispatch) => {
  dispatch(slice.actions.setVehicleSearchMode(payload));
};

export default slice;
